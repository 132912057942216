import { QCFilterPipe } from './Shared/FilterPipe';
import { OEEHistoryComponent } from './Components/monitor/oee-history/oee-history.component';
import { OnlyNumber } from './Shared/OnlyNumber';
import { NoSlash } from './Shared/NoSlash';

import { QCComponent } from './Components/qc/qc.component';
import { InvoiceComponent } from './Components/invoice/invoice.component';
import { ShippingComponent } from './Components/shipping/shipping.component';
import { SchedulingComponent } from './Components/scheduling/scheduling.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule, MatNativeDateModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoginComponent } from './Components/Home/login/login.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { LayoutComponent } from './Components/Home/layout/layout.component';
import { TopnavComponent } from './Components/Home/layout/topnav/topnav.component';
import { MonitorComponent } from './Components/monitor/monitor.component';
import { PartsComponent} from './Components/parts/parts.component';
import { PurchaseOrderComponent } from './Components/purchaseOrder/purchaseOrder.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import listPlugin from '@fullcalendar/list';// a plugin
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { SelectMachineComponent } from './Components/scheduling/select-machine/select-machine.component';
import { AddEventComponent } from './Components/scheduling/add-event/add-event.component';
import { ModalModule } from './Components/_modal';
import { ScheduleComponent } from './Components/schedule/schedule.component';
import { SelectMachineSlotComponent } from './Components/scheduling/add-event/select-machine-slot/select-machine-slot.component';
import { UpdateScheduleComponent } from './Components/scheduling/update-schedule/update-schedule.component';
import { ConfirmationDialogComponent } from './Shared/confirmation-dialog/confirmation-dialog.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddInvoiceComponent } from './Components/invoice/add-invoice/add-invoice.component';
import { UpdateInvoiceComponent } from './Components/invoice/update-invoice/update-invoice.component';
import { RawComponent } from './Components/raw/raw.component';
import { AddPartComponent } from './Components/parts/PopUpModal/add-part/add-part.component';
import { UpdatePartComponent } from './Components/parts/PopUpModal/update-part/update-part.component';
import { AlignDialogComponent } from './Shared/align-dialog/align-dialog.component';
import { PinDialogComponent } from './Shared/pin-dialog/pin-dialog.component';
import { FinishDialogComponent } from './Shared/finish-dialog/finish.component';
import { ViewRawMaterialsComponent } from './Shared/view-raw-materials/view-raw-materials.component';
import { GenerateQRCodeDialogComponent } from './Components/invoice/generate-qrcode-dialog/generate-qrcode-dialog.component';
import { AddRawComponent } from './Components/raw/PopUpRaw/add-raw/add-raw.component';
import { OrderRawMaterialComponent } from './Components/raw/order-raw-material/order-raw-material.component';
import { ReceivedOrderComponent } from './Components/raw/received-order/received-order.component';
import { GenerateQRCodeComponent } from './Components/raw/generate-qrcode/generate-qrcode.component';
import { EnterReceiveDetailsComponent } from './Components/raw/received-order/enter-receive-details/enter-receive-details.component';
import { DetailsRawComponent } from './Components/raw/PopUpRaw/details-raw/details-raw.component';
import { DetailPOComponent } from './Components/raw/PO/detail-po/detail-po.component';
import { OrdersComponent } from './Components/raw/PO/orders/orders.component';
import { BoxNumberComponent } from './Components/invoice/generate-qrcode-dialog/box-number/box-number.component';
import { PartsInfoComponent } from './Components/qc/parts-info/parts-info.component';
import { ShippingInformationComponent } from './Components/purchaseOrder/shipping-information/shipping-information.component';
import { RawCheckOutInformationComponent } from './Components/purchaseOrder/raw-check-out-information/raw-check-out-information.component';
import { DetailsComponent } from './Components/parts/PopUpModal/details/details.component';
import { AddSupplierComponent } from './Components/raw/Supplier/add-supplier/add-supplier.component';
import { DetailsScheduleComponent } from './Components/schedule/PopUpSchedule/details-schedule/details-schedule.component';
import { RawMaterialsPartComponent } from './Shared/raw-materials-part/raw-materials-part.component';
import { AddPackingComponent } from './Components/invoice/add-packing/add-packing.component';
import { AccountSettingsComponent } from './Components/account-settings/account-settings.component';
import { ManageRawTypeComponent } from './Components/raw/manage-raw-type/manage-raw-type.component';
import { AddCustomerComponent } from './Components/purchaseOrder/Customer/add-customer/add-customer.component';
import { UpdatePackingComponent } from './Components/invoice/update-packing/update-packing.component';
import { GenerateInfoComponent } from './Components/invoice/add-invoice/generate-info/generate-info.component';
import { GenerateOldLabelComponent } from './Components/invoice/generate-old-label/generate-old-label.component';
import { SelectPOStockComponent } from './Components/invoice/add-packing/select-postock/select-postock.component';
import { InfoComponent } from './Components/invoice/add-packing/info/info.component';
import { QuantityComponent } from './Components/invoice/add-packing/select-postock/quantity/quantity.component';
import { AddPOComponent } from './Components/purchaseOrder/add-po/add-po.component';
import { GenerateOldQRCodeComponent } from './Components/raw/generate-old-qrcode/generate-old-qrcode.component';
import { nl2brPipe } from './Shared/nlpipe';
import { OldStockRecordComponent } from './Components/raw/old-stock-record/old-stock-record.component';
import { CrushingMaterialComponent } from './Components/raw/crushing-material/crushing-material.component';
import { AddCrushingMaterialComponent } from './Components/raw/crushing-material/add-crushing-material/add-crushing-material.component';
import { ViewCrushingMaterialComponent } from './Components/raw/crushing-material/view-crushing-material/view-crushing-material.component';
import { QRCODEComponent } from './Components/raw/crushing-material/qrcode/qrcode.component';
import { UpdateQuantityComponent } from './Components/scheduling/update-schedule/update-quantity/update-quantity.component';
import { MoldComponent } from './Components/mold/mold.component';
import { ReceiptComponent } from './Components/receipt/receipt.component';
import { PoStockRecordComponent } from './Components/raw/po-stock-record/po-stock-record.component';
import { ManageCrushingTypeComponent } from './Components/raw/crushing-material/manage-crushing-type/manage-crushing-type.component';
import { StocksComponent } from './Components/parts/PopUpModal/stocks/stocks.component';
import { DetailPOPageComponent } from './Components/purchaseOrder/detail-po/detail-po-page.component';
import { MonthPickerComponent } from './Shared/month-picker/month-picker.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { QtyInformationComponent } from './Components/qc/qty-information/qty-information.component';
import { QCInfoComponent } from './Components/qc/qcinfo/qcinfo.component';
import { SortProductionPerformancePipe } from './Shared/SortProductionPerformancePipe';
import { OeeCalculationComponent } from './Components/monitor/oee-history/oee-calculation/oee-calculation.component';
import { DowntimeDetailsComponent } from './Components/purchaseOrder/downtime-details/downtime-details.component';
import { StaffComponent } from './Components/staff/staff.component';
import { AddStaffComponent } from './Components/staff/add-staff/add-staff.component';
import { ManageOutsourceTypeComponent } from './Components/raw/manage-outsource-type/manage-outsource-type.component';
import { DetailsOutsourceComponent } from './Components/raw/PopUpOutsource/details-outsource/details-outsource.component';
import { AddOutsourceComponent } from './Components/raw/PopUpOutsource/add-outsource/add-outsource.component';
import { OrderConsumableComponent } from './Components/raw/order-consumable/order-consumable.component';
import { OrderOutsourceComponent } from './Components/raw/order-outsource/order-outsource.component';
import { ManufacturingComponent } from './Components/manufacturing/manufacturing.component';
import { AddQCComponent } from './Components/qc/add-qc/add-qc.component';
import { UploadExcelDialogComponent } from './Shared/upload-excel-dialog/upload-excel-dialog.component';
import { QCRejectionComponent } from './Components/qc/qc-rejection/qc-rejection.component';
import { AddConfirmationComponent } from './Components/qc/add-confirmation/add-confirmation.component';
import { ViewConfirmationComponent } from './Components/qc/view-confirmation/view-confirmation.component';
import { ScanOutsourceComponent } from './Components/raw/scan-outsource/scan-outsource.component';
import { ForgotPasswordComponent } from './Components/Home/login/forgot-password/forgot-password.component';
import { ReceivedScanComponent } from './Components/raw/received-scan/received-scan.component';
import { ScanReceiveDetailsComponent } from './Components/raw/received-scan/scan-receive-details/scan-receive-details.component';
import { ScanStartComponent } from './Components/manufacturing/scan-start/scan-start.component';
import { ScanEndComponent } from './Components/manufacturing/scan-end/scan-end.component';
import { ReceiveDetailsComponent } from './Components/manufacturing/receive-details/receive-details.component';
import { DetailOutsourceComponent } from './Components/raw/PO/detail-outsource/detail-outsource.component';
import { ScanDrawingComponent } from './Components/manufacturing/scan-drawing/scan-drawing.component';
import { InputDialogComponent } from './Shared/input-dialog/input-dialog.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { StaffDialogComponent } from './Shared/staff-dialog/staff-dialog.component';
import { QuantityDialogComponent } from './Shared/quantity-dialog/quantity-dialog.component';
import { ViewPartComponent } from './Components/purchaseOrder/view-part/view-part.component';
import { AddStationComponent } from './Components/staff/add-station/add-station.component';

const firebaseConfig = {
  apiKey: "AIzaSyAgtGxH5UfURiWeANkFGzHmgdFXqrYSkLs",
  authDomain: "rcet-76240.firebaseapp.com",
  databaseURL: 'https://rcet-76240-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: "rcet-76240",
  storageBucket: "rcet-76240.appspot.com",
  messagingSenderId: "570282839600",
  appId: "1:570282839600:web:475095f693125df1cea7f5",
  measurementId: "G-9YEQ16XT2V"
};

/*const firebaseConfig = {
  apiKey: "AIzaSyD871Tu53w8TsTVj66Id_yFC0uNe3g9E7I",
  authDomain: "rect-uat.firebaseapp.com",
  databaseURL: 'https://rect-uat-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: "rect-uat",
  storageBucket: "rect-uat.appspot.com",
  messagingSenderId: "20077112761",
  appId: "1:20077112761:web:e465982d801e71a2ce9a68",
  measurementId: "G-V7785ER5SJ"
};*/

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  resourceTimelinePlugin,
  timeGridPlugin,
  listPlugin
]);

@NgModule({
  declarations: [
    QCFilterPipe,
    OnlyNumber,
    NoSlash,
    AppComponent,
    LoginComponent,
    LayoutComponent,
    TopnavComponent,
    DashboardComponent,
    MonitorComponent,
    PartsComponent,
    PurchaseOrderComponent,
    SchedulingComponent,
    SelectMachineComponent,
    AddEventComponent,
    ScheduleComponent,
    SelectMachineSlotComponent,
    ShippingComponent,
    UpdateScheduleComponent,
    ConfirmationDialogComponent,
    InvoiceComponent,
    QCComponent,
    AddInvoiceComponent,
    UpdateInvoiceComponent,
    RawComponent,
    AddPartComponent,
    UpdatePartComponent,
    AlignDialogComponent,
    InputDialogComponent,
    PinDialogComponent,
    FinishDialogComponent,
    ViewRawMaterialsComponent,
    GenerateQRCodeDialogComponent,
    AddRawComponent,
    AddStationComponent,
    ViewPartComponent,
    AddOutsourceComponent,
    DetailsOutsourceComponent,
    OrderRawMaterialComponent,
    OrderOutsourceComponent,
    ScanOutsourceComponent,
    ScanReceiveDetailsComponent,
    ScanStartComponent,
    ScanDrawingComponent,
    ReceiveDetailsComponent,
    ScanEndComponent,
    ReceivedScanComponent,
    OrderConsumableComponent,
    ForgotPasswordComponent,
    ReceivedOrderComponent,
    GenerateQRCodeComponent,
    EnterReceiveDetailsComponent,
    DetailsRawComponent,
    DetailPOComponent,
    DetailOutsourceComponent,
    OrdersComponent,
    BoxNumberComponent,
    PartsInfoComponent,
    ShippingInformationComponent,
    RawCheckOutInformationComponent,
    DetailsComponent,
    AddSupplierComponent,
    AddPackingComponent,
    DetailsScheduleComponent,
    RawMaterialsPartComponent,
    AccountSettingsComponent,
    ManageRawTypeComponent,
    ManageOutsourceTypeComponent,
    AddCustomerComponent,
    UpdatePackingComponent,
    GenerateInfoComponent,
    GenerateOldLabelComponent,
    SelectPOStockComponent,
    InfoComponent,
    QuantityComponent,
    AddPOComponent,
    GenerateOldQRCodeComponent,
    nl2brPipe,
    OldStockRecordComponent,
    OEEHistoryComponent,
    CrushingMaterialComponent,
    AddCrushingMaterialComponent,
    ViewCrushingMaterialComponent,
    QRCODEComponent,
    UpdateQuantityComponent,
    MoldComponent,
    ReceiptComponent,
    PoStockRecordComponent,
    ManageCrushingTypeComponent,
    StocksComponent,
    DetailPOPageComponent,
    MonthPickerComponent,
    NotFoundComponent,
    QtyInformationComponent,
    QCInfoComponent,
    AddQCComponent,
    UploadExcelDialogComponent,
    QCRejectionComponent,
    AddConfirmationComponent,
    ViewConfirmationComponent,
    ManufacturingComponent,
    SortProductionPerformancePipe,
    OeeCalculationComponent,
    DowntimeDetailsComponent,
    StaffComponent,
    AddStaffComponent,
    DetailPOPageComponent,
    StaffDialogComponent, 
    QuantityDialogComponent
  ],
  entryComponents:[
    DowntimeDetailsComponent,
    StocksComponent,
    AddEventComponent,
    SelectMachineComponent,
    QuantityDialogComponent,
    AddPartComponent,
    UpdatePartComponent,
    SelectMachineSlotComponent,
    UpdateScheduleComponent,
    ConfirmationDialogComponent,
    AddInvoiceComponent,
    UpdateInvoiceComponent,
    AlignDialogComponent,
    InputDialogComponent,
    PinDialogComponent,
    FinishDialogComponent,
    ViewRawMaterialsComponent,
    GenerateQRCodeDialogComponent,
    AddRawComponent,
    AddStationComponent,
    ViewPartComponent,
    AddOutsourceComponent,
    DetailsOutsourceComponent,
    OrderRawMaterialComponent,
    OrderOutsourceComponent,
    ScanOutsourceComponent,
    ScanReceiveDetailsComponent,
    ScanStartComponent,
    ScanDrawingComponent,
    ReceiveDetailsComponent,
    ScanEndComponent,
    ReceivedScanComponent,
    OrderConsumableComponent,
    ForgotPasswordComponent,
    ReceivedOrderComponent,
    GenerateQRCodeComponent,
    EnterReceiveDetailsComponent,
    DetailsRawComponent,
    DetailPOComponent,
    DetailOutsourceComponent,
    OrdersComponent,
    BoxNumberComponent,
    PartsInfoComponent,
    ShippingInformationComponent,
    RawCheckOutInformationComponent,
    DetailsComponent,
    AddSupplierComponent,
    DetailsScheduleComponent,
    AddPackingComponent,
    RawMaterialsPartComponent,
    AccountSettingsComponent,
    ManageRawTypeComponent,
    ManageOutsourceTypeComponent,
    AddCustomerComponent,
    UpdatePackingComponent,
    GenerateInfoComponent,
    GenerateOldLabelComponent,
    SelectPOStockComponent,
    InfoComponent,
    QuantityComponent,
    AddPOComponent,
    GenerateOldQRCodeComponent,
    QtyInformationComponent,
    OldStockRecordComponent,
    OEEHistoryComponent,
    ViewCrushingMaterialComponent,
    AddCrushingMaterialComponent,
    QRCODEComponent,
    UpdateQuantityComponent,
    PoStockRecordComponent,
    ManageCrushingTypeComponent,
    DetailPOPageComponent,
    QCInfoComponent,
    AddQCComponent,
    UploadExcelDialogComponent,
    QCRejectionComponent,
    AddConfirmationComponent,
    ViewConfirmationComponent,
    ManufacturingComponent,
    OeeCalculationComponent,
    StaffDialogComponent,
    AddStaffComponent
  ],
  imports: [
    BrowserModule,
    //NgxDaterangepickerMd.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    HttpClientModule,
    DragDropModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    FullCalendarModule,
    AngularFireDatabaseModule,
    ModalModule,
    NgxGaugeModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatSelectSearchModule,
    NgxQRCodeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }