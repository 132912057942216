import { ConfirmationDialogComponent } from './../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnChanges, NgZone, OnInit, SystemJsNgModuleLoader, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MachineDB_controller } from '../../Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { Machine, RawMaterialUsed, ScheduleTracker } from '../../Services/Object_Classes/Machine/Machine';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FinishDialogComponent } from 'src/app/Shared/finish-dialog/finish.component';
import { AlignDialogComponent } from 'src/app/Shared/align-dialog/align-dialog.component';
import { PinDialogComponent } from 'src/app/Shared/pin-dialog/pin-dialog.component';
import { DetailsScheduleComponent } from './PopUpSchedule/details-schedule/details-schedule.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { StaffDialogComponent } from 'src/app/Shared/staff-dialog/staff-dialog.component';
import { PartTracker, StaffInfo } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { QuantityDialogComponent } from 'src/app/Shared/quantity-dialog/quantity-dialog.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})


export class ScheduleComponent implements OnInit {
  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;
  modulesList = ['Laser Cutting', 'Shearing', 'Bending', 'Welding', 'Polishing', 'Cleaning', 'Tapping', 'Assembly', 'QC'];
  modulesList2 = ['Laser Cutting', 'Shearing', 'Bending', 'Welding', 'Polishing', 'Cleaning', 'Tapping', 'Assembly', 'QC'];

  Machine_No: string;
  Schedule_Track: SchedulerComponent[];
  now: number;
  is: any;
  subscribe: any;
  CodeSubmitted_flag: Boolean = false;
  Display_flag: Boolean = false;
  Button_flag: Boolean = false;
  viewForm: Boolean = false;
  AlertTimer: any;
  AlertCountDown: String;
  machinedb_Controller = new MachineDB_controller(this.db);
  po_controller: PODB_controller = new PODB_controller(this.db);
  partdb_Controller = new PartDB_controller(this.db, this.storage, this.firestore);
  flaskServertimerInterval: any;
  DatetimerInterval: any;
  ViewUpcoming = false;
  iss: any;
  machineFailureStatus = true;
  Presetting_flag: Boolean = false;
  Machine: Machine = new Machine();
  staffMessage: String = "-";
  staffIds: StaffInfo[] = [];
  soList: any = [];
  partList: any = [];
  endDateList: any = [];
  outsourcelist: RawMaterialInfo[] = [];
  matOutsourcesRaw: any = [];
  matSelectPO: PartTracker[] = [];
  cloneSelectPO: PartTracker[] = [];
  jobType: String = "start";

  code: any;
  elem;
  email: any;
  isScanned = false;
  canStart = false;
  isStarted = false;
  scanning: any = '';
  addForm: FormGroup;
  personInCharge: string;
  photo: string = '';
  mould: string = "";
  machineId: string = "";
  staffId: string = "";

  constructor(
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private zone: NgZone,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private dateFormat: DateFormatService,
    private firestore: AngularFirestore,
    private renderer: Renderer2, 
    private el: ElementRef,
    @Inject(DOCUMENT) private document: any
  ) {
    spinner.show();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.DatetimerInterval = setInterval(() => {
      this.now = Date.now();
    }, 1000)

    this.subscribe = this.angularFireAuth.authState.subscribe(async authState => {
      if (authState === null) {
        this.subscribe.unsubscribe();
      } else {
        this.subscribe.unsubscribe();
        this.machineId = authState.uid;
        const snapshot = await this.db.database.ref('Operators').child(authState.uid).once('value');

        if (snapshot.exists()) {
          this.Machine_No = snapshot.child('machineAssigned').val();

          if(snapshot.child('Process') != null){
            this.modulesList = [];
            snapshot.child('Process').forEach(snapshot2 => {
              this.modulesList.push(snapshot2.key);
            });
          }
        }
        this.spinner.hide(); 
      }
    });

    this.addForm = this.fb.group({
      outsources: this.fb.array([]),
    });
  }

  show() {
    this.ViewUpcoming = !this.ViewUpcoming;
  }

  start(){
    this.viewForm = true;
    this.jobType = "Start";
    this.isStarted = true;
  }

  onhold(){
    this.viewForm = true;
    this.jobType = "On-hold";
    this.isStarted = false;
    this.outsourcelist = [];
    this.outsources().clear();

    this.db.database.ref('User').child(this.staffId).once('value').then(childSnapshot => {
      childSnapshot.child('Schedule').forEach(snapshot2 => {
        if(snapshot2.child('_machine') != null 
        && snapshot2.child('_machine').val() != "" 
        && snapshot2.child('_machine').val() == this.machineId
        && snapshot2.child('_Status').val() != "Complete"){
          let jo = this.matSelectPO.find(a=>a.JO_No == snapshot2.child('_JO_No').val());
          this.outsources().push(this.newOutsourcesBarcode(snapshot2.child('_JO_No').val(), jo.SO_No, jo.PO_Part_No, jo.PO_Part_Name, jo.POQuantity, jo.FinishedQuantity,  snapshot2.key, snapshot2.child('_machine_schedule').val()));
          this.outsourcelist.push(null);
        }
      });
    });
  }

  resume(){
    this.viewForm = true;
    this.jobType = "Resume";
    this.isStarted = false;
    this.outsourcelist = [];
    this.outsources().clear();

    this.db.database.ref('User').child(this.staffId).once('value').then(childSnapshot => {
      childSnapshot.child('Schedule').forEach(snapshot2 => {
        if(snapshot2.child('_machine') != null 
        && snapshot2.child('_machine').val() != "" 
        && snapshot2.child('_machine').val() == this.machineId
        && snapshot2.child('_Status').val() != "Complete"){
          let jo = this.matSelectPO.find(a=>a.JO_No == snapshot2.child('_JO_No').val());
          this.outsources().push(this.newOutsourcesBarcode(snapshot2.child('_JO_No').val(), jo.SO_No, jo.PO_Part_No, jo.PO_Part_Name, jo.POQuantity, jo.FinishedQuantity,  snapshot2.key, snapshot2.child('_machine_schedule').val()));
          this.outsourcelist.push(null);
        }
      });
    });
  }

  async confirm(){
    const outsources = this.addForm.get('outsources').value;

    if(outsources == null || outsources.length <= 0){
      this.toast.error('Need At Least Start One JO', 'Please Add');
      return;
    }

    const addPOModel = {
      outsources: []
    };
    
    var flag = true;
    await outsources.forEach(async (element, index) => {
      if (!element.project || !element.partName || !element.soQuantity || !element.process || !element.quantity) {
        this.toast.error('JO information(' + (index + 1) + ') not completed!', 'Please fill in');
        flag=false;
      }

      if(parseInt(element.soQuantity) > (parseInt(element.quantity) + parseInt(element.finishedQuantity))){
        const info = {
          SO_No: this.soList[index],
          JO_No: element.project,
          PO_Part_No: this.partList[index],
          PO_Part_Name: element.partName,
          SoQuantity: element.soQuantity,
          POQuantity: element.quantity,
          finished: element.finishedQuantity,
          status: "Partial " + element.process,
          staffSchedule: element.staffSchedule || "",
          stationSchedule: element.stationSchedule || "",
          nextProcess: element.nextprocess || ""
        };

        addPOModel.outsources.push(info);
      }
      else{
        const info = {
          SO_No: this.soList[index],
          JO_No: element.project,
          PO_Part_No: this.partList[index],
          PO_Part_Name: element.partName,
          SoQuantity: element.soQuantity,
          POQuantity: element.quantity,
          finished: element.finishedQuantity,
          status: element.process,
          staffSchedule: element.staffSchedule || "",
          stationSchedule: element.stationSchedule || "",
          nextProcess: element.nextprocess || ""
        };

        addPOModel.outsources.push(info);
      }
    });

    if(!flag)
      return;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to update these JO?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await addPOModel.outsources.forEach(async machine=>{
          if(machine.JO_No.includes(machine.SO_No)){
            let updates1 = {};
            var current = new Date().getTime().toString();

            if(this.jobType == "Start"){
              if(parseInt(machine.SoQuantity) > (parseInt(machine.POQuantity) + parseInt(machine.finished))){
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Finished'] = machine.POQuantity;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Remaining'] = (parseInt(machine.SoQuantity) - parseInt(machine.POQuantity));
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Status'] = "Start";
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
                this.db.database.ref('Purchase Order/').update(updates1);
              }
              else{
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Status'] = "Start";
                this.db.database.ref('Purchase Order/').update(updates1);
              }

              let updates2 = {};
              let updates3 = {};
              const id2 = uuidv4();
              const id = uuidv4();

              updates2[this.machineId + '/Schedule/' + id + '/JO Number'] = machine.JO_No;
              updates2[this.machineId + '/Schedule/' + id + '/SO Number'] = machine.SO_No;
              updates2[this.machineId + '/Schedule/' + id + '/Started Datetime'] = new Date();
              updates2[this.machineId + '/Schedule/' + id + '/Status'] = "In Progress";
              updates2[this.machineId + '/Schedule/' + id + '/Staff'] = this.staffId;
              this.db.database.ref('Operators/').update(updates2);

              updates3[this.staffId + '/Schedule/' + id2 + '/_JO_No'] = machine.JO_No;
              updates3[this.staffId + '/Schedule/' + id2 + '/_SO_No'] = machine.SO_No;
              updates3[this.staffId + '/Schedule/' + id2 + '/_Status'] = "Started";
              updates3[this.staffId + '/Schedule/' + id2 + '/_task'] = machine.status;
              updates3[this.staffId + '/Schedule/' + id2 + '/_machine'] = this.machineId;
              updates3[this.staffId + '/Schedule/' + id2 + '/_machine_schedule'] = id;
              this.db.database.ref('User/').update(updates3);
            }
            else if(this.jobType == "On-hold"){
              if(parseInt(machine.SoQuantity) > (parseInt(machine.POQuantity) + parseInt(machine.finished))){
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Finished'] = machine.POQuantity;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Remaining'] = (parseInt(machine.SoQuantity) - parseInt(machine.POQuantity));
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Status'] = "On-hold";
                this.db.database.ref('Purchase Order/').update(updates1);
              }
              else{
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Status'] = "On-hold";
                this.db.database.ref('Purchase Order/').update(updates1);
              }

              if(machine.staffSchedule != "" && machine.stationSchedule != ""){
                const id = machine.stationSchedule;
                const id2 = machine.staffSchedule;
                let updates2 = {};
                let updates3 = {};
  
                updates2[this.machineId + '/Schedule/' + id + '/JO Number'] = machine.JO_No;
                updates2[this.machineId + '/Schedule/' + id + '/SO Number'] = machine.SO_No;
                updates2[this.machineId + '/Schedule/' + id + '/Started Datetime'] = new Date();
                updates2[this.machineId + '/Schedule/' + id + '/Status'] = "On-hold";
                updates2[this.machineId + '/Schedule/' + id + '/Staff'] = this.staffId;
                this.db.database.ref('Operators/').update(updates2);
  
                updates3[this.staffId + '/Schedule/' + id2 + '/_JO_No'] = machine.JO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_SO_No'] = machine.SO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_Status'] = "On-hold";
                updates3[this.staffId + '/Schedule/' + id2 + '/_task'] = machine.status;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine'] = this.machineId;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine_schedule'] = id;
                this.db.database.ref('User/').update(updates3);
              }
              else{
                let updates2 = {};
                let updates3 = {};
                const id2 = uuidv4();
                const id = uuidv4();
  
                updates2[this.machineId + '/Schedule/' + id + '/JO Number'] = machine.JO_No;
                updates2[this.machineId + '/Schedule/' + id + '/SO Number'] = machine.SO_No;
                updates2[this.machineId + '/Schedule/' + id + '/Started Datetime'] = new Date();
                updates2[this.machineId + '/Schedule/' + id + '/Status'] = "On-hold";
                updates2[this.machineId + '/Schedule/' + id + '/Staff'] = this.staffId;
                this.db.database.ref('Operators/').update(updates2);
  
                updates3[this.staffId + '/Schedule/' + id2 + '/_JO_No'] = machine.JO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_SO_No'] = machine.SO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_Status'] = "On-hold";
                updates3[this.staffId + '/Schedule/' + id2 + '/_task'] = machine.status;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine'] = this.machineId;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine_schedule'] = id;
                this.db.database.ref('User/').update(updates3);
              }
            }
            else if(this.jobType == "Resume"){
              if(parseInt(machine.SoQuantity) > (parseInt(machine.POQuantity) + parseInt(machine.finished))){
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Finished'] = machine.POQuantity;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Remaining'] = (parseInt(machine.SoQuantity) - parseInt(machine.POQuantity));
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Status'] = "On-hold";
                this.db.database.ref('Purchase Order/').update(updates1);
              }
              else{
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Status'] = "On-hold";
                this.db.database.ref('Purchase Order/').update(updates1);
              }

              if(machine.staffSchedule != "" && machine.stationSchedule != ""){
                const id = machine.stationSchedule;
                const id2 = machine.staffSchedule;
                let updates2 = {};
                let updates3 = {};
  
                updates2[this.machineId + '/Schedule/' + id + '/JO Number'] = machine.JO_No;
                updates2[this.machineId + '/Schedule/' + id + '/SO Number'] = machine.SO_No;
                updates2[this.machineId + '/Schedule/' + id + '/Started Datetime'] = new Date();
                updates2[this.machineId + '/Schedule/' + id + '/Status'] = "Resume";
                updates2[this.machineId + '/Schedule/' + id + '/Staff'] = this.staffId;
                this.db.database.ref('Operators/').update(updates2);
  
                updates3[this.staffId + '/Schedule/' + id2 + '/_JO_No'] = machine.JO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_SO_No'] = machine.SO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_Status'] = "Resume";
                updates3[this.staffId + '/Schedule/' + id2 + '/_task'] = machine.status;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine'] = this.machineId;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine_schedule'] = id;
                this.db.database.ref('User/').update(updates3);
              }
              else{
                let updates2 = {};
                let updates3 = {};
                const id2 = uuidv4();
                const id = uuidv4();
  
                updates2[this.machineId + '/Schedule/' + id + '/JO Number'] = machine.JO_No;
                updates2[this.machineId + '/Schedule/' + id + '/SO Number'] = machine.SO_No;
                updates2[this.machineId + '/Schedule/' + id + '/Started Datetime'] = new Date();
                updates2[this.machineId + '/Schedule/' + id + '/Status'] = "Resume";
                updates2[this.machineId + '/Schedule/' + id + '/Staff'] = this.staffId;
                this.db.database.ref('Operators/').update(updates2);
  
                updates3[this.staffId + '/Schedule/' + id2 + '/_JO_No'] = machine.JO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_SO_No'] = machine.SO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_Status'] = "Resume";
                updates3[this.staffId + '/Schedule/' + id2 + '/_task'] = machine.status;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine'] = this.machineId;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine_schedule'] = id;
                this.db.database.ref('User/').update(updates3);
              }
            }
            else{
              if(parseInt(machine.SoQuantity) > (parseInt(machine.POQuantity) + parseInt(machine.finished))){
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Finished'] = machine.POQuantity;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Remaining'] = (parseInt(machine.SoQuantity) - parseInt(machine.POQuantity));
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Status'] = "Complete";
                this.db.database.ref('Purchase Order/').update(updates1);
              }
              else{
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = machine.nextProcess;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = machine.status;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
                updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Status'] = "Complete";
                this.db.database.ref('Purchase Order/').update(updates1);
              }

              if(machine.staffSchedule != "" && machine.stationSchedule != ""){
                const id = machine.stationSchedule;
                const id2 = machine.staffSchedule;
                let updates2 = {};
                let updates3 = {};
  
                updates2[this.machineId + '/Schedule/' + id + '/JO Number'] = machine.JO_No;
                updates2[this.machineId + '/Schedule/' + id + '/SO Number'] = machine.SO_No;
                updates2[this.machineId + '/Schedule/' + id + '/Started Datetime'] = new Date();
                updates2[this.machineId + '/Schedule/' + id + '/Status'] = "Complete";
                updates2[this.machineId + '/Schedule/' + id + '/Staff'] = this.staffId;
                this.db.database.ref('Operators/').update(updates2);
  
                updates3[this.staffId + '/Schedule/' + id2 + '/_JO_No'] = machine.JO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_SO_No'] = machine.SO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_Status'] = "Complete";
                updates3[this.staffId + '/Schedule/' + id2 + '/_task'] = machine.status;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine'] = this.machineId;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine_schedule'] = id;
                this.db.database.ref('User/').update(updates3);
              }
              else{
                let updates2 = {};
                let updates3 = {};
                const id2 = uuidv4();
                const id = uuidv4();
  
                updates2[this.machineId + '/Schedule/' + id + '/JO Number'] = machine.JO_No;
                updates2[this.machineId + '/Schedule/' + id + '/SO Number'] = machine.SO_No;
                updates2[this.machineId + '/Schedule/' + id + '/Started Datetime'] = new Date();
                updates2[this.machineId + '/Schedule/' + id + '/Status'] = "Complete";
                updates2[this.machineId + '/Schedule/' + id + '/Staff'] = this.staffId;
                this.db.database.ref('Operators/').update(updates2);
  
                updates3[this.staffId + '/Schedule/' + id2 + '/_JO_No'] = machine.JO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_SO_No'] = machine.SO_No;
                updates3[this.staffId + '/Schedule/' + id2 + '/_Status'] = "Complete";
                updates3[this.staffId + '/Schedule/' + id2 + '/_task'] = machine.status;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine'] = this.machineId;
                updates3[this.staffId + '/Schedule/' + id2 + '/_machine_schedule'] = id;
                this.db.database.ref('User/').update(updates3);
              }
            }
          }
        });

        window.location.reload();
      }
    });
  }

  cancel(){
    window.location.reload();
  }

  async finish() {
    this.viewForm = true;
    this.jobType = "Complete";
    this.isStarted = false;
    this.outsourcelist = [];
    this.outsources().clear();

    this.db.database.ref('User').child(this.staffId).once('value').then(childSnapshot => {
      childSnapshot.child('Schedule').forEach(snapshot2 => {
        if(snapshot2.child('_machine') != null 
        && snapshot2.child('_machine').val() != "" 
        && snapshot2.child('_machine').val() == this.machineId
        && snapshot2.child('_Status').val() != "Complete"){
          let jo = this.matSelectPO.find(a=>a.JO_No == snapshot2.child('_JO_No').val());
          this.outsources().push(this.newOutsourcesBarcode(snapshot2.child('_JO_No').val(), jo.SO_No, jo.PO_Part_No, jo.PO_Part_Name, jo.POQuantity, jo.FinishedQuantity, snapshot2.key, snapshot2.child('_machine_schedule').val()));
          this.outsourcelist.push(null);
        }
      });
    });
  }

  getMachineInfo(DataSnapshot) {
    this.zone.run(async () => {
      this.Machine = this.machinedb_Controller.getSpecifyMachine(DataSnapshot);

      this.Machine.Schedule_Track = await this.getSortedScheduleList(this.Machine.Schedule_Track);

      if (this.Machine.Schedule_Track.length > 0) {
        if ((this.Machine.Schedule_Track[0].DatePresettingStart == "-" ||
          this.Machine.Schedule_Track[0].DatePresettingEnd == "-") &&
          this.Machine.Schedule_Track[0].Machine_Schedule_Status == "Waiting") {
          this.Presetting_flag = true;
        }
      }


      let Stopped_Schedule = this.getStoppedSchedule(this.Machine.Schedule_Track);

      if (Stopped_Schedule != null && this.Machine.Machine_Code.match('-')) {
        //this.Display_flag = true;
        this.getFlaskServerStatus();
        for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
          this.Machine.Schedule_Track[i].Active_Schedule_Status = false;
        }
      }
    });

    //this.machinedb_Controller.getUnsolveMachineStatus(this.Machine.Machine_No).then(flag =>{})
  }

  getFlaskServerStatus() {
    this.httpClient.post('http://127.0.0.1:5002/handshaking', { responseType: 'text' }).subscribe(
      response => {
        var splitted_response = response.toString().split("@@@");
        //False = machine without failure
        //True = machine with failure
        var machineFailureStatus = (splitted_response[1] == "true")

        if (this.Machine) {
          let Stopped_Schedule = this.getStoppedSchedule(this.Machine.Schedule_Track)
          let InProgress_Schedule = this.getInProgressSchedule(this.Machine.Schedule_Track)

          if (splitted_response[0].match("Thread is stopped") || splitted_response[0].match("Thread is not exist")) {
            if (InProgress_Schedule != null) {

              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: this.Machine.MachineStatus,
                code: this.Machine.Machine_Code,
                po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
                scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
                action: "Update Schedule Status to Stop due to thread is stopped or not exist"
              }
              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);


              this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + InProgress_Schedule.Machine_Schedule_No).update({ 'Schedule Status': 'Stopped' });
            }

            if (Stopped_Schedule != null) {
              if (this.Machine && this.Machine.Machine_Code.match('-')) {
                this.showToasterWarning();
                this.Display_flag = true;

                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              } else if (this.Machine && !machineFailureStatus) {
                this.Machine.Schedule_Track[0].Active_Schedule_Status = true;
                this.showToasterSuccess("The server is ready to start the task");
                this.Display_flag = false;
              } else if (machineFailureStatus) {
                this.showToasterMachineWarning();
                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              }
            } else {
              let Waiting_Schedule = this.getWaitingSchedule(this.Machine.Schedule_Track)
              if (Waiting_Schedule != null && !machineFailureStatus) {
                this.Machine.Schedule_Track[0].Active_Schedule_Status = true;
              } else if (machineFailureStatus) {
                this.showToasterMachineWarning();
                if (Waiting_Schedule != null) {
                  this.Machine.Schedule_Track[0].Active_Schedule_Status = false;
                }
              }
            }

          } else if (splitted_response[0].match("Thread is running")) {
            if (Stopped_Schedule != null) {
              if (this.Machine && this.Machine.Machine_Code.match('-')) {
                this.showToasterWarning();
                this.Display_flag = true;

                for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
                  this.Machine.Schedule_Track[i].Active_Schedule_Status = false
                }
              } else {
                this.Display_flag = false;
              }
            } else {
              this.Display_flag = false;
            }
          }
          this.zone.run(async () => { this.Machine, this.spinner.hide(); })
        }
      },
      error => {
        this.zone.run(async () => { this.spinner.hide(); })
        this.showToasterError("Ensure the flask server is connected");
        this.Display_flag = false

        if (this.Machine) {
          let InProgress_Schedule = this.getInProgressSchedule(this.Machine.Schedule_Track)
          if (InProgress_Schedule != null) {
            const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
            const log = {
              date: new Date(),
              status: this.Machine.MachineStatus,
              code: this.Machine.Machine_Code,
              po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
              scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
              action: "Update Schedule Status to Stop due to flask server is not connected"
            }
            this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);

            this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + InProgress_Schedule.Machine_Schedule_No).update({ 'Schedule Status': 'Stopped' });
          }

          for (var i = 0; i < this.Machine.Schedule_Track.length; i++) {
            this.Machine.Schedule_Track[i].Active_Schedule_Status = false
          }
        }
      }
    );
  }

  showToasterSuccess(message: string) {
    this.toast.success(message, "", { timeOut: 2000, positionClass: 'toast-top-left' });
  }

  showToasterError(message: string) {
    this.toast.toastrConfig
    this.toast.error(message, "Error!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  showRestartToasterWarning(message: string) {
    this.toast.toastrConfig
    this.toast.warning(message, "Warning!", { timeOut: 15000, positionClass: 'toast-top-center' });
  }

  showToasterWarning() {
    this.toast.warning("Enter the interrupt code for the machine stopped", "Warning!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  showToasterMachineWarning() {
    this.toast.warning("The machine failure is not resolved.", "Warning!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  getMachineFailureStatus() {

    this.httpClient.post('http://127.0.0.1:5002/machineFailureStatus', { responseType: 'text' }).subscribe(
      response => {
        if (response.toString().match("true")) {
          this.machineFailureStatus = true
        } else {
          this.machineFailureStatus = false
        }
      },
      error => {
        this.machineFailureStatus = true
      }
    )
  }

  setCounterTally() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(AlignDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if (Number(result)) {

          const dialogConfig = new MatDialogConfig();
          dialogConfig.autoFocus = false;
          dialogConfig.height = 'auto';
          dialogConfig.width = 'auto';
          const position = {
            top: '5%'
          };
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.data = 'Align the actual quantity to ' + result + '.\nThe changes will be reflected in the next production cycle.';
          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: this.Machine.MachineStatus,
                code: this.Machine.Machine_Code,
                po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
                scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No,
                action: "Tally Quantity"
              }
              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
              this.httpClient.post('http://127.0.0.1:5002/tally', (result), { responseType: 'text' }).subscribe(
                data => { this.showToasterSuccess("The value will be updated on the next production cycle") },
                error => { }
              )
            }
          })
        } else {

        }
      }
    })
  }
  
  enterPinToFinishSchedule(machineNumber, Schedule: ScheduleTracker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(PinDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        if (Number(result)) {
          var snapshot = await this.db.database.ref('Passcode').once('value');
          var isValidPin = false;
          if (snapshot.exists()) {
            if (result == snapshot.child('Passcode1').val()) {
              this.personInCharge = snapshot.child('Person1').val();
              isValidPin = true;
            } else if (result == snapshot.child('Passcode2').val()) {
              this.personInCharge = snapshot.child('Person2').val();
              isValidPin = true;
            } else if (result == snapshot.child('Passcode3').val()) {
              this.personInCharge = snapshot.child('Person3').val();
              isValidPin = true;
            } else if (result == snapshot.child('Passcode4').val()) {
              this.personInCharge = snapshot.child('Person4').val();
              isValidPin = true;
            }

            if (isValidPin) {
              this.showToasterSuccess("Identity validated! Proceed to further action!");
              this.ScheduleFinishDialog(machineNumber, Schedule);
            } else {
              this.showToasterError("Invalid PIN number");
            }
          }
        } else {

        }
      }
    })
  }

  staffChange(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(StaffDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.height = 'auto';
        dialogConfig.width = 'auto';

        const position = {top: '5%'};
        dialogConfig.position = position;
        dialogConfig.disableClose = true;

        if(result.includes('/') || result.includes('@')){
          dialogConfig.data = '/ and @ is not allowed !!!';

          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              console.log(data);
            }
          });
        }
        else{
          this.db.database.ref('User').once('value').then(data => {
            if (data.exists()) {
              var found = false;
              var month = new Date().getMonth()+1;
              var year = new Date().getFullYear();
              var day = new Date().getDate();
              var hour = new Date().getHours();
              var minutes = new Date().getMinutes();
              var seconds = new Date().getSeconds();
              var currentTimestamp = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;

              data.forEach(childSnapshot2 => {
                if(childSnapshot2.child("StaffNo").val() == result){
                  this.staffId = childSnapshot2.key;
                  this.staffMessage = childSnapshot2.child("StaffName").val();
                  found = true;
                }
              });

              if(found){
                dialogConfig.data = 'Confirm Your Staff Id is ' + result;

                this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
                  this.staffMessage = result;
                  this.staffMessage = result + ' - ' +this.staffMessage
                  this.canStart = true;
                });
              }
              else{
                dialogConfig.data = 'Employee No is not found!';

                this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
                  if (data) {
                    console.log(data);
                  }
                });
              }
            }
          });
        }
      }
    });
  }

  completeQuantityChange(formindex:0){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';

    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(QuantityDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.height = 'auto';
        dialogConfig.width = 'auto';

        const position = {top: '5%'};
        dialogConfig.position = position;
        dialogConfig.disableClose = true;

        if(result.includes('/') || result.includes('@')){
          dialogConfig.data = '/ and @ is not allowed !!!';

          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              console.log(data);
            }
          });
        }
        else{
          ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('quantity').patchValue(result); 
        }
      }
    });
  }

  ScheduleFinishDialog(machineNumber, Schedule: ScheduleTracker) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    if (Schedule.Acc_Qty < Schedule.Exp_Qty) {
      dialogConfig.data = 'The production quantity (' + Schedule.Acc_Qty + ') is less than the PO required quantity!';
    } else {
      dialogConfig.data = 'The production quantity is ' + Schedule.Acc_Qty;
    }

    this.dialog.open(FinishDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.partdb_Controller.search_Part(Schedule.Machine_Schedule_Part_No).then(data => {
          Schedule.PartCavityNum = String(data.Number_Cavity);
          Schedule.Machine_Number = this.Machine.Machine_No.split(" ")[1].trim();
          Schedule.PredefinedCycleTime = String(data.Cycle_Time);
          let startDate = new Date(Schedule.DateFormatStart);
          let endDate = new Date();
          let presetStartDate = new Date(Schedule.DatePresettingStart);
          let presetEndDate = new Date(Schedule.DatePresettingEnd);
          let totalRunTime = Math.abs(endDate.getTime() - startDate.getTime()) / 3600000;
          let totalPresetTime = (Math.abs(presetEndDate.getTime() - presetStartDate.getTime())) / 3600000;
          let totalBreakTime = Schedule.TotalDownTime;
          Schedule.Availability = (totalRunTime - totalPresetTime - totalBreakTime )/totalRunTime;
          Schedule.Performance = (Schedule.Acc_Qty * (data.Cycle_Time / 3600)) / totalRunTime;
          const addPOModel = {
            po: Schedule.Machine_Schedule_PO_No,
            machineChosen: machineNumber,
            scheduleID: Schedule.Machine_Schedule_No,
            scheduleID2: Schedule.Machine_Schedule_No2,
            scheduleID3: Schedule.Machine_Schedule_No3,
            availability: Number.isFinite(Schedule.Availability) ? Schedule.Availability : 0,
            performance: Number.isFinite(Schedule.Performance) ? Schedule.Performance : 0,
            Acc_Qty: Schedule.Acc_Qty,
            pic: this.personInCharge,
          };

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: addPOModel.machineChosen + ' Job End by <b>' + this.email + '</b>\n' + Schedule.Machine_Schedule_PO_No,
            date: new Date(),
            addPOModel
          };

          this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
          this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

          this.httpClient.post('http://127.0.0.1:5002/endSchedule', (Schedule), { responseType: 'text' }).subscribe(
            response => {
              this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "OFF" });
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: 'OFF',
                code: this.Machine.Machine_Code,
                po: Schedule.Machine_Schedule_PO_No,
                scheduleNo: Schedule.Machine_Schedule_No,
                action: "End Schedule"
              }
              this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);

              if (response.toString().match("Production completed")) {
                this.showToasterSuccess("Scheduled task completed!");
                this.machinedb_Controller.updateJobOEE(addPOModel);
              }
            },
            error => {
              this.showToasterError("The scheduled task unable to finish due to the flask server is not connected.");
            }
          );
        })
      }
    })
  }

  outsources(): FormArray {
    return this.addForm.get('outsources') as FormArray;
  }

  async barcode(event){
    this.isScanned = true;
    let jo = await this.matSelectPO.find(a=>a.JO_No == event.target.value);
    //let time = this.addForm.get('receivedDate').value;
    //time.setDate(time.getDate() + parseInt(this.Rule));
    this.outsources().push(this.newOutsourcesBarcode(event.target.value, jo.SO_No, jo.PO_Part_No, jo.PO_Part_Name, jo.POQuantity, jo.FinishedQuantity, "", ""));
    this.outsourcelist.push(null);

    $('#barcodeScan').val('');
    $('#barcodeScan').trigger('focus');
  }

  newOutsourcesBarcode(joNo: any, SO_No: any, partNumber: any, name: any, quant: any, finished: any, staffSchedule: any, stationSchedule: any): FormGroup {
    const projectFilterControl = new FormControl();
    this.partList.push(partNumber);
    //this.endDateList.push(endDate);
    this.soList.push(SO_No);

    projectFilterControl.valueChanges.subscribe(() => {
      this.findJO(projectFilterControl);
    });

    this.scrollToBottom();

    return this.fb.group({
      projectFilterControl,
      project: joNo,
      partName: name,
      process: this.modulesList[0],
      soQuantity: quant,
      finishedQuantity: finished,
      quantity: quant - finished,
      staffSchedule: staffSchedule,
      stationSchedule: stationSchedule,
      nextprocess: ""
    });
  }

  newOutsources(): FormGroup {
    const projectFilterControl = new FormControl();

    projectFilterControl.valueChanges.subscribe(() => {
      this.findJO(projectFilterControl);
    });

    this.scrollToBottom();

    return this.fb.group({
      projectFilterControl,
      project: "",
      partName: "",
      process: this.modulesList[0],
      soQuantity: 0,
      finishedQuantity: 0,
      quantity: 0,
      staffSchedule: "",
      stationSchedule: "",
      nextprocess: ""
    });
  }

  addOutsources() {
    this.outsources().push(this.newOutsources());
    this.outsourcelist.push(null);
  }

  scan(){
    $('#barcode').val('');
    $('#barcode').trigger('focus');
    this.scanning = "Scanning......";
  }

  removeOutsource(i: number) {
    if(!this.isScanned){
      this.outsources().removeAt(i);
      this.outsourcelist.splice(i, 1);
    }
    else{
      this.isScanned = false;
    }   
  }

  async joChange(event, formindex:0){
    let jo = await this.matSelectPO.find(a=>a.JO_No == event.value);

    if(jo && jo.PO_Status != 'Processing'){
      this.partList[formindex] = jo.PO_Part_ID;
      //this.endDateList.push(jo.EndDate);
      this.soList[formindex] = jo.SO_No;
      ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('partName').patchValue(jo.PO_Part_No);
      ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('soQuantity').patchValue(jo.POQuantity);
      ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('finishedQuantity').patchValue(jo.FinishedQuantity);
      ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('quantity').patchValue(jo.POQuantity - jo.FinishedQuantity);
    }
    else{
      this.toast.error('This JO already started');
    }
  }

  ngOnInit() {
    this.elem = document.getElementById('full');
    this.po_controller.getIncompleteJOList().then(data => {
      this.matSelectPO = data;
      this.cloneSelectPO = this.matSelectPO.slice();
      this.spinner.hide();
    });
  }

  ngAfterViewInit(): void {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  ngOnDestroy() {
    // Will clear when component is destroyed e.g. route is navigated away from.
    clearInterval(this.DatetimerInterval);
    clearInterval(this.flaskServertimerInterval);
  }

  findJO(supplier) {
    if (!this.cloneSelectPO) { return; }
    const search = supplier.value;
    this.matSelectPO = this.cloneSelectPO.filter(p => p.JO_No.toLowerCase().includes(search.toLowerCase()));
  }

  restartWebApp() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Close and restart the current web application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.httpClient.post('http://127.0.0.1:5002/RestartWebApp', { responseType: 'text' }).subscribe(
          data => {
            this.showToasterSuccess("Restarting the web application!")
          },
          error => {
            this.showRestartToasterWarning("Unable to locate the flask server, please the restart server manually! \n **(Close the web app -> click the ClickMe.sh)**");
          }
        );
      }
    })
  }

  startProcess(Schedule: ScheduleTracker) {
    this.Button_flag = true;
    Schedule.Button_Status = true;
    Schedule.Active_Schedule_Status = false;

    this.partdb_Controller.search_Part(Schedule.Machine_Schedule_Part_No).then(data => {
      Schedule.PartCavityNum = String(data.Number_Cavity)
      Schedule.Machine_Number = this.Machine.Machine_No.split(" ")[1].trim()
      Schedule.PredefinedCycleTime = String(data.Cycle_Time)
      if (Schedule.Acc_Qty === 0) {
        const d = {
          startAt: new Date(),
          endAt: new Date(new Date().getTime() + (Schedule.DateFormatEnd.getTime() - Schedule.DateFormatStart.getTime()))
        }
        this.db.database.ref('Machine/' + this.Machine_No + '/Schedule/' + Schedule.Machine_Schedule_No).update(d);
        if (Schedule.Machine_Schedule_No2)
          this.db.database.ref('Machine/@@' + this.Machine_No + '_1/Schedule/' + Schedule.Machine_Schedule_No2).update(d);
        if (Schedule.Machine_Schedule_No3)
          this.db.database.ref('Machine/@@' + this.Machine_No + '_2/Schedule/' + Schedule.Machine_Schedule_No3).update(d);



      }
      this.httpClient.post('http://127.0.0.1:5002/', (Schedule), { responseType: 'text' }).subscribe(
        data => {
          const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: this.Machine_No + ' started by <b>' + this.email + '</b>\n',
            date: new Date(),
          };
          const status = {
            Status: "In Progress",
          }
          this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No).update(status);
          if (Schedule.Machine_Schedule_No2)
            this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No2).update(status);
          if (Schedule.Machine_Schedule_No3)
            this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No3).update(status);


          this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
          this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);


          this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "ON" });
          const log = {
            date: new Date(),
            status: 'ON',
            code: this.Machine.Machine_Code,
            po: Schedule.Machine_Schedule_PO_No,
            scheduleNo: Schedule.Machine_Schedule_No,
            action: "Start Process"
          }
          this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
          this.machinedb_Controller.Machine_restoreLogState(this.Machine_No, Schedule.Machine_Schedule_No)
          this.Button_flag = false
        },
        error => {
          this.Button_flag = false
        }
      )
    })
  }
  
  async getPart(schedule: ScheduleTracker): Promise<void> {
    var snapshot = await this.db.database.ref('Part').child(schedule.Machine_Schedule_Part_No).once('value');
    if (snapshot.exists()) {
      this.mould = snapshot.child('Mould/Mould Referance No').val();

      if (this.photo !== snapshot.child('PhotoURL').val()) {
        this.photo = snapshot.child('PhotoURL').val();
      }
    }
  }

  async getRawMaterialUsed(schedule: ScheduleTracker): Promise<void> {
    var snapshot = await this.db.database.ref('Purchase Order').
      child(schedule.Machine_Schedule_PO_No + '/Part List/' + schedule.Machine_Schedule_No + '/Raw Materials Used').once('value');
    if (snapshot.exists()) {
      schedule.RawMaterialUsed = [];
      snapshot.forEach(element => {
        element.forEach(s => {
          console.log(s.val());
          const index = schedule.RawMaterialUsed.findIndex(u => u.MaterialName === s.child('RawMaterialName').val());
          if (index !== -1) {
            schedule.RawMaterialUsed[index].Quantity += parseInt(s.child('Quantity Checkout').val());
          } else {
            const rawMaterialUsed = new RawMaterialUsed;
            rawMaterialUsed.Quantity = s.child('Quantity Checkout').val();
            rawMaterialUsed.MaterialName = s.child('RawMaterialName').val();
            schedule.RawMaterialUsed.push(rawMaterialUsed);
          }
        })
      })
    }
  }

  async getSortedScheduleList(Schdule_TrackList: ScheduleTracker[]): Promise<ScheduleTracker[]> {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }

    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList)

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList)

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false
      SortedScheduleList.unshift(InProgress_Schedule)
    } else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true
      SortedScheduleList.unshift(Stopped_Schedule)

    } else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true
    }
    SortedScheduleList.forEach(async e => {
      await this.getRawMaterialUsed(e);
    })

    if (SortedScheduleList.length > 0) {
      await this.getPart(SortedScheduleList[0]);
    }
    return SortedScheduleList;
  }

  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }

      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    let InProgress_Schedule: ScheduleTracker;
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {

        InProgress_Schedule = Schdule_TrackList[i]
        return InProgress_Schedule
      }
    }
    return null;
  }

  getStoppedSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Stopped_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")) {
        Stopped_Schedule = Schdule_TrackList[i]
        return Stopped_Schedule
      }
    }
    return null;
  }

  submitCode(code, Schedule: ScheduleTracker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Submit this interrupt code (' + code + ')?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.getFlaskServerStatus();
        this.db.database.ref('Machine/' + this.Machine_No).update({ Code: code });
        this.db.database.ref('Machine/' + this.Machine_No).update({ "Machine Status": "OFF" });

        const status = {
          Status: "Stopped",
        }
        this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No).update(status);
        if (Schedule.Machine_Schedule_No2)
          this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No2).update(status);
        if (Schedule.Machine_Schedule_No3)
          this.db.database.ref('Purchase Order/' + Schedule.Machine_Schedule_PO_No + '/Part List/' + Schedule.Machine_Schedule_No3).update(status);

        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: "Machine " + this.Machine_No + ' Submit code by <b>' + this.email + '</b>\n',
          date: new Date(),
        };

        this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

        const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
        const log = {
          date: new Date(),
          status: 'OFF',
          code: code,
          action: "Submit Interrupt Code",
          po: this.Machine.Schedule_Track[0].Machine_Schedule_PO_No,
          scheduleNo: this.Machine.Schedule_Track[0].Machine_Schedule_No
        }
        this.firestore.collection('MachinePerformanceLog').doc(this.Machine.Machine_No).collection(currentTime).add(log);
        this.machinedb_Controller.setMachine_Logfile(this.Machine_No, this.getStoppedSchedule(this.Machine.Schedule_Track).Machine_Schedule_No, code);
        this.CodeSubmitted_flag = true;
        // document.getElementById("overlay").style.display = "none";
      }
    })
  }

  stop() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };

    /*
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Stop the process?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.machineRunning = false;
        this.stopProcess()

        this.db.database.ref('Machine/'+ this.Machine_No + '/Schedule/' + this.getInProgressSchedule(this.Machine.Schedule_Track).Machine_Schedule_No).update({'Schedule Status': 'Stopped'});
        this.machinedb_Controller.saveMachine_StoppedTime(this.Machine_No,this.getInProgressSchedule(this.Machine.Schedule_Track).Machine_Schedule_No);

        this.AlertTimerFunc();
      }
    })
    */
    //this.machinedb_Controller.saveMachine_StoppedTime(this.Machine_No,this.getStoppedSchedule(this.Machine.Schedule_Track).Machine_Schedule_No);
    this.AlertTimerFunc();
  }

  AlertTimerFunc() {
    let counter: number = 900 //seconds
    this.AlertTimer = setInterval(() => {
      if (counter > 0) {
        counter--;
        let m: number = Math.floor(counter / 60);
        let s: number = counter % 60;

        let minutes = m < 10 ? "0" + m : String(m);
        let seconds = s < 10 ? "0" + s : String(s);

        this.AlertCountDown = minutes + ":" + seconds;
      } else {
        clearInterval(this.AlertTimer);
      }
    }, 1000)
  }

  viewScheduleDetail(schedule): void {
    const dialogRefupdatePart = this.dialog.open(DetailsScheduleComponent, {
      data: schedule,
      width: '70%',
      height: '30%'
    });
  }

  startPresettingTime() {
    let startTime = Date.now();
    const addPOModel = {
      machineChosen: this.Machine.Machine_No,
      scheduleID: this.Machine.Schedule_Track[0].Machine_Schedule_No,
      startTimer: startTime,
    };
    this.machinedb_Controller.updatePresetStartTime(addPOModel);
    let element = <HTMLInputElement>document.getElementById("mouldSetEnd");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldSetStart");
    element1.disabled = true;
  }

  continueProcess(message: string, message1: string) {
    let element = <HTMLInputElement>document.getElementById(message);
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById(message1);
    element1.disabled = true;
  }

  endPresettingTime() {
    let endTime = Date.now();
    const addPOModel = {
      machineChosen: this.Machine.Machine_No,
      scheduleID: this.Machine.Schedule_Track[0].Machine_Schedule_No,
      endTimer: endTime,
    };
    this.machinedb_Controller.updatePresetEndTime(addPOModel);
    let element = <HTMLInputElement>document.getElementById("mouldTestEnd");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldTestStart");
    element1.disabled = true;
    this.Presetting_flag = false;
    this.resetPresetGUI();
  }

  resetPresetGUI() {
    let element = <HTMLInputElement>document.getElementById("mouldSetStart");
    element.disabled = false;
    let element1 = <HTMLInputElement>document.getElementById("mouldSetEnd");
    element1.disabled = true;
    let element2 = <HTMLInputElement>document.getElementById("preheatStart");
    element2.disabled = true;
    let element3 = <HTMLInputElement>document.getElementById("preheatEnd");
    element3.disabled = true;
    let element4 = <HTMLInputElement>document.getElementById("mouldTestStart");
    element4.disabled = true;
    let element5 = <HTMLInputElement>document.getElementById("mouldTestEnd");
    element5.disabled = true;
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
  }

  scanEmployee() {
    this.renderer.setProperty(this.el.nativeElement.querySelector('#barcodeScanStaff'), 'value', '');
    this.renderer.selectRootElement(this.el.nativeElement.querySelector('#barcodeScanStaff')).focus();
  }

  async scanStaff(event: any){
    if(event.target.value != null && event.target.value != ''){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = 'auto';

      const position = {top: '5%'};
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      var cardId = event.target.value

      this.db.database.ref('User').once('value').then(data => {
        if (data.exists()) {
          var found = false;
          var month = new Date().getMonth()+1;
          var year = new Date().getFullYear();
          var day = new Date().getDate();
          var hour = new Date().getHours();
          var minutes = new Date().getMinutes();
          var seconds = new Date().getSeconds();
          var currentTimestamp = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
          var staffNo = "";

          data.forEach(childSnapshot2 => {
            if(childSnapshot2.child("CardID").val() == cardId){
              this.staffId = childSnapshot2.key;
              staffNo = childSnapshot2.child("StaffNo").val();
              found = true;
            }
          });

          if(found){
            dialogConfig.data = 'Confirm Your Staff Id is ' + staffNo;

            this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
              this.staffMessage = staffNo;
              this.canStart = true;
            });
          }
          else{
            dialogConfig.data = 'Employee No is not found!';

            this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
              if (data) {
                console.log(data);
              }
            });
          }
        }
      });
    }

    //this.scanning = '';
    $('#barcodeScanStaff').val('');
  }
}

export class SchedulerComponent {
  private Machine_Schedule_No: string;
  private Exp_Qty: number;
  private Acc_Qty: number;
  private Machine_Schedule_PO_No: string;
  private Machine_Schedule_Part_No: string;
  private Machine_Schedule_Status: string;

  constructor() { }
}
