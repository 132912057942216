import { element } from 'protractor';
import { AngularFireDatabase } from '@angular/fire/database';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { ScheduleTracker, Staff } from '../Object_Classes/Staff/Staff';
import { ReplaySubject } from 'rxjs';
import { MachineSchedule, Machine } from '../Object_Classes/Machine/Machine';

let firebase = require("firebase");
const firebaseConfig = {
  apiKey: "AIzaSyAgtGxH5UfURiWeANkFGzHmgdFXqrYSkLs",
  authDomain: "rcet-76240.firebaseapp.com",
  databaseURL: 'https://rcet-76240-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: "rcet-76240",
  storageBucket: "rcet-76240.appspot.com",
  messagingSenderId: "570282839600",
  appId: "1:570282839600:web:475095f693125df1cea7f5",
  measurementId: "G-9YEQ16XT2V"
};

/*const firebaseConfig = {
  apiKey: "AIzaSyD871Tu53w8TsTVj66Id_yFC0uNe3g9E7I",
  authDomain: "rect-uat.firebaseapp.com",
  databaseURL: 'https://rect-uat-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: "rect-uat",
  storageBucket: "rect-uat.appspot.com",
  messagingSenderId: "20077112761",
  appId: "1:20077112761:web:e465982d801e71a2ce9a68",
  measurementId: "G-V7785ER5SJ"
};*/

export class StaffDB_controller {
  app;
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }

  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getStaffList(): Promise<Staff[]> {
    let StaffList: Staff[] = [];
    var snapshot = await this.db.database.ref('User').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const staff = new Staff();
        staff.StaffID = childSnapshot.key;
        staff.StaffName = childSnapshot.child('StaffName').val();
        staff.Card_ID = childSnapshot.child('CardID').val();
        staff.StaffNo = childSnapshot.child('StaffNo').val();
        staff.Email = childSnapshot.child('Email').val();
        staff.Role = childSnapshot.child('Role').val();
        staff.UpdatedBy = childSnapshot.child('UpdatedBy').val();
        staff.CreatedBy = childSnapshot.child('CreatedBy').val();
        staff.CreatedDate = new Date(childSnapshot.child('CreatedDate').val());
        staff.UpdatedDate = new Date(childSnapshot.child('UpdatedDate').val());

        if (staff.Role.toUpperCase() !== 'ADMIN') {
          childSnapshot.child('Access').forEach(snapshot2 => {
            staff.Access.push(snapshot2.key);
          });

          childSnapshot.child('Submodule').forEach(snapshot2 => {
            staff.Submodule.push(snapshot2.key);
          });
        }

        let schedule_TrackList: ScheduleTracker[] = [];

        childSnapshot.child("Schedule").forEach(childSnapshot2 => {
          if(childSnapshot2.child('_Status').val() != 'Completed'){
            const scheduleTracker = new ScheduleTracker();

            scheduleTracker.ID = childSnapshot2.key;
            scheduleTracker.PO_No = childSnapshot2.child('_SO_No').val();
            scheduleTracker.JO_No = childSnapshot2.child('_JO_No').val();
            schedule_TrackList.push(scheduleTracker);
          }
        });

        staff.Schedule_Track = schedule_TrackList;
        StaffList.push(staff);
      });
    }

    StaffList = StaffList.filter(s => s.StaffName).sort((a, b) => {
      return (a.StaffName < b.StaffName ? -1 : 1) * (true ? 1 : -1);
    })

    return StaffList;
  }

  async getStationList(): Promise<Machine[]> {
    let StaffList: Machine[] = [];
    var snapshot = await this.db.database.ref('Operators').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const staff = new Machine();
        staff.StationID = childSnapshot.key;
        staff.StationName = childSnapshot.child('name').val();
        staff.StationNo = childSnapshot.child('machineAssigned').val();
        staff.Email = childSnapshot.child('Email').val();

        childSnapshot.child('Process').forEach(snapshot2 => {
          staff.Process.push(snapshot2.key);
        });

        StaffList.push(staff);
      });
    }

    return StaffList;
  }

  async getStationScheduleList(): Promise<Machine[]> {
    let StaffList: Machine[] = [];
    var snapshot = await this.db.database.ref('Operators').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const staff = new Machine();
        staff.StationID = childSnapshot.key;
        staff.StationName = childSnapshot.child('name').val();
        staff.StationNo = childSnapshot.child('machineAssigned').val();
        staff.Email = childSnapshot.child('Email').val();

        childSnapshot.child('Process').forEach(snapshot2 => {
          staff.Process.push(snapshot2.key);
        });

        let part_trackList: MachineSchedule[] = [];
        childSnapshot.child('Schedule').forEach(childSnapshot2 => {
          if(childSnapshot2.child('Status').val() != 'Complete'){
            let part_track = new MachineSchedule;
            part_track.ID = childSnapshot2.key;
            part_track.JO_No = childSnapshot2.child('JO Number').val();
            part_track.PO_No = childSnapshot2.child('SO Number').val();
            part_track.HandledById = childSnapshot2.child('Staff').val();
            this.getJOInfo(part_track);
            this.getStaffInfo(part_track);
            part_trackList.push(part_track);
          }
        });

        staff.MachineSchedule = part_trackList;
        StaffList.push(staff);
      });
    }

    return StaffList;
  }

  async getStaffListwithSchedule(): Promise<Staff[]> {
    let StaffList: Staff[] = [];
    const snapshot = await this.db.database.ref('Schedule').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(dateSnapshot => {
        const staff = new Staff();
        staff.DateOnly = dateSnapshot.key;

        let schedule_TrackList: ScheduleTracker[] = [];
        dateSnapshot.forEach(innerSnapshot => {
          let scheduleTracker = new ScheduleTracker();
          scheduleTracker.StaffID = innerSnapshot.key;
          this.getStaffDetails(scheduleTracker);
          scheduleTracker.bf = innerSnapshot.child('bf').val();
          scheduleTracker.left = innerSnapshot.child('cf').val();
          scheduleTracker.new = innerSnapshot.child('new').val();
          scheduleTracker.complete = innerSnapshot.child('output').val();
          schedule_TrackList.push(scheduleTracker);
        });

        staff.Schedule_Track = schedule_TrackList;
        StaffList.push(staff);
      });

      StaffList = StaffList.sort((a, b) => {
        return (a.DateOnly < b.DateOnly ? -1 : 1) * (true ? 1 : -1);
      })
    }
  
    return StaffList;
  }  

  async add_Staff(_staff: Staff, email: string): Promise<ReplaySubject<any>> {
    let resultSubject = new ReplaySubject(1);
    if (!this.app) {
      this.app = firebase.initializeApp(firebaseConfig, "secondary");
    }
    await this.app.auth().createUserWithEmailAndPassword(_staff.Email, _staff.password)
      .then(async fbAuth => {
        var updates = {}
        _staff.CreatedDate = new Date();
        _staff.UpdatedDate = new Date();
        _staff.CreatedBy = email;
        _staff.UpdatedBy = email;
        updates['/' + fbAuth.user.uid + '/CardID'] = _staff.Card_ID || '';
        updates['/' + fbAuth.user.uid + '/StaffName'] = _staff.StaffName;
        updates['/' + fbAuth.user.uid + '/Email'] = _staff.Email;
        updates['/' + fbAuth.user.uid + '/Role'] = _staff.Role;
        updates['/' + fbAuth.user.uid + '/StaffNo'] = _staff.StaffNo;
        updates['/' + fbAuth.user.uid + '/CreatedDate'] = _staff.CreatedDate;
        updates['/' + fbAuth.user.uid + '/UpdatedDate'] = _staff.UpdatedDate;
        updates['/' + fbAuth.user.uid + '/CreatedBy'] = _staff.CreatedBy;
        updates['/' + fbAuth.user.uid + '/UpdatedBy'] = _staff.UpdatedBy;
        for (const acc of _staff.Access) {
          updates['/' + fbAuth.user.uid + '/Access/' + acc] = "All"
        }

        for (const acc of _staff.Submodule) {
          updates['/' + fbAuth.user.uid + '/Submodule/' + acc] = "All"
        }

        await this.db.database.ref('User').update(updates).then(e => {
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: _staff.StaffName + ' has been added by the ' + email,
            date: new Date()
          };
          this.firestore.collection('StaffLog').doc(dateFormat).set({ Date: new Date() });
          this.firestore.collection('StaffLog').doc(dateFormat).collection('Staff').add(info);
          resultSubject.next(_staff);
        });

      })
      .catch(err => {
        resultSubject.error(err);
      })
    return resultSubject;

  }

  async add_Station(_staff: Machine, email: string): Promise<ReplaySubject<any>> {
    let resultSubject = new ReplaySubject(1);
    if (!this.app) {
      this.app = firebase.initializeApp(firebaseConfig, "secondary");
    }
    await this.app.auth().createUserWithEmailAndPassword(_staff.Email, _staff.password)
      .then(async fbAuth => {
        var updates = {};
        updates['/' + fbAuth.user.uid + '/name'] = _staff.StationName;
        updates['/' + fbAuth.user.uid + '/Email'] = _staff.Email;
        updates['/' + fbAuth.user.uid + '/machineAssigned'] = _staff.StationNo;
        
        for (const acc of _staff.Process) {
          updates['/' + fbAuth.user.uid + '/Process/' + acc] = "All"
        }
        
        await this.db.database.ref('Operators').update(updates).then(e => {
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: _staff.StationName + ' has been added by the ' + email,
            date: new Date()
          };
          this.firestore.collection('OperatorsLog').doc(dateFormat).set({ Date: new Date() });
          this.firestore.collection('OperatorsLog').doc(dateFormat).collection('Operators').add(info);
          resultSubject.next(_staff);
        });

      })
      .catch(err => {
        resultSubject.error(err);
      })
    return resultSubject;
  }

  async update_Staff(_staff: Staff, email: string) {

    var updates = {}
    _staff.CreatedDate = new Date();
    _staff.UpdatedDate = new Date();
    _staff.CreatedBy = email;
    updates['/' + _staff.StaffID + '/CardID'] = _staff.Card_ID || '';
    updates['/' + _staff.StaffID + '/StaffName'] = _staff.StaffName;
    updates['/' + _staff.StaffID + '/Role'] = _staff.Role;
    updates['/' + _staff.StaffID + '/StaffNo'] = _staff.StaffNo;
    updates['/' + _staff.StaffID + '/UpdatedDate'] = _staff.UpdatedDate;
    updates['/' + _staff.StaffID + '/UpdatedBy'] = _staff.UpdatedBy;
    for (const acc of _staff.Access) {
      updates['/' + _staff.StaffID + '/Access/' + acc] = "All"
    }

    for (const acc of _staff.Submodule) {
      updates['/' + _staff.StaffID + '/Submodule/' + acc] = "All"
    }

    await this.db.database.ref('User/'+_staff.StaffID +'/Access').set(null).then(async e=>{
      await this.db.database.ref('User').update(updates).then(e => {
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: _staff.StaffName + ' has been updated by the ' + email,
          date: new Date()
        };
        this.firestore.collection('StaffLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('StaffLog').doc(dateFormat).collection('Staff').add(info);
      });
    })
  }

  async update_Station(_staff: Machine, email: string) {
    var updates = {};
    
    updates['/' + _staff.StationID + '/name'] = _staff.StationName;
    updates['/' + _staff.StationID + '/Email'] = _staff.Email;
    updates['/' + _staff.StationID + '/machineAssigned'] = _staff.StationNo;

    for (const acc of _staff.Process) {
      updates['/' + _staff.StationID + '/Process/' + acc] = "All"
    }
    
    await this.db.database.ref('Operators/'+_staff.StationID).set(null).then(async e=>{
      await this.db.database.ref('Operators').update(updates).then(e => {
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: _staff.StationName + ' has been updated by the ' + email,
          date: new Date()
        };
        this.firestore.collection('OperatorsLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('OperatorsLog').doc(dateFormat).collection('Operators').add(info);
      });
    })
  }

  async searchStaffbyEmail(email: any): Promise<Staff> {
    const staff = new Staff();
    var snapshot = await this.db.database.ref('User').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('Email').val() == email){
          staff.StaffID = childSnapshot.key;
          staff.StaffName = childSnapshot.child('StaffName').val();
          staff.Card_ID = childSnapshot.child('CardID').val();
          staff.StaffNo = childSnapshot.child('StaffNo').val();
          staff.Email = childSnapshot.child('Email').val();
          staff.Role = childSnapshot.child('Role').val();
          staff.UpdatedBy = childSnapshot.child('UpdatedBy').val();
          staff.CreatedBy = childSnapshot.child('CreatedBy').val();
          staff.CreatedDate = new Date(childSnapshot.child('CreatedDate').val());
          staff.UpdatedDate = new Date(childSnapshot.child('UpdatedDate').val());
        }
      });
    }

    return staff;
  }

  delete_Staff(id: string) {
    this.db.database.ref('/User/' + id).set(null);
  }

  async getPODetails(pt: ScheduleTracker): Promise<{ bf: number; input: number; output: number; cf: number }> {
    let childSnapshot = await this.db.database.ref('Purchase Order/' + pt.PO_No).once('value');
    let bf = 0,
      input = 0,
      output = 0,
      cf = 0;
  
    if (childSnapshot.exists()) {
      childSnapshot.child('Part List').forEach(childSnapshot2 => {
        if (
          childSnapshot2.child('Status').val() == 'Drawing Review' ||
          childSnapshot2.child('Status').val() == 'Pending Customer Drawing'
        ) {
          bf = 0;
          input += 1;
        } else {
          output += 1;
        }
  
        cf = input - output;
        pt.bf = bf;
        pt.new = input;
        pt.complete = output;
        pt.left = cf;
      });
    }
  
    return { bf, input, output, cf };
  }

  async getJOInfo(pt: MachineSchedule): Promise<void> {
    var snapshot = await this.db.database.ref('Purchase Order/' + pt.PO_No + '/Part List/' + pt.JO_No).once('value');
    
    if (snapshot.exists()) {
      pt.Status = snapshot.child('Status').val();
      pt.Quantity = snapshot.child('PO Quantity').val();
      pt.Reason = snapshot.child('Reason').val();
    }
  }
  
  async getStaffInfo(pt: MachineSchedule): Promise<void> {
    var snapshot = await this.db.database.ref('User').child(pt.HandledById).once('value');
    
    if (snapshot.exists()) {
      pt.HandledBy = snapshot.child('StaffName').val();
    }
  }

  async getStaffDetails(pt: ScheduleTracker): Promise<void> {
    var snapshot = await this.db.database.ref('User').child(pt.StaffID).once('value');
    
    if (snapshot.exists()) {
      pt.StaffName = snapshot.child('StaffName').val();
      pt.StaffNo = snapshot.child('StaffNo').val();
    }
  }

  /*async getPODetails(pt: ScheduleTracker): Promise<void> {
    let childSnapshot = await this.db.database.ref('Purchase Order/' + pt.PO_No).once('value');
    let bf=0, input=0, output=0, cf=0;

    if (childSnapshot.exists()) {
      childSnapshot.child("Part List").forEach(childSnapshot2 => {
        if(childSnapshot2.child("Status").val() == "Drawing Review" || childSnapshot2.child("Status").val() == "Pending Customer Drawing"){
          bf = 0;
          input += 1;
        }
        else{
          output += 1;
        }

        cf = input - output;
        pt.bf = bf;
        pt.new = input;
        pt.complete = output;
        pt.left = cf;
      });
    }
  }*/
}
