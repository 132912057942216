import { Machine } from './../Object_Classes/Machine/Machine';
import { TakeFromStock } from './../Object_Classes/PackingList/PackingList';
import { BoxShippingInfo, CheckOut, RawCheckOut, ScheduleInfo } from './../Object_Classes/PurchaseOrder/PurchaseOrder';
import { AngularFireDatabase } from '@angular/fire/database';
import { PurchaseOrder, PartTracker } from '../Object_Classes/PurchaseOrder/PurchaseOrder';
import { RawMaterialInfo } from '../Object_Classes/RawMaterial/RawMaterial';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { v4 as uuidv4 } from 'uuid';
import { POINT_CONVERSION_COMPRESSED } from 'constants';

export class PODB_controller {
  constructor(private db: AngularFireDatabase) {
  }

  private firestore: AngularFirestore;
  private dateFormat = new DateFormatService();
  /************** Purchase Order ****************/
  async getPOList(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    var snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        let PO_item = new PurchaseOrder();
        PO_item.PO_No = childSnapshot.key;
        PO_item.Customer = childSnapshot.child('_Customer').val();
        PO_item.Customer_PO = childSnapshot.child('_Customer_PO').val();
        PO_item.Person_In_Charge = childSnapshot.child('_PIC').val();
        PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        PO_item.Created_By = childSnapshot.child('_Created_By').val();
        PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
        PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
        PO_item.Status = childSnapshot.child('_Status').val();

        var part_trackList: PartTracker[] = [];
        childSnapshot.child("Part List").forEach((childSnapshot2) => {
          let part_track = new PartTracker;
          var boxList: BoxShippingInfo[] = [];
          var scheduleList: ScheduleInfo[] = [];
          var checkout: CheckOut[] = [];
          childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
            let box = new BoxShippingInfo;
            box.Box_No = childSnapshot3.key;
            box.Box_Status = childSnapshot3.child('Box Status').val();
            box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
            box.Status = childSnapshot3.child('Status').val();
            box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
            box.Updated_Date = new Date(childSnapshot3.child('Date').val());
            boxList.push(box);
          });

          childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
            let out = new CheckOut;
            var raw: RawCheckOut[] = [];
            childSnapshot3.forEach(childSnapshot4 => {
              let r = new RawCheckOut;
              out.Raw_PO = childSnapshot4.key;
              r.Raw_ID = childSnapshot4.child('RawMaterialID').val();
              r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
              r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
              raw.push(r);
            })
            out.RawCheckOutList = raw;
            checkout.push(out);
          });

          childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
            let schedule = new ScheduleInfo;

            schedule.Timestamp = childSnapshot3.key;
            schedule.Process = childSnapshot3.child("Process").val();
            schedule.Type = childSnapshot3.child("Type").val();
            schedule.Supplier = childSnapshot3.child("Supplier").val();
            schedule.By = childSnapshot3.child("Created_By").val();
            scheduleList.push(schedule);
          });

          scheduleList.sort((a, b)=>{
            return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
          });

          for(var i=0; i<scheduleList.length-1; i++){
            scheduleList[i].TubingDifferent = new Date(scheduleList[i].Timestamp).getTime() - new Date(scheduleList[i+1].Timestamp).getTime();
            scheduleList[i].TubingDifferent = scheduleList[i].TubingDifferent / 3600000;
          }

          part_track.Schedule = scheduleList;
          part_track.ID = childSnapshot2.key;
          part_track.CheckOut_RawList = checkout;
          part_track.PO_Part_ID = childSnapshot2.child("Part No").val();
          part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
          part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
          part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
          part_track.PO_Status = childSnapshot2.child("Status").val();
          part_track.JOStatus = childSnapshot2.child('Status').val();
          part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
          part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
          part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
          part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
          part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
          part_track.Reference = childSnapshot2.child("Reference").val();
          part_track.POS = childSnapshot2.child("POS").val();
          part_track.PIC = childSnapshot2.child("PIC").val();
          part_track.UOM = childSnapshot2.child("Part Uom").val();
          part_track.MachineNo = childSnapshot2.child("MachineNo").val() || "";
          part_track.MachineNo = part_track.MachineNo.replace('@@','');
          part_track.MachineNo = part_track.MachineNo.replace('_1','');
          part_track.MachineNo = part_track.MachineNo.replace('_2','');
          part_track.subFrom = childSnapshot2.child("SubFrom").val();
          part_track.BoxInfo_List = boxList;
          part_track.Date_Diff = Math.ceil((part_track.EndDate.getTime() - part_track.StartDate.getTime()) / (24 * 60 * 60 * 1000)).toString();
          this.getPartInfo(part_track);
          part_trackList.push(part_track);

        });

        for (let i = 0; i < part_trackList.length; i++) {
          if(part_trackList[i].subFrom){
            const result = part_trackList.find(e=> e.ID === part_trackList[i].subFrom);
            if(result){
              result.subPart.push(part_trackList[i]);
              part_trackList.splice(i,1)
              i = i-1;
            }
          }

        }

        PO_item.PO_Part_List = part_trackList;
        PO_list.push(PO_item);
      });
    }

    return PO_list;
  }

  async getPOListForPackingList(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    var snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        let PO_item = new PurchaseOrder();
        PO_item.PO_No = childSnapshot.key;
        PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        PO_item.Created_By = childSnapshot.child('_Created_By').val();
        PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
        PO_item.Updated_By = childSnapshot.child('_Updated_By').val();

        var part_trackList: PartTracker[] = [];
        childSnapshot.child("Part List").forEach((childSnapshot2) => {
          let part_track = new PartTracker;
          var boxList: BoxShippingInfo[] = [];
          var checkout: CheckOut[] = [];
          childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
            let box = new BoxShippingInfo;
            box.Box_No = childSnapshot3.key;
            box.Box_Status = childSnapshot3.child('Box Status').val();
            box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
            box.Status = childSnapshot3.child('Status').val();
            box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
            box.Updated_Date = new Date(childSnapshot3.child('Date').val());
            boxList.push(box);
          });
          childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
            let out = new CheckOut;
            var raw: RawCheckOut[] = [];
            childSnapshot3.forEach(childSnapshot4 => {
              let r = new RawCheckOut;
              out.Raw_PO = childSnapshot4.key;
              r.Raw_ID = childSnapshot4.child('RawMaterialID').val();
              r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
              r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
              raw.push(r);
            })
            out.RawCheckOutList = raw;
            checkout.push(out);
          });
          part_track.ID = childSnapshot2.key;
          part_track.CheckOut_RawList = checkout;
          part_track.PO_Part_ID = childSnapshot2.child("Part No").val();
          part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
          part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
          part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
          part_track.PO_Status = childSnapshot2.child("Status").val();
          part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
          part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
          part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
          part_track.Reference = childSnapshot2.child("Reference").val();
          part_track.POS = childSnapshot2.child("POS").val();
          part_track.PIC = childSnapshot2.child("PIC").val();
          part_track.MachineNo = childSnapshot2.child("MachineNo").val() || "";
          part_track.MachineNo = part_track.MachineNo.replace('@@','');
          part_track.MachineNo = part_track.MachineNo.replace('_1','');
          part_track.MachineNo = part_track.MachineNo.replace('_2','');
          part_track.subFrom = childSnapshot2.child("SubFrom").val();
          part_track.BoxInfo_List = boxList;

          this.getPartInfo(part_track);
          part_trackList.push(part_track);
        });

        PO_item.PO_Part_List = part_trackList;
        PO_list.push(PO_item);
      });
    }
    return PO_list;
  }

  async addPO(_newPO: any, email) {
    for (const data of _newPO.parts) {
      let updates = {};
      if (data.partNumber2) {
        const info = {
          'Accumulate Quantity': 0,
          'Expected Quantity': data.quantity,
          'PO Quantity': data.poquantity2,
          'Code': '-',
          'PO No': _newPO.POName,
          'Part No': data.partNumber2,
          'Part Name': data.partName2,
          'Schedule Status': 'Waiting',
          'startAt': data.startTime.toISOString(),
          'endAt': data.endTime.toISOString(),
          'presetStart': '-',
          'presetEnd': '-',
          'downStart': 0,
          'downEnd': 0,
          'Total Down Time': 0,
          'Availability': 0,
          'Performance': 0,
          'Effectiveness': 0,
        }
        await this.db.database.ref('Machine/@@' + data.machineChosen + '_1' + '/Schedule/' + data.scheduleID2).set(info)

        updates['LinkedPart2'] = data.partNumber2;
        updates['LinkedPart2ScheduleID'] = data.scheduleID2;
      }
      if (data.partNumber3) {
        const info = {
          'Accumulate Quantity': 0,
          'Expected Quantity': data.quantity,
          'PO Quantity': data.poquantity3,
          'Code': '-',
          'PO No': _newPO.POName,
          'Part No': data.partNumber3,
          'Part Name': data.partName3,
          'Schedule Status': 'Waiting',
          'startAt': data.startTime.toISOString(),
          'endAt': data.endTime.toISOString(),
          'presetStart': '-',
          'presetEnd': '-',
          'downStart': 0,
          'downEnd': 0,
          'Total Down Time': 0,
          'Availability': 0,
          'Performance': 0,
          'Effectiveness': 0,
        }
        await this.db.database.ref('Machine/@@' + data.machineChosen + '_2' + '/Schedule/' + data.scheduleID3).set(info)
        updates['LinkedPart3'] = data.partNumber3;
        updates['LinkedPart3ScheduleID'] = data.scheduleID3;
      }
      updates['Accumulate Quantity'] = 0;
      updates['Expected Quantity'] = data.quantity;
      updates['PO Quantity'] = data.poquantity;
      updates['Code'] = '-';
      updates['PO No'] = _newPO.POName;
      updates['Part No'] = data.partNumber;
      updates['Schedule Status'] = 'Waiting';
      updates['startAt'] = data.startTime.toISOString();
      updates['endAt'] = data.endTime.toISOString();
      updates['presetStart'] = '-';
      updates['presetEnd'] = '-';
      updates['downStart'] = 0;
      updates['downEnd'] = 0;
      updates['Total Down Time'] = 0;
      updates['Availability'] = 0;
      updates['Performance'] = 0;
      updates['Effectiveness'] = 0;
      await this.db.database.ref('Machine/' + data.machineChosen + '/Schedule/' + data.scheduleID).set(updates, async () => {

        for (const element of data.raw.Raw_Material) {
          if (element.check) {
            this.db.database.ref('RawMaterial/' + element.Material_ID).once('value').then(datasnap => {
              let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());

              quantity += parseFloat(element.quantityNeeded);
              this.db.database.ref('RawMaterial/' + element.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
            })
            const info = {}
            info['ID'] = element.Material_ID;
            info['Name'] = element.Material_Name;
            info['Quantity Needed'] = element.quantityNeeded;
            info['Type'] = element.Raw_Type;
            await this.db.database.ref('Machine/' + data.machineChosen + '/Schedule/' + data.scheduleID + '/RawMaterials/' + element.Material_ID).set(info)
          }
        }
      })
    }
    let newPO = {};

    newPO['_Created_Date'] = new Date().toISOString();
    newPO['_Updated_Date'] = new Date().toISOString();
    newPO['_Created_By'] = email;
    newPO['_Updated_By'] = email;
    await this.db.database.ref('Purchase Order/' + _newPO.POName).set(newPO);

    for (const data of _newPO.parts) {
      if (data.partNumber2) {
        const info = {}
        info['Part No'] = data.partNumber2;
        info['Part Name'] = data.partName2;
        info['Part Quantity'] = data.quantity;
        info['PO Quantity'] = data.poquantity2;
        info['Status'] = "Waiting";
        info['_Completed_Date'] = "";
        info['Added to packing'] = false;
        info['Sub'] = true;
        info['SubFrom'] = data.scheduleID;
        info['MachineNo'] = '@@' + data.machineChosen + '_1';
        info['POS'] = data.pos2;
        info['Reference'] = data.reference2;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID2).set(info);

      }
      if (data.partNumber3) {
        const info = {}
        info['Part No'] = data.partNumber3;
        info['Part Name'] = data.partName3;
        info['Part Quantity'] = data.quantity;
        info['PO Quantity'] = data.poquantity3;
        info['Status'] = "Waiting";
        info['_Completed_Date'] = "";
        info['Added to packing'] = false;
        info['Sub'] = true;
        info['SubFrom'] = data.scheduleID;
        info['MachineNo'] = '@@' + data.machineChosen + '_2';
        info['POS'] = data.pos3;
        info['Reference'] = data.reference3;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID3).set(info);

      }
      const info = {}
      info['Part No'] = data.partNumber;
      info['Part Name'] = data.partName;
      info['Part Quantity'] = data.quantity;
      info['PO Quantity'] = data.poquantity;
      info['Status'] = "Waiting";
      info['_Completed_Date'] = "";
      info['Added to packing'] = false;
      info['MachineNo'] = data.machineChosen;
      info['POS'] = data.pos;
      info['Reference'] = data.reference;
      await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID).set(info);

      for (const element of data.raw.Raw_Material) {
        if (element.check) {
          const inf = {}
          inf['ID'] = element.Material_ID;
          inf['Name'] = element.Material_Name;
          inf['Quantity Needed'] = element.quantityNeeded;
          inf['Type'] = element.Raw_Type;
          await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/RawMaterials/' + element.Material_ID).set(inf);
        }
      }

      if (data.raw.innerCheck) {
        const innerBox = {}
        innerBox['ID'] = data.raw.InnerBox.Material_ID;
        innerBox['Name'] = data.raw.InnerBox.Material_Name;
        innerBox['Type'] = data.raw.InnerBox.Raw_Type;
        innerBox['Quantity Needed'] = data.raw.innerBoxNeeded;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/InnerBox').set(innerBox);

        this.db.database.ref('RawMaterial/' + data.raw.InnerBox.Material_ID).once('value').then(datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity += parseFloat(data.raw.innerBoxNeeded);
          this.db.database.ref('RawMaterial/' + data.raw.InnerBox.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }
      if (data.raw.polyCheck) {
        const polybox = {}
        polybox['ID'] = data.raw.PolyBag.Material_ID;
        polybox['Name'] = data.raw.PolyBag.Material_Name;
        polybox['Type'] = data.raw.PolyBag.Raw_Type;
        polybox['Quantity Needed'] = data.raw.polyBagNeeded;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/PolyBag').set(polybox);
        this.db.database.ref('RawMaterial/' + data.raw.PolyBag.Material_ID).once('value').then(datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity += parseFloat(data.raw.polyBagNeeded);
          this.db.database.ref('RawMaterial/' + data.raw.PolyBag.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }
      const cartonbox = {}

      cartonbox['ID'] = data.raw.CartonBox.Material_ID;
      cartonbox['Name'] = data.raw.CartonBox.Material_Name;
      cartonbox['Type'] = data.raw.CartonBox.Raw_Type;
      cartonbox['Quantity Needed'] = data.raw.cartonNeeded;
      cartonbox['Carton Weight'] = data.raw.CartonBox.Carton_Weight;
      await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/Carton').set(cartonbox);

      await this.db.database.ref('RawMaterial/' + data.raw.CartonBox.Material_ID).once('value').then(async datasnap => {
        let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
        quantity += parseFloat(data.raw.cartonNeeded);
        await this.db.database.ref('RawMaterial/' + data.raw.CartonBox.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
      })
    }
  }

  async addPOWithoutSchedule(_newPO: any, email) {
    let updates1 = {};
    let updates2 = {};

    _newPO.parts.forEach(async (data, index) => {
      //var partNo = uuidv4();
      var current = new Date().getTime().toString();

      if(index < 9){
        var JO = _newPO.POName + '-0' + (parseInt(index)+1).toString();
      }
      else{
        var JO = _newPO.POName + '-' + (parseInt(index)+1).toString();
      }
      
      updates1[_newPO.POName + '/Part List/' + JO + '/Part No'] = data.id;
      updates1[_newPO.POName + '/Part List/' + JO + '/Part Number'] = data.partNumber;
      updates1[_newPO.POName + '/Part List/' + JO + '/Part Name'] = data.partName;
      updates1[_newPO.POName + '/Part List/' + JO + '/Part Quantity'] = 0;
      updates1[_newPO.POName + '/Part List/' + JO + '/Part Uom'] = data.uom || "";
      updates1[_newPO.POName + '/Part List/' + JO + '/PO Quantity'] = data.poquantity || 0;
      updates1[_newPO.POName + '/Part List/' + JO + '/Status'] = "Drawing Review";
      //updates1[_newPO.POName + '/Part List/' + JO + '/Status'] = "Planned Material";
      updates1[_newPO.POName + '/Part List/' + JO + '/Start Date'] = data.startDate;
      updates1[_newPO.POName + '/Part List/' + JO + '/End Date'] = data.endDate;
      updates1[_newPO.POName + '/Part List/' + JO + '/Assign To'] = _newPO.assignTo || "-";
      //updates1[_newPO.POName + '/Part List/' + JO + '/Schedule/' + current + '/Process'] = "Planned Material";
      updates1[_newPO.POName + '/Part List/' + JO + '/Schedule/' + current + '/Process'] = "Drawing Review";
      updates1[_newPO.POName + '/Part List/' + JO + '/Schedule/' + current + '/Created_By'] = email;

      if(_newPO.assignTo != null && _newPO.assignTo != ""){
        var scheduleId = uuidv4();
        updates2['Schedule/'+scheduleId+'/_SO_No'] = _newPO.POName;
        updates2['Schedule/'+scheduleId+'/_JO_No'] = JO;
        updates2['Schedule/'+scheduleId+'/_Status'] = "Pending";

        let updates3 = {};
        const todayDate = this.getTodayDate();
        this.db.database.ref('Schedule/' + todayDate + '/' + _newPO.assignTo).once('value').then(async (childSnapshot) => {
          if (childSnapshot.exists()) {
            let quantity = parseInt(childSnapshot.child('new').val());
            let quantity2 = parseInt(childSnapshot.child('cf').val());
            updates3[todayDate + '/' + _newPO.assignTo + '/new'] = quantity + 1;
            updates3[todayDate + '/' + _newPO.assignTo + '/cf'] = quantity2 + 1;
            await this.db.database.ref('Schedule/').update(updates3);
          } 
          else {
            updates3[todayDate + '/' + _newPO.assignTo + '/new'] = 1;
            updates3[todayDate + '/' + _newPO.assignTo + '/bf'] = 0;
            updates3[todayDate + '/' + _newPO.assignTo + '/cf'] = 1;
            updates3[todayDate + '/' + _newPO.assignTo + '/output'] = 0;
            await this.db.database.ref('Schedule/').update(updates3);
          }
          
          console.log('All operations completed');
        }).catch((error) => {
          console.error('Error:', error);
        });


        /*let childSnapshot = await this.db.database.ref('Schedule/'+todayDate+'/'+_newPO.assignTo).once('value');

        if (childSnapshot.exists()) {
          let quantity = parseInt(childSnapshot.child('new').val());
          let quantity2 = parseInt(childSnapshot.child('cf').val());
          updates3[todayDate+'/'+_newPO.assignTo+'/new'] = (quantity + 1);
          updates3[todayDate+'/'+_newPO.assignTo+'/cf'] = (quantity2 + 1);
          await this.db.database.ref('Schedule/').update(updates3);
        }
        else{
          updates3[todayDate+'/'+_newPO.assignTo+'/new'] = 1;
          updates3[todayDate+'/'+_newPO.assignTo+'/bf'] = 0;
          updates3[todayDate+'/'+_newPO.assignTo+'/cf'] = 1;
          updates3[todayDate+'/'+_newPO.assignTo+'/output'] = 0;
          await this.db.database.ref('Schedule/').update(updates3);
        }*/
      }

      await this.db.database.ref('Part/'+data.id).set(null).then(async r => {
        const partinfo = { };
  
        partinfo['Stock Quantity'] = 0;
        partinfo['Part Name'] = data.partName || '-';
        partinfo['Part No'] = data.partNumber || '-';
  
        await this.db.database.ref('Part/' + data.id).set(partinfo);
  
        /*const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          date: new Date(),
          content:JSON.stringify(data),
        };
        await this.firestore.collection('PartLog').doc(dateFormat).set({ Date: new Date() });
        await this.firestore.collection('PartLog').doc(dateFormat).collection('Part').add(info);*/
      });
    });

    updates1[_newPO.POName + '/_Customer_PO'] = _newPO.poNumber || "-";
    updates1[_newPO.POName + '/_Customer'] = _newPO.CusName;
    updates1[_newPO.POName + '/_Assign_To'] = _newPO.assignTo || "-";
    updates1[_newPO.POName + '/_PIC'] = _newPO.staffName || "";
    updates1[_newPO.POName + '/_Created_Date'] = _newPO.createdDate;
    updates1[_newPO.POName + '/_Updated_Date'] = new Date();
    updates1[_newPO.POName + '/_Created_By'] = email;
    updates1[_newPO.POName + '/_Updated_By'] = email;
    updates1[_newPO.POName + '/_Status'] = "Incomplete";

    await this.db.database.ref('Purchase Order/').update(updates1);

    if(_newPO.assignTo != null && _newPO.assignTo != ""){
      await this.db.database.ref('User/'+_newPO.assignTo).update(updates2);
    }
  }

  async updateJOWithoutSchedule(_newPO: any, email) {
    let updates1 = {};

    updates1[_newPO.PONo + '/Part List/' + _newPO.JONo + '/Status'] = _newPO.JOStatus;
    this.db.database.ref('Purchase Order/').update(updates1);

    _newPO.rawMaterials.forEach(data => {
      let newStock = parseFloat(data.rawMatStock) - parseFloat(data.rawMatNeeded);
      this.db.database.ref('RawMaterial/' + data.rawMatId + '/_In_Stock').set(newStock.toFixed(2));
    });
  }

  async search_PO(PO_No: string): Promise<PurchaseOrder> {
    let searched_PO = new PurchaseOrder;
    let childSnapshot = await this.db.database.ref('Purchase Order/' + PO_No).once('value');

    if (childSnapshot.exists()) {
      let get_PO_No = childSnapshot.key;
      if (PO_No.match(get_PO_No)) {
        searched_PO.PO_No = childSnapshot.key;
        let part_trackList: PartTracker[] = [];
        
        childSnapshot.child("Part List").forEach(childSnapshot2 => {
          let part_track = new PartTracker;
          var checkout: CheckOut[] = [];

          childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
            let out = new CheckOut;
            var raw: RawCheckOut[] = [];
            out.Raw_PO = childSnapshot3.key;
            childSnapshot3.forEach(childSnapshot4 => {
              let r = new RawCheckOut;
              r.Raw_ID = childSnapshot4.key
              r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
              r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
              raw.push(r);
            })
            out.RawCheckOutList = raw;
            checkout.push(out);
          });

          part_track.ID = childSnapshot2.key;
          part_track.CheckOut_RawList = checkout;
          part_track.PO_Part_ID = childSnapshot2.child("Part No").val();
          part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
          part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
          part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
          part_track.PO_Status = childSnapshot2.child("Status").val();
          part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
          part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
          part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
          part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
          part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
          part_track.UOM = childSnapshot2.child("Part Uom").val();
          this.getPartInfo(part_track);
          part_trackList.push(part_track);
        });
        
        searched_PO.PO_Part_List = part_trackList;
      }
    }

    return searched_PO;
  }

  async search_PO_By_Id(PO_No: string): Promise<PurchaseOrder> {
    var childSnapshot = await this.db.database.ref('Purchase Order').child(PO_No).once('value');
    let PO_item = new PurchaseOrder();

    if (childSnapshot.exists()) {
      PO_item.PO_No = childSnapshot.key;
      PO_item.Customer = childSnapshot.child('_Customer').val();
      PO_item.Customer_PO = childSnapshot.child('_Customer_PO').val();
      PO_item.Person_In_Charge = childSnapshot.child('_PIC').val();
      PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
      PO_item.Created_By = childSnapshot.child('_Created_By').val();
      PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
      PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
      PO_item.Status = childSnapshot.child('_Status').val();
    }

    return PO_item;
  }

  async search_Partial_PO(PO_No: string): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let searched_PO = new PurchaseOrder;
        let get_PO_No = childSnapshot.key;
        if (get_PO_No.includes(PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          let part_trackList: PartTracker[] = [];
          childSnapshot.child("Part List").forEach((function (childSnapshot2) {
            let part_track = new PartTracker;
            var boxList: BoxShippingInfo[] = [];
            var checkout: CheckOut[] = [];
            childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
              let box = new BoxShippingInfo;
              box.Box_No = childSnapshot3.key;
              box.Box_Status = childSnapshot3.child('Box Status').val();
              box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
              box.Status = childSnapshot3.child('Status').val();
              box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
              box.Updated_Date = new Date(childSnapshot3.child('Date').val());
              boxList.push(box);
            });
            childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
              let out = new CheckOut;
              var raw: RawCheckOut[] = [];
              out.Raw_PO = childSnapshot3.key;
              childSnapshot3.forEach(childSnapshot4 => {
                let r = new RawCheckOut;
                r.Raw_ID = childSnapshot4.key
                r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
                r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
                raw.push(r);
              })
              out.RawCheckOutList = raw;
              checkout.push(out);
            });
            part_track.CheckOut_RawList = checkout;
            part_track.PO_Part_ID = childSnapshot2.child("Part No").val();
            part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
            part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
            part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
            part_track.PO_Status = childSnapshot2.child("Status").val();
            part_track.UOM = childSnapshot2.child("Part Uom").val();
            part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
            part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
            part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
            part_track.BoxInfo_List = boxList;
            part_trackList.push(part_track);
          }));
          searched_PO.PO_Part_List = part_trackList;
          PO_list.push(searched_PO);
        }
      }));
    }
    return PO_list;
  }

  async search_PO_withStatusDrawingReviewSearch(PO_No: string): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order/' + PO_No).once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          let searched_PO = new PurchaseOrder;

          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          searched_PO.Longest_Date = new Date('2100-01-01 00:00:00 UTC+00');
          searched_PO.Customer = childSnapshot.child('_Customer').val();
          searched_PO.Customer_PO = childSnapshot.child('_Customer_PO').val();
          searched_PO.Person_In_Charge = childSnapshot.child('_PIC').val();

          let part_trackList: PartTracker[] = [];
          var allCompleted = true;
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() == "Drawing Review"){
              let part_track = new PartTracker;
              part_track.ID = childSnapshot2.key;
              part_track.SO_No = childSnapshot.key;
              part_track.Customer_Name = childSnapshot.child('_Customer').val();
              part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.UOM = childSnapshot2.child("Part Uom").val();
              part_track.JOStatus = childSnapshot2.child('Status').val();
              part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
              part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
              part_track.AssignTo = childSnapshot2.child('Assign To').val();
              this.getStaffInfo(part_track);
              var scheduleList: ScheduleInfo[] = [];

              if(childSnapshot2.child('Status').val() != 'Complete'){
                allCompleted = false;
                if(part_track.EndDate.getTime() <= searched_PO.Longest_Date.getTime()){
                  searched_PO.Longest_Date = part_track.EndDate;
                }
              }

              const rawMaterialID = [];
              childSnapshot2.child('Raw Material').forEach(childSnapshot3 => {
                const info = {
                  ID: childSnapshot3.child('ID').val(),
                  Name: childSnapshot3.child('Name').val(),
                  Material: childSnapshot3.child('Material').val(),
                  Raw_Type: childSnapshot3.child('Type').val(),
                  UOM: childSnapshot3.child('UOM').val(),
                  Amount: childSnapshot3.child('Amount').val()
                }
      
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                    raw.Material_ID = snap.key;
                    raw.Material_Name = snap.child('_Material_Name').val();
                    raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                    raw.In_Stock = snap.child('_In_Stock').val();
                    raw.Unit = info.UOM;
                    //raw.Unit = snap.child('_Unit').val();
                    raw.Unit_Price = snap.child('_Unit_Price').val(); 
                    raw.Updated_By = snap.child('_Updated_By').val();
                    raw.Created_By = snap.child('_Created_By').val();
                    raw.Created_Date = new Date(snap.child('_Created_Date').val());
                    raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                    raw.Part_Material_Name = info.Name;
                    raw.Raw_Amount = info.Amount;
                    raw.Raw_Type = info.Raw_Type;
                    rawMaterialID.push(raw);
                  }
                });
              });

              childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
                let schedule = new ScheduleInfo;
    
                schedule.Timestamp = childSnapshot3.key;
                schedule.Process = childSnapshot3.child("Process").val();
                schedule.Type = childSnapshot3.child("Type").val();
                schedule.Supplier = childSnapshot3.child("Supplier").val();
                schedule.By = childSnapshot3.child("Created_By").val();
                scheduleList.push(schedule);
              });

              scheduleList.sort((a, b)=>{
                return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
              });
    
              for(var i=0; i<scheduleList.length-1; i++){
                scheduleList[i].TubingDifferent = new Date(scheduleList[i].Timestamp).getTime() - new Date(scheduleList[i+1].Timestamp).getTime();
                scheduleList[i].TubingDifferent = scheduleList[i].TubingDifferent / 3600000;
              }
    
              part_track.Schedule = scheduleList;
              
              part_track.Raw_Material = rawMaterialID;
              this.getPartInfo(part_track);
              part_trackList.push(part_track);
              }
          });

          searched_PO.PO_Part_List = part_trackList;

          if (searched_PO.PO_Part_List.length > 0){
            if(searched_PO.Status == "Incomplete" && allCompleted){
              this.db.database.ref('Purchase Order/' + searched_PO.PO_No + '/_Status').set("Complete");
            }
            else{
              PO_list.push(searched_PO);
            }
          }
        }
      });
    }

    return PO_list;
  }

  /*delete_PO(PO_No: string, machinelist) {
    const de = [];
    this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value').then(async snapshot => {
      snapshot.forEach(deleteSchedule => {
        if (deleteSchedule.child('RawMaterials').val()) {
          deleteSchedule.child('RawMaterials').forEach(e => {
            const info = {
              ID: e.child('ID').val(),
              value: parseFloat(e.child('Quantity Needed').val())
            }
            if (de.length > 0) {
              const result = de.findIndex(e => e.ID === info.ID);
              if (result !== -1) {
                de[result].value += parseFloat(e.child('Quantity Needed').val());
              } else {
                de.push(info);
              }
            } else {
              de.push(info);
            }
          })
        }
        if (deleteSchedule.child('Box').val()) {
          deleteSchedule.child('Box').forEach(e => {
            const info = {
              ID: e.child('ID').val(),
              value: parseFloat(e.child('Quantity Needed').val())
            }
            if (de.length > 0) {
              const result = de.findIndex(e => e.ID === info.ID);
              if (result !== -1) {
                de[result].value += parseFloat(e.child('Quantity Needed').val());
              } else {
                de.push(info);
              }
            } else {
              de.push(info);
            }
          })
        }

        machinelist.forEach(element => {
          this.db.database.ref('/Machine/' + element + '/Schedule/' + deleteSchedule.key).set(null);
        });
      })
    }).finally(() => {
      this.db.database.ref('/Purchase Order/' + PO_No).set(null);
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }

    })
  }*/

  delete_PO(PO_No: string) {
    const de = [];
    this.db.database.ref('/Purchase Order/' + PO_No).set(null);
  }

  //Temporary
  async delete_Schedule(PO_No, machine, id, schedule) {
    const de = [];

    if(schedule.LinkedPart2ScheduleID){
      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + schedule.LinkedPart2ScheduleID).set(null).then(async result => {
        await this.db.database.ref('/Machine/@@' + machine + '_1/Schedule/' + schedule.LinkedPart2ScheduleID).set(null);
      });
    }
    if(schedule.LinkedPart3ScheduleID){
      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + schedule.LinkedPart3ScheduleID).set(null).then(async result => {
        await this.db.database.ref('/Machine/@@' + machine + '_2/Schedule/' + schedule.LinkedPart3ScheduleID).set(null);
      });
    }

    await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).once('value').then(async snapshot => {
      if (snapshot.child('RawMaterials').val()) {
        snapshot.child('RawMaterials').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
      if (snapshot.child('Box').val()) {
        snapshot.child('Box').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
    }).finally(() => {
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }

      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).set(null).then(async result => {
        await this.db.database.ref('/Machine/' + machine + '/Schedule/' + id).set(null);
        const snapshot = await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value')
        if (!snapshot.exists()) {
          await this.db.database.ref('/Purchase Order/' + PO_No).set(null)
        }
      });
    })


  }

  async delete_ScheduleFromPO(PO_No, id) {
    this.db.database.ref('Purchase Order/' + PO_No + '/Part List/' + id).set(null);


    /*await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).once('value').then(async snapshot => {
      if (snapshot.child('RawMaterials').val()) {
        snapshot.child('RawMaterials').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
      if (snapshot.child('Box').val()) {
        snapshot.child('Box').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
    }).finally(() => {
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }




      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).set(null).then(async result => {
        await this.db.database.ref('/Machine/' + machine + '/Schedule/' + id).set(null);
        const snapshot = await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value')
        if (!snapshot.exists()) {
          await this.db.database.ref('/Purchase Order/' + PO_No).set(null)
        }
      });
    })*/


  }

  async search_PO_withStatusCompleted(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrder;
        searched_PO.PO_No = childSnapshot.key;
        searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        let part_trackList: PartTracker[] = [];
        childSnapshot.child('Part List').forEach(childSnapshot2 => {
          let part_track = new PartTracker;
          if (childSnapshot2.child('Added to packing').val() === true) {
            var scheduleList: ScheduleInfo[] = [];
            part_track.PO_Part_No = childSnapshot2.child('Part No').val();
            part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
            part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
            part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
            // part_track.Completed_Date = new Date(childSnapshot2.child('_Completed_Date').val());
            childSnapshot2.child("Take From Stocks").forEach(stock => {
              const sto = new TakeFromStock();
              sto.BoxNumber = stock.key;
              sto.DeductedQuantity = stock.val();
              part_track.TakeFromStocks.push(sto);
            });

            childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
              let schedule = new ScheduleInfo;
  
              schedule.Timestamp = childSnapshot3.key;
              schedule.Process = childSnapshot3.child("Process").val();
              schedule.Type = childSnapshot3.child("Type").val();
              schedule.Supplier = childSnapshot3.child("Supplier").val();
              schedule.By = childSnapshot3.child("Created_By").val();
              scheduleList.push(schedule);
            });

            scheduleList.sort((a, b)=>{
              return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
            });
  
            for(var i=0; i<scheduleList.length-1; i++){
              scheduleList[i].TubingDifferent = new Date(scheduleList[i].Timestamp).getTime() - new Date(scheduleList[i+1].Timestamp).getTime();
              scheduleList[i].TubingDifferent = scheduleList[i].TubingDifferent / 3600000;
            }
  
            part_track.Schedule = scheduleList;

            part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
            part_track.CartonBoxNeeded = childSnapshot2.child("Box/Carton/Quantity Needed").val();
            if(childSnapshot2.child('Sub').val()){
              part_track.Carton_Weight = childSnapshot.child("Part List/"+childSnapshot2.child('SubFrom').val()+"/Box/Carton/Carton Weight").val();
            }else{
              part_track.Carton_Weight = childSnapshot2.child("Box/Carton/Carton Weight").val();
            }
            part_track.Reference = childSnapshot2.child("Reference").val();
            part_track.POS = childSnapshot2.child("POS").val();
            this.getPartInfo(part_track);
            this.getInvoice(searched_PO.PO_No, part_track);
            part_trackList.push(part_track);
          }
        });
        searched_PO.PO_Part_List = part_trackList;
        if (searched_PO.PO_Part_List.length > 0)
          PO_list.push(searched_PO);
      });
    }
    return PO_list;
  }

  async search_PO_withStatusNotCompleted(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          let searched_PO = new PurchaseOrder;

          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          searched_PO.Longest_Date = new Date('2100-01-01 00:00:00 UTC+00');
          searched_PO.Customer = childSnapshot.child('_Customer').val();
          searched_PO.Customer_PO = childSnapshot.child('_Customer_PO').val();
          searched_PO.Person_In_Charge = childSnapshot.child('_PIC').val();

          let part_trackList: PartTracker[] = [];
          var allCompleted = true;
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() != 'Complete'){
              let part_track = new PartTracker;
              part_track.ID = childSnapshot2.key;
              part_track.SO_No = childSnapshot.key;
              part_track.Customer_Name = childSnapshot.child('_Customer').val();
              part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.UOM = childSnapshot2.child("Part Uom").val();
              part_track.JOStatus = childSnapshot2.child('Status').val();
              part_track.OutsourcePath = childSnapshot2.child('Outsouce_Detail').val();
              part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
              part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
              var scheduleList: ScheduleInfo[] = [];

              if(part_track.OutsourcePath != null && part_track.OutsourcePath != ''){
                this.getOutsourceStatus(part_track);
              }

              if(childSnapshot2.child('Status').val() != 'Complete'){
                allCompleted = false;
                if(part_track.EndDate.getTime() <= searched_PO.Longest_Date.getTime()){
                  searched_PO.Longest_Date = part_track.EndDate;
                }
              }

              const rawMaterialID = [];
              childSnapshot2.child('Raw Material').forEach(childSnapshot3 => {
                const info = {
                  ID: childSnapshot3.child('ID').val(),
                  Name: childSnapshot3.child('Name').val(),
                  Material: childSnapshot3.child('Material').val(),
                  Raw_Type: childSnapshot3.child('Type').val(),
                  UOM: childSnapshot3.child('UOM').val(),
                  Amount: childSnapshot3.child('Amount').val()
                }
      
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                    raw.Material_ID = snap.key;
                    raw.Material_Name = snap.child('_Material_Name').val();
                    raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                    raw.In_Stock = snap.child('_In_Stock').val();
                    raw.Unit = info.UOM;
                    //raw.Unit = snap.child('_Unit').val();
                    raw.Unit_Price = snap.child('_Unit_Price').val(); 
                    raw.Updated_By = snap.child('_Updated_By').val();
                    raw.Created_By = snap.child('_Created_By').val();
                    raw.Created_Date = new Date(snap.child('_Created_Date').val());
                    raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                    raw.Part_Material_Name = info.Name;
                    raw.Raw_Amount = info.Amount;
                    raw.Raw_Type = info.Raw_Type;
                    rawMaterialID.push(raw);
                  }
                });
              });

              childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
                let schedule = new ScheduleInfo;
    
                schedule.Timestamp = childSnapshot3.key;
                schedule.Process = childSnapshot3.child("Process").val();
                schedule.Type = childSnapshot3.child("Type").val();
                schedule.Supplier = childSnapshot3.child("Supplier").val();
                schedule.By = childSnapshot3.child("Created_By").val();
                scheduleList.push(schedule);
              });

              scheduleList.sort((a, b)=>{
                return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
              });
    
              for(var i=0; i<scheduleList.length-1; i++){
                scheduleList[i].TubingDifferent = new Date(scheduleList[i].Timestamp).getTime() - new Date(scheduleList[i+1].Timestamp).getTime();
                scheduleList[i].TubingDifferent = scheduleList[i].TubingDifferent / 3600000;
              }
    
              part_track.Schedule = scheduleList;
              
              part_track.Raw_Material = rawMaterialID;
              this.getPartInfo(part_track);
              part_track.Date_Diff = Math.ceil((part_track.EndDate.getTime() - part_track.StartDate.getTime()) / (24 * 60 * 60 * 1000)).toString();
              part_trackList.push(part_track);
            }
          });

          searched_PO.PO_Part_List = part_trackList;

          if (searched_PO.PO_Part_List.length > 0){
            if(searched_PO.Status == "Incomplete" && allCompleted){
              this.db.database.ref('Purchase Order/' + searched_PO.PO_No + '/_Status').set("Complete");
            }
            else{
              PO_list.push(searched_PO);
            }
          }
            
        }
      });
    }

    return PO_list;
  }

  async search_JO_withStatusNotCompleted(): Promise<PartTracker[]> {
    let JOList: PartTracker[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() == 'Incomplete'){
              let part_track = new PartTracker;
              part_track.SO_No = childSnapshot.key;
              part_track.JO_No = childSnapshot2.key;
              part_track.EndDate = new Date(childSnapshot2.child("End Date").val());
              part_track.PO_Part_No = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.UOM = childSnapshot2.child("Part Uom").val();
              part_track.PO_Status = childSnapshot2.child('Status').val();
              part_track.Customer_Name = childSnapshot.child('_Customer').val();
              part_track.Date_Diff = Math.ceil((part_track.EndDate.getTime() - part_track.StartDate.getTime()) / (24 * 60 * 60 * 1000)).toString();
              this.getPartRawMaterialUsed(part_track);
              this.getPartInfo(part_track);
              JOList.push(part_track);
            };
          });
        }
      });
    }

    return JOList;
  }

  async search_PO_withoutCompletedGroupByCompany(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let Cus_list: String[] = [];

    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete' && !Cus_list.includes(childSnapshot.child('_Customer').val())){
          let searched_PO = new PurchaseOrder;
          //searched_PO.PO_No = childSnapshot.key;
          //searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          //searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          //searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          //searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          //searched_PO.Longest_Date = new Date('2100-01-01 00:00:00 UTC+00');
          searched_PO.Customer = childSnapshot.child('_Customer').val();
          //searched_PO.Customer_PO = childSnapshot.child('_Customer_PO').val();
          //searched_PO.Person_In_Charge = childSnapshot.child('_PIC').val();

          let part_trackList: PartTracker[] = [];
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            let part_track = new PartTracker;
            part_track.ID = childSnapshot2.key;
            part_track.SO_No = childSnapshot.key;
            part_track.Customer_Name = childSnapshot.child('_Customer').val();
            part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
            part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
            part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
            part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
            part_track.UOM = childSnapshot2.child("Part Uom").val();
            part_track.JOStatus = childSnapshot2.child('Status').val();
            part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
            part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
            this.getPartInfo(part_track);
            part_trackList.push(part_track);
          });

          searched_PO.PO_Part_List = part_trackList;

          if (searched_PO.PO_Part_List.length > 0){
            PO_list.push(searched_PO);
            Cus_list.push(childSnapshot.child('_Customer').val());
          }
        }
        else if(childSnapshot.child('_Status').val() == 'Incomplete'){
          let index = Cus_list.indexOf(childSnapshot.child('_Customer').val());

          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            let part_track = new PartTracker;
            part_track.ID = childSnapshot2.key;
            part_track.SO_No = childSnapshot.key;
            part_track.Customer_Name = childSnapshot.child('_Customer').val();
            part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
            part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
            part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
            part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
            part_track.UOM = childSnapshot2.child("Part Uom").val();
            part_track.JOStatus = childSnapshot2.child('Status').val();
            part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
            part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
            this.getPartInfo(part_track);
            PO_list[index].PO_Part_List.push(part_track);
          });
        }
      });
    }

    return PO_list;
  }

  async getJOList(): Promise<PartTracker[]> {
    let JOList: PartTracker[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() == 'Planned Material' 
            || childSnapshot2.child('Status').val() == 'Drawing Released' 
            || childSnapshot2.child('Status').val() == 'Drawing Reviewed' 
            || childSnapshot2.child('Status').val() == 'Drawing Review' 
            || childSnapshot2.child('Status').val() == 'Processing' 
            || childSnapshot2.child('Status').val() == 'Outsourcing'){
              let part_track = new PartTracker;
              var scheduleList: ScheduleInfo[] = [];

              childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
                let schedule = new ScheduleInfo;
    
                schedule.Timestamp = childSnapshot3.key;
                schedule.Process = childSnapshot3.child("Process").val();
                schedule.Type = childSnapshot3.child("Type").val();
                schedule.Supplier = childSnapshot3.child("Supplier").val();
                schedule.By = childSnapshot3.child("Created_By").val();
                scheduleList.push(schedule);
              });
  
              scheduleList.sort((a, b)=>{
                return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
              });
    
              for(var i=0; i<scheduleList.length-1; i++){
                scheduleList[i].TubingDifferent = new Date(scheduleList[i].Timestamp).getTime() - new Date(scheduleList[i+1].Timestamp).getTime();
                scheduleList[i].TubingDifferent = scheduleList[i].TubingDifferent / 3600000;
              }
    
              part_track.Schedule = scheduleList;

              part_track.SO_No = childSnapshot.key;
              part_track.JO_No = childSnapshot2.key;
              part_track.EndDate = new Date(childSnapshot2.child("End Date").val());
              part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.UOM = childSnapshot2.child("Part Uom").val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.PO_Status = childSnapshot2.child('Status').val();
              this.getPartInfo(part_track);
              JOList.push(part_track);
            };
          });
        }
      });
    }

    return JOList;
  }

  async search_JO_withStatusDrawingReviwed(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          let searched_PO = new PurchaseOrder;

          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          searched_PO.Longest_Date = new Date('2100-01-01 00:00:00 UTC+00');
          searched_PO.Customer = childSnapshot.child('_Customer').val();
          searched_PO.Customer_PO = childSnapshot.child('_Customer_PO').val();
          searched_PO.Person_In_Charge = childSnapshot.child('_PIC').val();

          let part_trackList: PartTracker[] = [];
          var allCompleted = true;
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() == "Drawing Reviewed"){
              let part_track = new PartTracker;
              part_track.ID = childSnapshot2.key;
              part_track.SO_No = childSnapshot.key;
              part_track.Customer_Name = childSnapshot.child('_Customer').val();
              part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.UOM = childSnapshot2.child("Part Uom").val();
              part_track.JOStatus = childSnapshot2.child('Status').val();
              part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
              part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
              var scheduleList: ScheduleInfo[] = [];
  
              if(childSnapshot2.child('Status').val() != 'Complete'){
                allCompleted = false;
                if(part_track.EndDate.getTime() <= searched_PO.Longest_Date.getTime()){
                  searched_PO.Longest_Date = part_track.EndDate;
                }
              }
  
              const rawMaterialID = [];
              childSnapshot2.child('Raw Material').forEach(childSnapshot3 => {
                const info = {
                  ID: childSnapshot3.child('ID').val(),
                  Name: childSnapshot3.child('Name').val(),
                  Material: childSnapshot3.child('Material').val(),
                  Raw_Type: childSnapshot3.child('Type').val(),
                  UOM: childSnapshot3.child('UOM').val(),
                  Amount: childSnapshot3.child('Amount').val()
                }
      
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                    raw.Material_ID = snap.key;
                    raw.Material_Name = snap.child('_Material_Name').val();
                    raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                    raw.In_Stock = snap.child('_In_Stock').val();
                    raw.Unit = info.UOM;
                    //raw.Unit = snap.child('_Unit').val();
                    raw.Unit_Price = snap.child('_Unit_Price').val(); 
                    raw.Updated_By = snap.child('_Updated_By').val();
                    raw.Created_By = snap.child('_Created_By').val();
                    raw.Created_Date = new Date(snap.child('_Created_Date').val());
                    raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                    raw.Part_Material_Name = info.Name;
                    raw.Raw_Amount = info.Amount;
                    raw.Raw_Type = info.Raw_Type;
                    rawMaterialID.push(raw);
                  }
                });
              });
  
              childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
                let schedule = new ScheduleInfo;
    
                schedule.Timestamp = childSnapshot3.key;
                schedule.Process = childSnapshot3.child("Process").val();
                schedule.Type = childSnapshot3.child("Type").val();
                schedule.Supplier = childSnapshot3.child("Supplier").val();
                schedule.By = childSnapshot3.child("Created_By").val();
                scheduleList.push(schedule);
              });
  
              scheduleList.sort((a, b)=>{
                return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
              });
    
              for(var i=0; i<scheduleList.length-1; i++){
                scheduleList[i].TubingDifferent = new Date(scheduleList[i].Timestamp).getTime() - new Date(scheduleList[i+1].Timestamp).getTime();
                scheduleList[i].TubingDifferent = scheduleList[i].TubingDifferent / 3600000;
              }
    
              part_track.Schedule = scheduleList;
              
              part_track.Raw_Material = rawMaterialID;
              this.getPartInfo(part_track);
              part_trackList.push(part_track);
            }
          });

          searched_PO.PO_Part_List = part_trackList;

          if (searched_PO.PO_Part_List.length > 0){
            if(searched_PO.Status == "Incomplete" && allCompleted){
              this.db.database.ref('Purchase Order/' + searched_PO.PO_No + '/_Status').set("Complete");
            }
            else{
              PO_list.push(searched_PO);
            }
          }
        }
      });
    }

    return PO_list;
  }

  async search_PO_forDrawingReport(): Promise<PartTracker[]> {
    let JO_list: PartTracker[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        childSnapshot.child('Part List').forEach(childSnapshot2 => {
          let part_track = new PartTracker;
          part_track.ID = childSnapshot2.key;
          part_track.SO_No = childSnapshot.key;
          part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
          part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
          part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
          part_track.CustomerName = childSnapshot.child('_Customer').val();
          part_track.AssignTo = childSnapshot2.child('Assign To').val();
          

          childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
            if(childSnapshot3.child("Process").val() == 'Drawing Review'){
              part_track.StartDrawing = new Date(parseInt(childSnapshot3.key.toString()));
            }
            else if(childSnapshot3.child("Process").val() == 'Drawing Reviewed'){
              part_track.EndDrawing = new Date(parseInt(childSnapshot3.key.toString()));
              part_track.CompletedBy = childSnapshot3.child('Completed By').val();
              this.getStaffInfoComplete(part_track);
            }
          });

          this.getPartInfo(part_track);
          this.getStaffInfo(part_track);
          JO_list.push(part_track);
        });
      });
    }

    return JO_list;
  }

  async search_PO_withStatusDrawingReview(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          let searched_PO = new PurchaseOrder;

          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          searched_PO.Longest_Date = new Date('2100-01-01 00:00:00 UTC+00');
          searched_PO.Customer = childSnapshot.child('_Customer').val();
          searched_PO.Customer_PO = childSnapshot.child('_Customer_PO').val();
          searched_PO.Person_In_Charge = childSnapshot.child('_PIC').val();

          let part_trackList: PartTracker[] = [];
          var allCompleted = true;
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() == "Drawing Review"
            || childSnapshot2.child('Status').val() == "Pending Customer Soft Copy"
            || childSnapshot2.child('Status').val() == "Pending Customer Confirmation"
            || childSnapshot2.child('Status').val() == "Drawing Reviewed"){
              let part_track = new PartTracker;
              part_track.ID = childSnapshot2.key;
              part_track.SO_No = childSnapshot.key;
              part_track.Customer_Name = childSnapshot.child('_Customer').val();
              part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.UOM = childSnapshot2.child("Part Uom").val();
              part_track.JOStatus = childSnapshot2.child('Status').val();
              part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
              part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
              part_track.AssignTo = childSnapshot2.child('Assign To').val();
              this.getStaffInfo(part_track);
              var scheduleList: ScheduleInfo[] = [];

              if(childSnapshot2.child('Status').val() != 'Complete'){
                allCompleted = false;
                if(part_track.EndDate.getTime() <= searched_PO.Longest_Date.getTime()){
                  searched_PO.Longest_Date = part_track.EndDate;
                }
              }

              childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
                if(childSnapshot3.child("Process").val() == 'Drawing Review'){
                  part_track.StartDrawing = new Date(parseInt(childSnapshot3.key.toString()));
                }
                else if(childSnapshot3.child("Process").val() == 'Drawing Reviewed'){
                  part_track.EndDrawing = new Date(parseInt(childSnapshot3.key.toString()));
                }
              });

              const rawMaterialID = [];
              childSnapshot2.child('Raw Material').forEach(childSnapshot3 => {
                const info = {
                  ID: childSnapshot3.child('ID').val(),
                  Name: childSnapshot3.child('Name').val(),
                  Material: childSnapshot3.child('Material').val(),
                  Raw_Type: childSnapshot3.child('Type').val(),
                  UOM: childSnapshot3.child('UOM').val(),
                  Amount: childSnapshot3.child('Amount').val()
                }
      
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                    raw.Material_ID = snap.key;
                    raw.Material_Name = snap.child('_Material_Name').val();
                    raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                    raw.In_Stock = snap.child('_In_Stock').val();
                    raw.Unit = info.UOM;
                    //raw.Unit = snap.child('_Unit').val();
                    raw.Unit_Price = snap.child('_Unit_Price').val(); 
                    raw.Updated_By = snap.child('_Updated_By').val();
                    raw.Created_By = snap.child('_Created_By').val();
                    raw.Created_Date = new Date(snap.child('_Created_Date').val());
                    raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                    raw.Part_Material_Name = info.Name;
                    raw.Raw_Amount = info.Amount;
                    raw.Raw_Type = info.Raw_Type;
                    rawMaterialID.push(raw);
                  }
                });
              });

              childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
                let schedule = new ScheduleInfo;
    
                schedule.Timestamp = childSnapshot3.key;
                schedule.Process = childSnapshot3.child("Process").val();
                schedule.Type = childSnapshot3.child("Type").val();
                schedule.Supplier = childSnapshot3.child("Supplier").val();
                schedule.By = childSnapshot3.child("Created_By").val();
                scheduleList.push(schedule);
              });

              scheduleList.sort((a, b)=>{
                return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
              });
    
              for(var i=0; i<scheduleList.length-1; i++){
                scheduleList[i].TubingDifferent = new Date(scheduleList[i].Timestamp).getTime() - new Date(scheduleList[i+1].Timestamp).getTime();
                scheduleList[i].TubingDifferent = scheduleList[i].TubingDifferent / 3600000;
              }
    
              part_track.Schedule = scheduleList;
              
              part_track.Raw_Material = rawMaterialID;
              this.getPartInfo(part_track);
              part_trackList.push(part_track);
              }
          });

          searched_PO.PO_Part_List = part_trackList;

          if (searched_PO.PO_Part_List.length > 0){
            if(searched_PO.Status == "Incomplete" && allCompleted){
              this.db.database.ref('Purchase Order/' + searched_PO.PO_No + '/_Status').set("Complete");
            }
            else{
              PO_list.push(searched_PO);
            }
          }
        }
      });
    }

    return PO_list;
  }

  async search_JO_withStatusDrawingReview(): Promise<PartTracker[]> {
    let PO_list: PartTracker[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() == "Drawing Review"
            || childSnapshot2.child('Status').val() == "Pending Customer Soft Copy"
            || childSnapshot2.child('Status').val() == "Pending Customer Confirmation"){
              let part_track = new PartTracker;
              part_track.ID = childSnapshot2.key;
              part_track.SO_No = childSnapshot.key;
              part_track.Customer_Name = childSnapshot.child('_Customer').val();
              part_track.Customer_Name = childSnapshot.child('_Customer').val();
              part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.UOM = childSnapshot2.child("Part Uom").val();
              part_track.JOStatus = childSnapshot2.child('Status').val();
              part_track.StartDate = new Date(childSnapshot2.child('Start Date').val());
              part_track.EndDate = new Date(childSnapshot2.child('End Date').val());
              part_track.AssignTo = childSnapshot2.child('Assign To').val();

              childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
                if(childSnapshot3.child("Process").val() == 'Drawing Review'){
                  part_track.StartDrawing = new Date(parseInt(childSnapshot3.key.toString()));
                }
                else if(childSnapshot3.child("Process").val() == 'Drawing Reviewed'){
                  part_track.EndDrawing = new Date(parseInt(childSnapshot3.key.toString()));
                }
              });

              this.getStaffInfo(part_track);
              this.getPartInfo(part_track);
              PO_list.push(part_track);
            }
          });
        }
      });
    }

    return PO_list;
  }

  async getIncompleteJOList(): Promise<PartTracker[]> {
    let JOList: PartTracker[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() != 'Complete' && childSnapshot2.child('Status').val() != 'QC'){
              let part_track = new PartTracker;

              if(childSnapshot2.child('Status').val().includes('Partial')){
                childSnapshot2.child("Schedule").forEach((childSnapshot3) => {
                  if(childSnapshot3.child("Process").val() == childSnapshot2.child('Status').val()){
                    part_track.FinishedQuantity = childSnapshot3.child('Finished').val();
                    part_track.RemainingQuantity = childSnapshot3.child('Remaining').val();
                  }
                });
              }
    
              part_track.SO_No = childSnapshot.key;
              part_track.JO_No = childSnapshot2.key;
              part_track.EndDate = new Date(childSnapshot2.child("End Date").val());
              part_track.PO_Part_ID = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.UOM = childSnapshot2.child("Part Uom").val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.PO_Status = childSnapshot2.child('Status').val();
              this.getPartInfo(part_track);
              JOList.push(part_track);
            };
          });
        }
      });
    }

    return JOList;
  }

  async getPartRawMaterialUsed(part_track: PartTracker): Promise<void> {
    var childSnapshot = await this.db.database.ref('Part/'+part_track.PO_Part_No).once('value');

    const rawMaterialID = [];
    part_track.Raw_Material_Status = "true";
    childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
      const info = {
        ID: childSnapshot2.child('ID').val(),
        Name: childSnapshot2.child('Name').val(),
        Material: childSnapshot2.child('Material').val(),
        Raw_Type: childSnapshot2.child('Type').val(),
        UOM: childSnapshot2.child('UOM').val(),
        Amount: childSnapshot2.child('Amount').val()
      }

      this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
        if (snap.exists()) {
          const raw = new RawMaterialInfo();
          raw.Material_ID = snap.key;
          raw.Material_Name = snap.child('_Material_Name').val();
          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
          raw.In_Stock = snap.child('_In_Stock').val();
          raw.Unit = info.UOM;
          raw.Unit_Price = snap.child('_Unit_Price').val(); 
          raw.Updated_By = snap.child('_Updated_By').val();
          raw.Created_By = snap.child('_Created_By').val();
          raw.Created_Date = new Date(snap.child('_Created_Date').val());
          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
          raw.Part_Material_Name = info.Name;
          raw.Raw_Amount = info.Amount;
          raw.Raw_Type = info.Raw_Type;
          this.getRawMaterialUsed(raw, part_track);
          rawMaterialID.push(raw);
        }
      });
    });

    part_track.Raw_Material = rawMaterialID;
  }

  async getRawMaterialUsed(raw: RawMaterialInfo, quantity: PartTracker): Promise<void> {
    raw.quantityNeeded = parseFloat((parseFloat(raw.Raw_Amount) * parseFloat(quantity.POQuantity.toString())).toString()).toFixed(2).toString();

    if(parseFloat(raw.quantityNeeded) > parseFloat(raw.In_Stock)){
      quantity.Raw_Material_Status = "false";
    }
  }

  async getOutsourceStatus(pt: PartTracker): Promise<void> {
    if(pt.OutsourcePath){
      var snapshot = await this.db.database.ref('PO Outsource/' + pt.OutsourcePath).once('value');

      if (snapshot.exists()) {
        pt.OutsourceStatus = snapshot.child('_PO_RawMaterial_Status').val();
      }
    }
  }

  async getPartInfo(pt: PartTracker): Promise<void> {
    if(pt.PO_Part_ID){
      var snapshot = await this.db.database.ref('Part').child(pt.PO_Part_ID).once('value');
    
      if (snapshot.exists()) {
        pt.Part_Weight = snapshot.child('Part Weight').val();
        pt.PO_Part_No = snapshot.child('Part No').val();
        pt.PO_Part_Name = snapshot.child('Part Name').val();
        pt.PartPhoto = snapshot.child('PhotoURL').val();
        pt.QuantityPerBox = snapshot.child('Packaging/Box/Quantity in Box').val();
        pt.InStock = snapshot.child('Stock Quantity').val();
        pt.RevNO = snapshot.child('RevNO').val();
        pt.PhotoURL = snapshot.child('PhotoURL').val();
        pt.DrawingURL = snapshot.child('DrawingURL').val();
        pt.UnfoldURL = snapshot.child('UnfoldURL').val();
        pt.Outsource = snapshot.child('Outsource').val();
        pt.Process = snapshot.child('Process').val();
        pt.Outsources = snapshot.child('Outsources').val();
        pt.tow = snapshot.child('typeOfWork').val();
      }
    }
  }

  async getCustomerInfo(id, pt: PartTracker): Promise<void> {
    var cusSnapshot = await this.db.database.ref('Customer').child(id).once('value');
    if (cusSnapshot.exists()) {
      pt.CustomerName = cusSnapshot.child('_Customer_Name').val();
      pt.CustomerAddress = cusSnapshot.child('_Address').val();
      pt.partCurrency = cusSnapshot.child('_Currency').val();
    }
  }

  async getInvoice(poNumber, pt: PartTracker): Promise<void> {
    var snapshot = await this.db.database.ref('PackingList').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(chilsnapshot => {
        if (chilsnapshot.child('POList').child(poNumber).exists()) {
          pt.PackingListNo = chilsnapshot.key;
          // pt.PackingListNo = chilsnapshot.child('Inv_PackingListNo').val();
        }
      })
    }
  }

  async getStaffInfo(pt: PartTracker): Promise<void> {
    if(pt.AssignTo != null && pt.AssignTo != ''){
      var snapshot = await this.db.database.ref('User').child(pt.AssignTo.toString()).once('value');
    
      if (snapshot.exists()) {
        pt.AssignToName = snapshot.child('StaffName').val();
      }
    }
  }

  async getStaffInfoComplete(pt: PartTracker): Promise<void> {
    if(pt.CompletedBy != null && pt.CompletedBy != ''){
      var snapshot = await this.db.database.ref('User').child(pt.CompletedBy.toString()).once('value');
    
      if (snapshot.exists()) {
        pt.CompletedByName = snapshot.child('StaffName').val();
      }
    }
  }

  getTodayDate(): string {
    const today = new Date();
  
    // Extract year, month, and day components
    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const day = today.getDate().toString().padStart(2, '0');
  
    // Concatenate components in yyyyMMdd format
    const formattedDate = `${year}${month}${day}`;
  
    return formattedDate;
  }
}
