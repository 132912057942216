import { ScheduleTracker } from '../../Services/Object_Classes/Machine/Machine';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { OrdersComponent } from '../raw/PO/orders/orders.component';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { PackingList_PO_List, PackingList, PackingList_PO_Part_List } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AddPackingComponent } from '../invoice/add-packing/add-packing.component';
import { PackingListDB_controller } from 'src/app/Services/DB_Controller/PackingListDB_controller';
import { GenerateOldLabelComponent } from '../invoice/generate-old-label/generate-old-label.component';
import { GenerateQRCodeDialogComponent } from '../invoice/generate-qrcode-dialog/generate-qrcode-dialog.component';
import { UpdatePackingComponent } from '../invoice/update-packing/update-packing.component';
import { ScanStartComponent } from './scan-start/scan-start.component';
import { ScanEndComponent } from './scan-end/scan-end.component';
import { ReceiveDetailsComponent } from './receive-details/receive-details.component';
import { ScanDrawingComponent } from './scan-drawing/scan-drawing.component';
import { InputDialogComponent } from 'src/app/Shared/input-dialog/input-dialog.component';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';

@Component({
  selector: 'app-purchaseOrder',
  templateUrl: './manufacturing.component.html',
  styleUrls: ['./manufacturing.component.css']
})
export class ManufacturingComponent implements OnInit {
  POno: string;
  partNo: string;
  POquantity: number;
  CustomerDB_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);
  email: string;
  JOList: PurchaseOrder[] = [];
  CloneJOList: PurchaseOrder[] = [];
  IncompletePOGroupedList: Machine[] = [];
  ClonePOGroupedList: Machine[] = [];

  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;


  //Pagination2
  length2 = 0;
  pageSize2 = 10;
  pageSizeOptions2: number[] = [10, 25, 50];
  pageIndex2 = 0;
  offset2 = this.pageSize2 * this.pageIndex2;
  search2: string;
  sortedu2 = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator2', { read: MatPaginator, static: true }) topPaginator2: MatPaginator;
  @ViewChild('bottomPaginator2', { read: MatPaginator, static: true }) bottomPaginator2: MatPaginator;

  PODB_controller: PODB_controller = new PODB_controller(this.db);
  machineDB_controller = new MachineDB_controller(this.db);

  machinelist = []
  role:string;
  acccessRight:any[]=[];
  poStatus: string = "";
  disableDelete = true;

  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private angularFireAuth: AngularFireAuth,
    private excelHelper: ExcelHelperService,
    private firestore: AngularFirestore,
    private userinfoSrv:UserInfoService
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role
      if(this.userinfoSrv.checkPermission(this.role, 'Manufacturing',this.acccessRight)){
        this.disableDelete = false;
      }
    });

    this.userinfoSrv.currentAccessRights.subscribe(access=>{
      this.acccessRight = access
      if(this.userinfoSrv.checkPermission(this.role, 'Manufacturing',this.acccessRight)){
        this.disableDelete = false;
      }
    });
  }

  ngOnInit() {
    this.setup();
  }

  refresh(){
    this.setup();
  }

  refreshJO(){
    this.setup();
  }
  
  setup(){
    this.spinner.show();
    this.PODB_controller.search_PO_withStatusNotCompleted().then(async data => {
      this.JOList = data;

      await this.JOList.sort((a,b)=>{
        return a.Longest_Date.getTime() - b.Longest_Date.getTime();
      });

      this.length = this.JOList.length;
      this.CloneJOList = this.JOList.slice();
      this.limitList();
      this.spinner.hide();
    });

    this.StaffDB_controller.getStationScheduleList().then(data => {
      this.IncompletePOGroupedList = data;
      this.length2 = this.IncompletePOGroupedList.length;
      this.ClonePOGroupedList = this.IncompletePOGroupedList.slice();
      this.limitList2();
      this.spinner.hide();
    });
  }

  deletePO(POnumber) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this PO? This will affect the schedule';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PODB_controller.delete_PO(POnumber);
        this.setup();
      }
    })

  }

  filterStatus(){
    if(this.search)
      this.DS();
    else
      this.CloneJOList = this.JOList.slice();

    this.CloneJOList =  this.CloneJOList.filter(u =>{
      for (const part of u.PO_Part_List) {
          if(part.JOStatus!=null && (part.JOStatus.includes(this.poStatus)))
            return true;
      }
      return false
    });
    
    this.length = this.CloneJOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
  }

  fs(){
    this.CloneJOList =  this.CloneJOList.filter(u =>{
      for (const part of u.PO_Part_List) {
          if(part.JOStatus!=null && (part.JOStatus.includes(this.poStatus)))
            return true;
      }
      return false
    });
  }

  DynamicSearchPO(partNum: string): void {
    this.search = partNum;
    this.CloneJOList = this.JOList.filter(u =>{
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase()) || 
      String(u.Customer).toLowerCase().includes(this.search.toLowerCase());
      
      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No && part.PO_Part_Name){
          if((part.PO_Part_No.toLowerCase().includes(this.search.toLowerCase())|| (part.PO_Part_Name.toLowerCase().includes(this.search.toLowerCase()))))
            return true;
        }

        if((part.ID.toLowerCase().includes(this.search.toLowerCase())))
          return true;
      }
      return flag

    });
    this.length = this.CloneJOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
  }

  DynamicSearchJO(partNum: string): void {
    this.search2 = partNum;
    this.ClonePOGroupedList = this.IncompletePOGroupedList.filter(u =>{
      const flag = String(u.StationName).toLowerCase().includes(this.search2.toLowerCase());
      
      for (const part of u.MachineSchedule) {
        if(part.JO_No && part.JO_No){
          if((part.HandledBy.toLowerCase().includes(this.search2.toLowerCase())
          || (part.Status.toLowerCase().includes(this.search2.toLowerCase()))))
            return true;
        }
      }
      return flag

    });
    this.length2 = this.ClonePOGroupedList.length;
    this.sortPOList2();
    this.limitList2();
    this.topPaginator2.firstPage();
    this.bottomPaginator2.firstPage();
  }

  DS() {
    this.CloneJOList = this.JOList.filter(u =>{
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No && part.PO_Part_Name)
        if((part.PO_Part_No.toLowerCase().includes(this.search.toLowerCase())
        || (part.PO_Part_Name.toLowerCase().includes(this.search.toLowerCase()))))
            return true;
      }

      return flag;
    });

    this.length = this.CloneJOList.length;
  }

  DS2() {
    this.ClonePOGroupedList = this.IncompletePOGroupedList.filter(u =>{
      const flag = String(u.StationName).toLowerCase().includes(this.search2.toLowerCase())

      for (const part of u.MachineSchedule) {
        if(part.JO_No && part.JO_No)
        if((part.JO_No.toLowerCase().includes(this.search2.toLowerCase())
        || (part.HandledBy.toLowerCase().includes(this.search2.toLowerCase()))))
            return true;
      }

      return flag;
    });

    this.length2 = this.ClonePOGroupedList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;

    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }

    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }

    if (this.search) {
      this.DS();
    } else {
      this.CloneJOList = this.JOList.slice();
    }

    //this.fs();
    this.sortPOList();
    this.limitList();
  }

  paginator2(pageEvent: PageEvent) {
    this.pageSize2 = pageEvent.pageSize;
    this.pageIndex2 = pageEvent.pageIndex;
    this.offset2 = this.pageSize2 * this.pageIndex2;

    if (this.topPaginator2.pageIndex < this.pageIndex2) {
      this.topPaginator2.nextPage();
    } else if (this.topPaginator2.pageIndex > this.pageIndex2) {
      this.topPaginator2.previousPage();
    }

    if (this.bottomPaginator2.pageIndex < this.pageIndex2) {
      this.bottomPaginator2.nextPage();
    } else if (this.bottomPaginator2.pageIndex > this.pageIndex2) {
      this.bottomPaginator2.previousPage();
    }

    if (this.search2) {
      this.DS2();
    } else {
      this.ClonePOGroupedList = this.IncompletePOGroupedList.slice();
    }

    //this.fs();
    this.sortPOList2();
    this.limitList2();
  }

  limitList() {
    this.CloneJOList = this.CloneJOList.slice(this.offset, (this.offset + this.pageSize));
  }

  limitList2() {
    this.ClonePOGroupedList = this.IncompletePOGroupedList.slice(this.offset2, (this.offset2 + this.pageSize2));
  }

  sortPOList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.CloneJOList = this.CloneJOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        default: return 0;
      }
    });
  }

  sortPOList2() {
    if (!this.sortedu2.active || this.sortedu2.direction === '') {
      return;
    }
    this.ClonePOGroupedList = this.ClonePOGroupedList.sort((a, b) => {
      const isAsc = this.sortedu2.direction === 'asc';
      switch (this.sortedu2.active) {
        case 'station': return this.compare(a.StationName, b.StationName, isAsc);
        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.CloneJOList = this.JOList.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.CloneJOList = this.JOList.slice();
      this.limitList();
      return;
    }

    this.CloneJOList = this.CloneJOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
         default: return 0;
      }
    });
    this.limitList();
  }

  sortData2(sort: Sort) {
    this.sortedu2 = sort;
    this.ClonePOGroupedList = this.IncompletePOGroupedList.slice();
    if (this.search2) {
      this.DS2();
    }
    if (!sort.active || sort.direction === '' && !this.search2) {
      this.ClonePOGroupedList = this.IncompletePOGroupedList.slice();
      this.limitList2();
      return;
    }

    this.ClonePOGroupedList = this.ClonePOGroupedList.sort((a, b) => {
      const isAsc = this.sortedu2.direction === 'asc';
      switch (this.sortedu2.active) {
        case 'station': return this.compare(a.StationName, b.StationName, isAsc);
         default: return 0;
      }
    });
    this.limitList2();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewOrder(po){
    this.dialog.open(OrdersComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();

    for (const po of this.JOList) {
      const info ={
        "PO":po.PO_No || "-",
        "Part List": po.PO_Part_List.map(a=>a.PO_Part_No).join('  |  '),
        "Part List Name": po.PO_Part_List.map(a=>a.PO_Part_Name).join(' |  '),
        "Production Quantity": po.PO_Part_List.map(a=>a.PO_Part_Qty).join(' |  '),
        "PO Quantity": po.PO_Part_List.map(a=>a.POQuantity).join(' |  '),
        "Reference": po.PO_Part_List.map(a=>a.Reference).join(' |  '),
        "POS": po.PO_Part_List.map(a=>a.POS).join(' |  '),
        "Status": po.PO_Part_List.map(a=>a.PO_Status).join(' |  '),
        "Created Date":po.Created_Date
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();
  }

  msToTime(end,start) {
    let duration = parseFloat(end)-parseFloat(start);
    if(duration > 0){
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hrs = (hours < 10) ? "0" + hours : hours;
      let min = (minutes < 10) ? "0" + minutes : minutes;
      let sec = (seconds < 10) ? "0" + seconds : seconds;
      return hrs + ":" + min + ":" + sec;
    }
    return 0;

  }

  hoursDisplay(hr){
    if(hr > 0){
      let min = hr - parseFloat(hr.toString().split('.')[0])
      min = min * 60
      let sec = min - parseFloat(min.toString().split('.')[0])
      sec = sec * 60 ;
      let hrs1 = (hr < 10) ? "0" + hr.toString().split('.')[0] : hr.toString().split('.')[0];
      let min1 = (min < 10) ? "0" + min.toString().split('.')[0] : min.toString().split('.')[0];
      let sec1 = (sec < 10) ? "0" + sec.toString().split('.')[0] : sec.toString().split('.')[0];
      return hrs1 + ":" + min1 + ":" + sec1;
    }
    return 0;
  }

  viewDrawing(url){
    window.open(url, '_blank');
  }

  validate(s){
    return typeof(s) === 'string'? true:false;
  }

  GenerateLabel() {
    this.dialog.open(GenerateQRCodeDialogComponent, {
      width: '80%',
      height: '80%'
    });
  }

  GenerateOldLabel() {
    this.dialog.open(GenerateOldLabelComponent, {
      width: '80%',
      height: 'auto'
    });
  }

  viewPackingDetail(packingNumber) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      seePacking: packingNumber,
    };
    this.dialog.open(UpdatePackingComponent, dialogConfig).afterClosed().subscribe(result => {
      setTimeout(() => {
        this.setup();
      }, 1000);
    });
  }
  

  getColor(quantityNeeded){
    if(quantityNeeded == 'Planned Material'){
      return "red";
    }
    else if(quantityNeeded == "Drawing Released"){
      return "violet"
    }
    else if(quantityNeeded == "Processing"){
      return "blue"
    }
    else if(quantityNeeded == "Complete"){
      return "green"
    }
    else if(quantityNeeded == "Outsourcing"){
      return "purple"
    }
  }

  getColorPanel(po:PurchaseOrder, index:any){
    if(po.Status == 'Incomplete' && po.PO_Part_List.length > 0){
      let flag = true;

      po.PO_Part_List.forEach(data => {
        if(data.JOStatus != 'Complete'){
          if(data.EndDate.getTime() - new Date().getTime() <= 259200000){
            flag = false;
            $('#SO' + index).attr('style', 'background-color: rgb(226 8 52 / 10%)');
            return 'rgb(226 8 52 / 25%)';
          }
          else if(data.EndDate.getTime() - new Date().getTime() <= 604800000){
            flag = false;
            $('#SO' + index).attr('style', 'background-color: rgb(226 222 8 / 10%)');
            return 'rgb(226 222 8 / 25%)';
          }
        }
      });

      if(flag){
        $('#SO' + index).attr('style', 'background-color: rgb(49 226 8 / 10%)');
        return 'rgb(49 226 8 / 25%)';
      }
    }
  }

  ScanDrawing(){
    const dialogRef = this.dialog.open(ScanDrawingComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
      }
    });
  }

  ScanOutsource(){
    const dialogRef = this.dialog.open(ScanStartComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
      }
    });
  }

  ScanEnd(){
    const dialogRef = this.dialog.open(ScanEndComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
      }
    });
  }

  ReleaseDrawing(machine: PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm received drawing?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        let updates1 = {};
        var current = new Date().getTime().toString();
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Status'] = "Drawing Released";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Process'] = "Drawing Released";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Created_By'] = this.email;
        await this.db.database.ref('Purchase Order/').update(updates1);
        this.setup();
      }
    });
  }

  StartProcess(machine: PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to start this job?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        let updates1 = {};
        var current = new Date().getTime().toString();
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Status'] = "Processing";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Process'] = "Processing";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Created_By'] = this.email;
        await this.db.database.ref('Purchase Order/').update(updates1);

        let qcRecord= {};
        qcRecord['QC Date'] = new Date().toISOString();
        qcRecord['Part Name'] = machine.PO_Part_Name;
        qcRecord['Part No'] = machine.PO_Part_ID;
        qcRecord['PO Qty'] = machine.POQuantity;
        qcRecord['Due Date'] = machine.EndDate.toISOString();
        qcRecord['Status'] = "Incomplete";

        if(parseInt(machine.POQuantity.toString()) <= 25){
          qcRecord['Inspect Qty'] = 2;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 26 && parseInt(machine.POQuantity.toString()) <= 50){
          qcRecord['Inspect Qty'] = 3;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 51 && parseInt(machine.POQuantity.toString()) <= 90){
          qcRecord['Inspect Qty'] = 4;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 91 && parseInt(machine.POQuantity.toString()) <= 150){
          qcRecord['Inspect Qty'] = 5;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 151 && parseInt(machine.POQuantity.toString()) <= 280){
          qcRecord['Inspect Qty'] = 6;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 281 && parseInt(machine.POQuantity.toString()) <= 500){
          qcRecord['Inspect Qty'] = 7;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 501 && parseInt(machine.POQuantity.toString()) <= 1200){
          qcRecord['Inspect Qty'] = 8;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 1201){
          qcRecord['Inspect Qty'] = 9;
        }

        await this.db.database.ref('QC/' + machine.SO_No + '/' + machine.ID).set(qcRecord);
        this.setup();
      }
    });
  }

  CancelProcess(machine: PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to cancel this job?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        let updates1 = {};
        var current = new Date().getTime().toString();
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Status'] = "Drawing Released";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Process'] = "Cancel Processing";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Created_By'] = this.email;
        await this.db.database.ref('Purchase Order/').update(updates1);
        this.setup();
      }
    });
  }

  CancelProcess2(machine: PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to cancel this job?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        let updates1 = {};
        var current = new Date().getTime().toString();
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Status'] = "Planned Material";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Process'] = "Cancel Drawing Released";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Created_By'] = this.email;
        await this.db.database.ref('Purchase Order/').update(updates1);
        this.setup();
      }
    });
  }

  EndProcess(machine: PartTracker){
    const dialogRef = this.dialog.open(ReceiveDetailsComponent, {
      width: '50%',
      height: 'auto',
      data: machine
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
      }
    });

    /*const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to end this job?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        let updates1 = {};
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Status'] = "Complete";
        this.db.database.ref('Purchase Order/').update(updates1);
        this.setup();
      }
    });*/
  }

  print(machine: PartTracker, cus: string, po: string){
    $("#forPrintJT").find("#description").text(machine.PO_Part_Name);
    $("#forPrintJT").find("#partNo").text(machine.PO_Part_No);
    $("#forPrintJT").find("#qty").text(machine.POQuantity + " " + machine.UOM);
    $("#forPrintJT").find("#cus").text(cus);
    $("#forPrintJT").find("#remarks").text(po);

    setTimeout(function(){
      //this.spinner.hide();
      var divContents = $("#forPrintJT").html();
      var printWindow = window.open('', '', 'height=189,width=189');
      printWindow.document.write('<html><head><title>Test Print</title>')
      printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 2px solid #dee2e6;} .table-bordered {border: 2px solid #000000;} .table-bordered th, .table-bordered td {border: 2px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>');
      printWindow.document.write(divContents);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 500);
    }, 500);
  }
}

