import { CrushingMaterialComponent } from './crushing-material/crushing-material.component';
import { Chart } from 'chart.js';
import { PoStockRecordComponent } from './po-stock-record/po-stock-record.component';
import { OldStock } from './../../Services/Object_Classes/RawMaterial/OldStock';
import { GenerateOldQRCodeComponent } from './generate-old-qrcode/generate-old-qrcode.component';
import { ManageRawTypeComponent } from './manage-raw-type/manage-raw-type.component';
import { SupplierDB_controller } from './../../Services/DB_Controller/SupplierDB_controller';
import { AddSupplierComponent } from './Supplier/add-supplier/add-supplier.component';
import { OrdersComponent } from './PO/orders/orders.component';
import { DetailPOComponent } from './PO/detail-po/detail-po.component';
import { DetailsRawComponent } from './PopUpRaw/details-raw/details-raw.component';
import { GenerateQRCodeComponent } from './generate-qrcode/generate-qrcode.component';
import { ReceivedOrderComponent } from './received-order/received-order.component';
import { PurchaseOrderRaw, RawMaterialOrder } from './../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { OrderRawMaterialComponent } from './order-raw-material/order-raw-material.component';
import { RawMaterialInfo } from './../../Services/Object_Classes/RawMaterial/RawMaterial';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddRawComponent } from './PopUpRaw/add-raw/add-raw.component';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { OldStockRecordComponent } from './old-stock-record/old-stock-record.component';
import { firestore } from 'firebase';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { Machine, RawMaterialNeeded, RawMaterialUsed, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { ManageOutsourceTypeComponent } from './manage-outsource-type/manage-outsource-type.component';
import { AddOutsourceComponent } from './PopUpOutsource/add-outsource/add-outsource.component';
import { DetailsOutsourceComponent } from './PopUpOutsource/details-outsource/details-outsource.component';
import { OrderConsumableComponent } from './order-consumable/order-consumable.component';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { OrderOutsourceComponent } from './order-outsource/order-outsource.component';
import { ToastrService } from 'ngx-toastr';
import { UploadExcelDialogComponent } from 'src/app/Shared/upload-excel-dialog/upload-excel-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import { ScanOutsourceComponent } from './scan-outsource/scan-outsource.component';
import { SSL_OP_ALL } from 'constants';
import { ReceivedScanComponent } from './received-scan/received-scan.component';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import JsBarcode from 'jsbarcode';
import qrcode from 'qrcode-generator';
import { DetailOutsourceComponent } from './PO/detail-outsource/detail-outsource.component';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';

const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-raw',
  templateUrl: './raw.component.html',
  styleUrls: ['./raw.component.css']
})
export class RawComponent implements OnInit {

  @ViewChild('crush',{read:CrushingMaterialComponent, static:false}) crush:CrushingMaterialComponent;
  pologs:any[]=[];
  rawlogs :any[]=[];
  crushinglogs :any[]=[];
  stocklist: OldStock[]= [];
  clonePendingListPO=[];
  cloneCompleteListPO=[];
  role:string;
  AccessList: String[] = [];
  /*partNoList1  = [];
  partNoList2 = [];
  amountList = [];*/

  poLength = 10;
  rawLength = 10;
  crushingLength = 10;

  RawDB_controllers: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  SupplierDB_controller: SupplierDB_controller = new SupplierDB_controller(this.db, this.firestore);
  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);
  //machineList: Machine[] = [];
  //machineDB_controller: MachineDB_controller = new MachineDB_controller(this.db);

  //Pagination Raw Materials
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  RawList: RawMaterialInfo[] = [];
  CloneRawList: RawMaterialInfo[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginator: MatPaginator;

  //Pagination Outsourcings
  lengthOut = 0;
  pageSizeOut = 10;
  pageSizeOptionsOut: number[] = [10, 25, 50];
  pageIndexOut = 0;
  offsetOut = this.pageSizeOut * this.pageIndexOut;
  searchOut: string;
  sortOut = {
    active: '',
    direction: ''
  };
  OutsourceList: RawMaterialInfo[] = [];
  CloneOutsourceList: RawMaterialInfo[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginatorOut: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginatorOut: MatPaginator;

  //Pagination PO raw materials
  lengthPO = 0;
  lengthTDO = 0;
  pageSizePO = 10;
  pageSizeTDO = 10;
  pageSizeOptionsPO: number[] = [10, 25, 50];
  pageSizeOptionsTDO: number[] = [10, 25, 50];
  pageIndexPO = 0;
  pageIndexTDO = 0;
  offsetPO = this.pageSizePO * this.pageIndexPO;
  offsetTDO = this.pageSizeTDO * this.pageIndexTDO;
  searchPO: string;
  searchTDO:string;
  sorteduPO = {
    active: '',
    direction: ''
  };
  sortedTDO = {
    active: '',
    direction: ''
  };
  RawListPO: PurchaseOrderRaw[] = [];
  RawListTDO: PurchaseOrderRaw[] = [];
  PendingListPO: PurchaseOrderRaw[] = [];
  CompleteListPO: PurchaseOrderRaw[] = [];
  CloneRawListPO: PurchaseOrderRaw[] = [];
  CloneRawListTDO: PurchaseOrderRaw[] = [];
  PODB_controller: PODB_controller = new PODB_controller(this.db);
  JOList: PurchaseOrderRaw[] = [];
  CloneJOList: PartTracker[] = [];
  @ViewChild('topPaginatorPO', { read: MatPaginator, static: false }) topPaginatorPO: MatPaginator;
  @ViewChild('topPaginatorTDO', { read: MatPaginator, static: false }) topPaginatorTDO: MatPaginator;
  @ViewChild('bottomPaginatorPO', { read: MatPaginator, static: false }) bottomPaginatorPO: MatPaginator;
  @ViewChild('bottomPaginatorTDO', { read: MatPaginator, static: false }) bottomPaginatorTDO: MatPaginator;

  //Pagination supplier
  lengthSup = 0;
  pageSizeSup  = 10;
  pageSizeOptionsSup : number[] = [10, 25, 50];
  pageIndexSup  = 0;
  offsetSup  = this.pageSizeSup  * this.pageIndexSup ;
  searchSup : string;
  sorteduSup  = {
    active: '',
    direction: ''
  };
  Supplier: Supplier[] = [];
  CloneSupplier: Supplier[] = [];
  @ViewChild('topPaginatorSup', { read: MatPaginator, static: false }) topPaginatorSup : MatPaginator;
  @ViewChild('bottomPaginatorSup', { read: MatPaginator, static: false }) bottomPaginatorSup : MatPaginator;
  datePicker: Date
  FromPicker: Date

  monthDate: Date;
  firstDayMonth:Date;
  LastDayMonth:Date;
  pageSizePending = 3;
  pageIndexPending = 0;
  offsetPending = this.pageSizePending * this.pageIndexPending;
  sorteduPending = {
    active: '',
    direction: ''
  };
  pageSizeCompleted = 3;
  pageIndexCompleted = 0;
  offsetCompleted = this.pageSizeCompleted * this.pageIndexCompleted;
  sorteduCompleted = {
    active: '',
    direction: ''
  };

  email: string;
  crushingMaterialLength = 0;
  pieChart: Chart;
  arrayBuffer:any;
  file:File;

  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private excelHelper: ExcelHelperService,
    private toast: ToastrService,
    private userinfoSrv:UserInfoService,
    private dateFormat: DateFormatService
  ) {
    this.monthDate= new Date();
    var totalDay = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, 0).getDate();
    const temp = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth(), 1);
    this.firstDayMonth = new Date(temp);
    this.firstDayMonth.setHours(0)
    this.firstDayMonth.setMinutes(0)
    this.firstDayMonth.setSeconds(0)
    this.LastDayMonth = new Date(temp.setDate(temp.getDate()+totalDay-1));
    this.LastDayMonth.setHours(23)
    this.LastDayMonth.setMinutes(59)
    this.LastDayMonth.setSeconds(59)

    this.datePicker = new Date();
    this.FromPicker = new Date(new Date().setMonth(this.datePicker.getMonth()-1));

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role;
    });
    this.userinfoSrv.currentSubmodules.subscribe(role=>{
      this.AccessList = role;
    });
    this.setup();
  }
  
  ngAfterViewInit(): void {

    this.pieChart = new Chart('pieChart', {
      plugins: [ChartDataLabels],
      type: 'pie',
      data: {
        labels: ["Pending PO", "Completed PO", "No PO"],
        datasets: [{
          backgroundColor: ['#FCCF1C', '#2AF560', '#8d8d8d', '#f99600', '#00ffc4', '#ff2300', '#e600ff', '#d3ff00'],
          data: [0, 0, 1],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(ctx.chart.data.labels[ctx.dataIndex] === 'No PO')
                return "";
              if(value === 0)
                return "";
              return value;
            },
            color: '#000',
            font: {
              weight: 'bold',
              size: 16,
            }
          }
        }
      }
    })
  }

  setup() {
    this.spinner.show();
    this.initPO();
    this.initTDO();
    this.initSup();
    this.initOldStock();
    this.initMachine();
  }
  
  getLength(length) {
    this.crushingMaterialLength = length
  }

  getDashboard(){
    this.PendingListPO = this.RawListPO.filter(r=>r.Status === 'Pending' &&
    r.Created_Date.getTime() >= this.firstDayMonth.getTime() &&
    r.Created_Date.getTime()<= this.LastDayMonth.getTime());
    this.CompleteListPO = this.RawListPO.filter(r=>r.Status === 'Completed'&&
    r.Created_Date.getTime() >= this.firstDayMonth.getTime() &&
    r.Created_Date.getTime()<= this.LastDayMonth.getTime());
    this.clonePendingListPO = this.PendingListPO.slice();
    this.cloneCompleteListPO = this.CompleteListPO.slice();

    if(this.PendingListPO.length > 0 || this.CompleteListPO.length > 0){
      this.pieChart.data.datasets[0].data[2] = 0;
    }else{
      this.pieChart.data.datasets[0].data[2] = 1;
    }
    this.pieChart.data.datasets[0].data[0] = this.PendingListPO.length;
    this.pieChart.data.datasets[0].data[1] = this.CompleteListPO.length;
    this.pieChart.update();

    this.limitCompleted();
    this.limitPending();

  }

  detectChanges(event) {
    this.monthDate = event;
    var totalDay = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, 0).getDate();
    const temp = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth(), 1);
    this.firstDayMonth = new Date(temp);
    this.firstDayMonth.setHours(0)
    this.firstDayMonth.setMinutes(0)
    this.firstDayMonth.setSeconds(0)
    this.LastDayMonth = new Date(temp.setDate(temp.getDate()+totalDay-1));
    this.LastDayMonth.setHours(23)
    this.LastDayMonth.setMinutes(59)
    this.LastDayMonth.setSeconds(59)
    this.getDashboard();
  }

  refresh(type){
    switch(type){
      case 'raw': this.initPO();this.initOldStock(); break;
      case 'po': this.initPO(); break;
      case 'sup': this.initSup(); break;
      case 'crush': this.crush.refresh(); break;
      case 'monitor': this.initMachine(); break;
      case 'tdo': this.initTDO(); break;
    }
  }

  initRaw(){
    this.RawDB_controllers.getRawList().then(data => {
      this.RawList = data;
      for (const raw of this.RawList) {
        this.POStockLocation(raw);
      }
      this.RawList = this.RawList.sort((a, b) => {
        return this.compareDate(a.Last_Updated, b.Last_Updated, false);
      });

      this.length = this.RawList.length;
      this.CloneRawList = this.RawList.slice();
      this.limitList();
      this.spinner.hide();
    });
  }

  initOutsource(){
    this.RawDB_controllers.getOutsourceList().then(data => {
      this.OutsourceList = data;
      this.OutsourceList = this.OutsourceList.sort((a, b) => {
        return this.compareDate(a.Last_Updated, b.Last_Updated, false);
      });
      this.lengthOut = this.OutsourceList.length;
      this.CloneOutsourceList = this.OutsourceList.slice();
      this.limitOutsourceList();
      this.spinner.hide();
    });
  }

  checkStock(raw: RawMaterialInfo){
    const result = this.stocklist.find(s=>s.MaterialID === raw.Material_ID);
    if(result){
      return true;
    }else{
      return false;
    }
  }

  checkPOStock(raw: RawMaterialInfo){
    const result = this.RawListPO.find(s=>{
      const r = s.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID);
      if(r)
        return s;
      else
        return null;
    });
    if(result){
      return true;
    }else{
      return false;
    }
  }

  POStockLocation(raw:RawMaterialInfo){
    const result = this.RawListPO.filter(s=>{
      const r = s.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID);
      if(r)
        return r;
    });
    let temp =[]
    raw.POlocation = '';
    result.forEach(e=>{
      const r = e.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID);
      r.ReceivedDetailsList.forEach(detail=>{
        const exist = temp.findIndex(t=>t===detail.Rack_Number);
        if(exist === -1){
          raw.POlocation += detail.Rack_Number + '|';
          temp.push(detail.Rack_Number);
        }
      })
    })
    return temp;
  }

  viewPOStock(raw: RawMaterialInfo){
    const result = this.RawListPO.filter(s=>{
      const r = s.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID);
      if(r)
        return r;
    });

    const info = [];

    result.forEach(e=>{

      const i = {
        PONumber: e.PO_No,
        RawMaterial:e.PO_RawMaterials.find(e=>e.PO_RawMaterial_ID === raw.Material_ID)
      }
      info.push(i);


    })

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '90%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = info;
    this.dialog.open(PoStockRecordComponent, dialogConfig);
  }

  OldStockLocation(raw: RawMaterialInfo){
    const result = this.stocklist.filter(s=>s.MaterialID === raw.Material_ID);

    let location = ""
    if(result.length > 0){
      let temp =[]
      result.forEach((e,index)=>{

        const exist = temp.findIndex(t=>t=== e.Location);
        if(exist === -1 && e.Location){
          if(!location){
            location = "Old Stock: ";
          }
          temp.push(e.Location);
          location += e.Location + "|"
        }
      })
    }
    return location.slice(0,-1)
  }

  viewOldStock(raw: RawMaterialInfo){
    const result = this.stocklist.filter(s=>s.MaterialID === raw.Material_ID).slice();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '90%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = result;
    this.dialog.open(OldStockRecordComponent, dialogConfig);
  }

  initOldStock(){
    this.RawDB_controllers.getOldStock().then(data => {
      this.stocklist = data;
      this.stocklist.sort
      this.stocklist = this.stocklist.sort((a, b) => {
        return this.compareDate(a._Created_Date, b._Created_Date, false);
      });
    });
  }

  initPO(){
    this.PORawController.getPORawMaterialList().then(data => {
      this.RawListPO = data;
      this.RawListPO = this.RawListPO.sort((a, b) => {
        return this.compareDate(a.Updated_Date, b.Updated_Date, false);
      });
      this.lengthPO = this.RawListPO.length;
      this.CloneRawListPO = this.RawListPO.slice();
      this.limitListPO();
      this.spinner.hide();
    }).finally(()=>{
      this.initRaw();
      this.initOutsource();
    });
  }

  initTDO(){
    this.PORawController.getTDOlList().then(data => {
      this.RawListTDO = data;
      this.RawListTDO = this.RawListTDO.sort((a, b) => {
        return this.compareDate(a.Updated_Date, b.Updated_Date, false);
      });
      this.lengthTDO = this.RawListTDO.length;
      this.CloneRawListTDO = this.RawListTDO.slice();
      this.limitListTDO();
      this.spinner.hide();
    }).finally(()=>{
      this.initRaw();
      this.initOutsource();
    });
  }

  initSup(){
    this.SupplierDB_controller.getSupplierList().then(data => {
      this.Supplier = data;
      this.Supplier = this.Supplier.sort((a, b) => {
        return this.compareDate(a.Last_Updated, b.Last_Updated, false);
      });
      this.lengthSup = this.Supplier.length;
      this.CloneSupplier = this.Supplier.slice();
      this.limitListSup();
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.getPOLog();
    this.getRawLog();
    this.getCrushingLog();
  }

  getPOLog(){
    this.pologs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);

    this.firestore.collection('PORawMaterialLog',
    ref => ref.where('Date','>=',fromdate)
            .where('Date','<=',toDate)
            .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
      data.forEach(e => {
        if(this.pologs.length > 10){
          return;
        }
        this.firestore.collection('PORawMaterialLog').doc(e.id).
        collection('PO', ref => ref.orderBy('date', 'desc').limit(10)).get().forEach(poLog => {
          poLog.forEach(log => {
            if(this.pologs.length > 10){
              return;
            }
            const info ={
              ...log.data()
            }
            this.pologs.push(info);
          });
        });
      });
    });
  }
  
  getRawLog(){
    this.rawlogs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('RawMaterialLog',
    ref => ref.where('Date','>=',fromdate)
    .where('Date','<=',toDate)
    .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
      data.forEach(e => {
        if(this.rawlogs.length > 10){
          return;
        }
        this.firestore.collection('RawMaterialLog').doc(e.id).
        collection('Raw', ref => ref.orderBy('date', 'desc').limit(10)).get().forEach(rawlog => {
          rawlog.forEach(log => {
            if(this.rawlogs.length > 10){
              return;
            }
            const info ={
              ...log.data()
            }
            this.rawlogs.push(info);
          });
        });
      });
    });
  }

  getCrushingLog(){
    this.crushinglogs = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('CrushingMaterialUpdateLog',
    ref => ref.where('Date','>=',fromdate)
    .where('Date','<=',toDate)
    .orderBy('Date', 'desc').limit(10)).get().forEach(data => {
      data.forEach(e => {
        if(this.crushinglogs.length > 10){
          return;
        }
        this.firestore.collection('CrushingMaterialUpdateLog').doc(e.id).
        collection('Raw', ref => ref.orderBy('Date', 'desc').limit(10)).get().forEach(rawlog => {
          rawlog.forEach(log => {
            if(this.crushinglogs.length > 10){
              return;
            }
            const info ={
              date: new Date(log.data().Date),
              name: log.data()['Raw Material Name'],
              original: log.data().Stock - log.data().Quantity,
              current:log.data().Stock,
              updatedBy: log.data()['Update by']
            }
            this.crushinglogs.push(info);
          });
        });
      });
    });
  }

  DynamicSearchRaw(rawName: string): void {
    this.search = rawName;
    this.CloneRawList = this.RawList.filter(u =>
      String(u.Material_Name).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Raw_Type).toLowerCase().includes(this.search.toLowerCase())
      || String(u.location).toLowerCase().includes(this.search.toLowerCase())
      || String(u.POlocation).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.CloneRawList.length;
    this.sortRawList();
    this.limitList();
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
  }

  DynamicSearchOutsource(rawName: string): void {
    this.search = rawName;
    this.CloneOutsourceList = this.OutsourceList.filter(u =>
      String(u.Material_Name).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Raw_Type).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.CloneRawList.length;
    this.sortOutsourceList();
    this.limitOutsourceList();
    this.topPaginatorOut.firstPage();
    this.bottomPaginatorOut.firstPage();
  }

  DS() {
    this.CloneRawList = this.RawList.filter(u =>
      String(u.Material_Name).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Raw_Type).toLowerCase().includes(this.search.toLowerCase())
      || String(u.location).toLowerCase().includes(this.search.toLowerCase())
      || String(u.POlocation).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.CloneRawList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;

    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.CloneRawList = this.RawList.slice();
    }
    this.sortRawList();
    this.limitList();
  }

  paginatorOut(pageEvent: PageEvent) {
    this.pageSizeOut = pageEvent.pageSize;
    this.pageIndexOut = pageEvent.pageIndex;
    this.offsetOut = this.pageSizeOut * this.pageIndexOut;

    if (this.topPaginatorOut.pageIndex < this.pageIndexOut) {
      this.topPaginatorOut.nextPage();
    } else if (this.topPaginatorOut.pageIndex > this.pageIndexOut) {
      this.topPaginatorOut.previousPage();
    }
    if (this.bottomPaginatorOut.pageIndex < this.pageIndexOut) {
      this.bottomPaginatorOut.nextPage();
    } else if (this.bottomPaginatorOut.pageIndex > this.pageIndexOut) {
      this.bottomPaginatorOut.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.CloneOutsourceList = this.OutsourceList.slice();
    }
    this.sortOutsourceList();
    this.limitOutsourceList();
  }

  limitList() {
    this.CloneRawList = this.CloneRawList.slice(this.offset, (this.offset + this.pageSize));
  }

  limitOutsourceList() {
    this.CloneOutsourceList = this.CloneOutsourceList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortRawList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.CloneRawList = this.CloneRawList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.Material_Name, b.Material_Name, isAsc);
        case 'category': return this.compare(a.Material_Type, b.Material_Type, isAsc);
        case 'grade': return this.compare(a.Material_Grade, b.Material_Grade, isAsc);
        case 'type': return this.compare(a.Raw_Type, b.Raw_Type, isAsc);
        case 'stock': return this.compare(a.In_Stock, b.In_Stock, isAsc);
        default: return 0;
      }
    });
  }

  sortOutsourceList() {
    if (!this.sortOut.active || this.sortOut.direction === '') {
      return;
    }
    this.CloneOutsourceList = this.CloneOutsourceList.sort((a, b) => {
      const isAsc = this.sortOut.direction === 'asc';
      switch (this.sortOut.active) {
        case 'name': return this.compare(a.Material_Name, b.Material_Name, isAsc);
        case 'type': return this.compare(a.Raw_Type, b.Raw_Type, isAsc);
        case 'updatedBy': return this.compare(a.In_Stock, b.In_Stock, isAsc);
        case 'lastUpdate': return this.compare(a.Unit, b.Unit, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'lastUpdate': return this.compareDate(a.Last_Updated, b.Last_Updated, isAsc);
        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.CloneRawList = this.RawList.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.CloneRawList = this.RawList.slice();
      this.limitList();
      return;
    }

    this.CloneRawList = this.CloneRawList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.Material_Name, b.Material_Name, isAsc);
        case 'category': return this.compare(a.Material_Type, b.Material_Type, isAsc);
        case 'grade': return this.compare(a.Material_Grade, b.Material_Grade, isAsc);
        case 'type': return this.compare(a.Raw_Type, b.Raw_Type, isAsc);
        case 'stock': return this.compare(a.In_Stock, b.In_Stock, isAsc);
        default: return 0;
      }
    });

    this.limitList();
  }

  sortDataOut(sort: Sort) {
    this.sortOut = sort;
    this.CloneOutsourceList = this.OutsourceList.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.CloneOutsourceList = this.OutsourceList.slice();
      this.limitList();
      return;
    }

    this.CloneOutsourceList = this.CloneOutsourceList.sort((a, b) => {
      const isAsc = this.sortOut.direction === 'asc';
      switch (this.sortOut.active) {
        case 'name': return this.compare(a.Material_Name, b.Material_Name, isAsc);
        case 'type': return this.compare(a.Raw_Type, b.Raw_Type, isAsc);
        case 'updatedBy': return this.compare(a.In_Stock, b.In_Stock, isAsc);
        case 'lastUpdate': return this.compare(a.Unit, b.Unit, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'lastUpdate': return this.compareDate(a.Last_Updated, b.Last_Updated, isAsc);
        default: return 0;
      }
    });

    this.limitOutsourceList();
  }

  DynamicSearchRawPO(POname: string): void {
    this.searchPO = POname;
    this.CloneRawListPO = this.RawListPO.filter(u =>
      String(u.PO_No).toLowerCase().includes(this.searchPO.toLowerCase()));
    this.lengthPO = this.CloneRawListPO.length;
    this.sortRawListPO();
    this.limitListPO();
    this.topPaginatorPO.firstPage();
    this.bottomPaginatorPO.firstPage();
  }

  DynamicSearchRawTDO(POname: string): void {
    this.searchTDO = POname;
    this.CloneRawListTDO = this.RawListTDO.filter(u => {
      const flag = String(u.PO_No).toLowerCase().includes(this.searchTDO.toLowerCase()) || String(u.Supplier).toLowerCase().includes(this.searchTDO.toLowerCase()) || String(u.Status).toLowerCase().includes(this.searchTDO.toLowerCase()) || String(u.SO_No).toLowerCase().includes(this.searchTDO.toLowerCase());

      for (const part of u.PO_RawMaterials) {
        if(part.Part_No && part.Part_Name){
          if((part.Part_No.toLowerCase().includes(this.searchTDO.toLowerCase())|| (part.Part_Name.toLowerCase().includes(this.searchTDO.toLowerCase())) || (String(part.PO_RawMaterial_Status).toLowerCase().includes(this.searchTDO.toLowerCase()))))
            return true;
        }
      }

      return flag;
    });

    this.lengthTDO = this.CloneRawListTDO.length;
    this.sortRawListTDO();
    this.limitListTDO();
    this.topPaginatorTDO.firstPage();
    this.bottomPaginatorTDO.firstPage();
  }

  DSPO() {
    this.CloneRawListPO = this.RawListPO.filter(u =>
      String(u.PO_No).toLowerCase().includes(this.searchPO.toLowerCase()));
    this.lengthPO = this.CloneRawListPO.length;
  }

  DSTDO() {
    this.CloneRawListTDO = this.RawListTDO.filter(u => String(u.PO_No).toLowerCase().includes(this.searchTDO.toLowerCase()));
    this.lengthTDO = this.CloneRawListTDO.length;
  }

  paginatorPO(pageEvent: PageEvent) {
    this.pageSizePO = pageEvent.pageSize;
    this.pageIndexPO = pageEvent.pageIndex;
    this.offsetPO = this.pageSizePO * this.pageIndexPO;
    if (this.topPaginatorPO.pageIndex < this.pageIndexPO) {
      this.topPaginatorPO.nextPage();
    } else if (this.topPaginatorPO.pageIndex > this.pageIndexPO) {
      this.topPaginatorPO.previousPage();
    }
    if (this.bottomPaginatorPO.pageIndex < this.pageIndexPO) {
      this.bottomPaginatorPO.nextPage();
    } else if (this.bottomPaginatorPO.pageIndex > this.pageIndexPO) {
      this.bottomPaginatorPO.previousPage();
    }
    if (this.searchPO) {
      this.DSPO();
    } else {
      this.CloneRawListPO = this.RawListPO.slice();
    }
    this.sortRawListPO();
    this.limitListPO();
  }

  paginatorTDO(pageEvent: PageEvent) {
    this.pageSizeTDO = pageEvent.pageSize;
    this.pageIndexTDO = pageEvent.pageIndex;
    this.offsetTDO = this.pageSizeTDO * this.pageIndexTDO;
    if (this.topPaginatorTDO.pageIndex < this.pageIndexTDO) {
      this.topPaginatorTDO.nextPage();
    } else if (this.topPaginatorTDO.pageIndex > this.pageIndexTDO) {
      this.topPaginatorTDO.previousPage();
    }
    if (this.bottomPaginatorTDO.pageIndex < this.pageIndexTDO) {
      this.bottomPaginatorTDO.nextPage();
    } else if (this.bottomPaginatorTDO.pageIndex > this.pageIndexTDO) {
      this.bottomPaginatorTDO.previousPage();
    }
    if (this.searchTDO) {
      this.DSTDO();
    } else {
      this.CloneRawListTDO = this.RawListTDO.slice();
    }
    this.sortRawListTDO();
    this.limitListTDO();
  }

  limitListPO() {
    this.CloneRawListPO = this.CloneRawListPO.slice(this.offsetPO, (this.offsetPO + this.pageSizePO));
  }

  limitListTDO(){
    this.CloneRawListTDO = this.CloneRawListTDO.slice(this.offsetTDO, (this.offsetTDO + this.pageSizeTDO));
  }

  sortRawListPO() {
    if (!this.sorteduPO.active || this.sorteduPO.direction === '') {
      return;
    }
    this.CloneRawListPO = this.CloneRawListPO.sort((a, b) => {
      const isAsc = this.sorteduPO.direction === 'asc';
      switch (this.sorteduPO.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortRawListTDO(){
    if (!this.sortedTDO.active || this.sortedTDO.direction === '') {
      return;
    }
    this.CloneRawListTDO = this.CloneRawListTDO.sort((a, b) => {
      const isAsc = this.sortedTDO.direction === 'asc';
      switch (this.sortedTDO.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortDataPO(sort: Sort) {
    this.sorteduPO = sort;
    this.CloneRawListPO = this.RawListPO.slice();
    if (this.searchPO) {
      this.DSPO();
    }
    if (!sort.active || sort.direction === '' && !this.searchPO) {
      this.CloneRawListPO = this.RawListPO.slice();
      this.limitListPO();
      return;
    }

    this.CloneRawListPO = this.CloneRawListPO.sort((a, b) => {
      const isAsc = this.sorteduPO.direction === 'asc';
      switch (this.sorteduPO.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.PO_RawMaterials[0].PO_RawMaterial_Status, b.PO_RawMaterials[0].PO_RawMaterial_Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
    this.limitListPO();
  }

  sortDataTDO(sort: Sort) {
    this.sortedTDO = sort;
    this.CloneRawListTDO = this.RawListTDO.slice();
    if (this.searchTDO) {
      this.DSTDO();
    }
    if (!sort.active || sort.direction === '' && !this.searchTDO) {
      this.CloneRawListTDO = this.RawListTDO.slice();
      this.limitListTDO();
      return;
    }

    this.CloneRawListTDO = this.CloneRawListTDO.sort((a, b) => {
      const isAsc = this.sortedTDO.direction === 'asc';
      switch (this.sortedTDO.active) {
        case 'TDOno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'TDOstatus': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
    this.limitListPO();
  }

  DynamicSearchRawSup(Supname: string): void {
    this.searchSup = Supname;
    this.CloneSupplier = this.Supplier.filter(u =>
      String(u.Supplier_Name).toLowerCase().includes(this.searchSup.toLowerCase()));
    this.lengthSup = this.CloneSupplier.length;
    this.sortRawListSup();
    this.limitListSup();
    this.topPaginatorSup.firstPage();
    this.bottomPaginatorSup.firstPage();
  }

  DSSup() {
    this.CloneSupplier = this.Supplier.filter(u =>
      String(u.Supplier_Name).toLowerCase().includes(this.searchSup.toLowerCase()));
    this.lengthSup = this.CloneSupplier.length;
  }

  paginatorSup(pageEvent: PageEvent) {
    this.pageSizeSup = pageEvent.pageSize;
    this.pageIndexSup = pageEvent.pageIndex;
    this.offsetSup = this.pageSizeSup * this.pageIndexSup;
    if (this.topPaginatorSup.pageIndex < this.pageIndexSup) {
      this.topPaginatorSup.nextPage();
    } else if (this.topPaginatorSup.pageIndex > this.pageIndexSup) {
      this.topPaginatorSup.previousPage();
    }
    if (this.bottomPaginatorSup.pageIndex < this.pageIndexSup) {
      this.bottomPaginatorSup.nextPage();
    } else if (this.bottomPaginatorSup.pageIndex > this.pageIndexSup) {
      this.bottomPaginatorSup.previousPage();
    }
    if (this.searchSup) {
      this.DSSup();
    } else {
      this.CloneSupplier = this.Supplier.slice();
    }
    this.sortRawListSup();
    this.limitListSup();
  }

  limitListSup() {
    this.CloneSupplier = this.CloneSupplier.slice(this.offsetSup, (this.offsetSup + this.pageSizeSup));
  }

  sortRawListSup() {
    if (!this.sorteduSup.active || this.sorteduSup.direction === '') {
      return;
    }
    this.CloneSupplier = this.CloneSupplier.sort((a, b) => {
      const isAsc = this.sorteduSup.direction === 'asc';
      switch (this.sorteduSup.active) {
        case 'name': return this.compare(a.Supplier_Name, b.Supplier_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
  }

  sortDataSup(sort: Sort) {
    this.sorteduSup = sort;
    this.CloneSupplier = this.Supplier.slice();
    if (this.searchSup) {
      this.DSSup();
    }
    if (!sort.active || sort.direction === '' && !this.searchSup) {
      this.CloneSupplier = this.Supplier.slice();
      this.limitListSup();
      return;
    }

    this.CloneSupplier = this.CloneSupplier.sort((a, b) => {
      const isAsc = this.sorteduSup.direction === 'asc';
      switch (this.sorteduSup.active) {
        case 'name': return this.compare(a.Supplier_Name, b.Supplier_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
    this.limitListSup();
  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewDetail(raw){
    this.dialog.open(DetailsRawComponent, {
      width: '70%',
      height: 'auto',
      disableClose: true,
      data: raw
    }).afterClosed().subscribe(r=>{
      if(r){
        this.RawDB_controllers.update_Raw(r,this.email);
        this.setup();
      }
    });
  }

  viewOutsourceDetail(raw){
    this.dialog.open(DetailsOutsourceComponent, {
      width: '70%',
      height: 'auto',
      disableClose: true,
      data: raw
    }).afterClosed().subscribe(r=>{
      if(r){
        this.RawDB_controllers.update_Outsource(r,this.email);
        this.setup();
      }
    });
  }

  viewDetailPO(po){
    this.dialog.open(DetailPOComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  viewDetailTDO(po){
    this.dialog.open(DetailOutsourceComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  viewOrder(po){
    this.dialog.open(OrdersComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  AddRaw(){
    const dialogRefaddPart = this.dialog.open(AddRawComponent, {
      width: '50%',
      height: '80%',
      disableClose: true,
    });
    var newRaw = new RawMaterialInfo();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        newRaw = result;
        if (!newRaw.In_Stock){
          newRaw.In_Stock = "0";
        }
        this.RawDB_controllers.add_Raw(newRaw, this.email);
        this.setup();

      }
    });
  }
  
  AddOrderOutsource(machine){
    const dialogRefaddPart = this.dialog.open(OrderOutsourceComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: machine
    });
    var newRaw = new RawMaterialInfo();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        //newRaw = result;
        //this.RawDB_controllers.add_Outsource(newRaw, this.email);
        this.setup();

      }
    });
  }

  AddOutsource(){
    const dialogRefaddPart = this.dialog.open(AddOutsourceComponent, {
      width: '50%',
      height: '80%',
      disableClose: true,
    });
    var newRaw = new RawMaterialInfo();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        newRaw = result;
        /*if (!newRaw.In_Stock){
          newRaw.In_Stock = "0";
        }*/
        this.RawDB_controllers.add_Outsource(newRaw, this.email);
        this.setup();

      }
    });
  }

  OrderDialog(){
    const dialogRefaddPart = this.dialog.open(OrderRawMaterialComponent, {
      width: '90%',
      height: '90%',
    });
    dialogRefaddPart.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }

  OrderDialogDirect(machine){
    const dialogRefaddPart = this.dialog.open(OrderRawMaterialComponent, {
      width: '90%',
      height: '90%',
      data: machine
    });
    dialogRefaddPart.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }

  StartProcess(machine: PartTracker){
    const addQCModel = {
      PONo: machine.SO_No,
      JONo: machine.JO_No,
      PartName: machine.PO_Part_Name,
      PartNo: machine.PO_Part_No,
      Quantity: machine.POQuantity,
      JOStatus: 'Planned Material',
      DueDate: machine.EndDate,
      rawMaterials: []
    };

    machine.Raw_Material.forEach(data => {
      const info = {
        rawMatId: data.Material_ID,
        rawMatName: data.Part_Material_Name,
        rawMatStock: data.In_Stock,
        rawMatNeeded: data.quantityNeeded,
      };

      if(parseFloat(data.In_Stock) < parseFloat(data.quantityNeeded)){
        //this.toast.error('Material is not enough', 'Please Add Materials');
        return;
      }

      addQCModel.rawMaterials.push(info);
    });

    if (addQCModel.rawMaterials.length === 0) {
      this.toast.error('Material is not enough', 'Please Add Materials');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm done material planning';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PODB_controller.updateJOWithoutSchedule(addQCModel,this.email);
        this.initMachine();
      }
    });
  }

  ScanOutsource(){
    const dialogRefaddPart = this.dialog.open(ScanOutsourceComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
    dialogRefaddPart.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }

  ReceiveOutsource(){
    const dialogRef = this.dialog.open(ReceivedScanComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }

  OrderOutsource(){
    const dialogRefaddPart = this.dialog.open(OrderOutsourceComponent, {
      width: '90%',
      height: '90%',
    });
    dialogRefaddPart.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }

  OrderConsumable(){
    const dialogRefaddPart = this.dialog.open(OrderConsumableComponent, {
      width: '90%',
      height: '90%',
    });
    dialogRefaddPart.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }
  
  /*ReceiveDialog(){
    const dialogRef = this.dialog.open(ReceivedOrderComponent, {
      width: '80%',
      height: '65%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }*/

  editPO(po: PurchaseOrderRaw){
    const dialogRef = this.dialog.open(ReceivedOrderComponent, {
      width: '80%',
      height: '65%',
      data: po
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }

  editTDO(po: PurchaseOrderRaw){
    const dialogRef = this.dialog.open(ReceivedOrderComponent, {
      width: '80%',
      height: '65%',
      data: po
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }

  async printTDO(machine: PurchaseOrderRaw){
    //this.spinner.show();
    let sup = this.Supplier.find(a=>a.Supplier_Name == machine.Supplier);
    var total = 0;
    var count = 1;
    var partNoList1 = [];
    var partNoList2 = [];
    var partNoList3 = [];
    var amountList =[];

    $("#forPrintTDO2").find("#nameForm").text(machine.Supplier);
    $("#forPrintTDO2").find("#address1Form").text(sup.Address);
    $("#forPrintTDO2").find("#tdoNoForm").text("TDO No: " + machine.PO_No);
    $("#forPrintTDO2").find("#address2Form").text(sup.Address2);
    $("#forPrintTDO2").find("#tdoDateForm").text("TDO Date: " + this.dateFormat.convertDateIntoDayMonthYearWithDash(machine.Pr_Date));
    $("#forPrintTDO2").find("#address3Form").text(sup.Address3);
    $("#forPrintTDO2").find("#requiredDateForm").text("Required Date: " + this.dateFormat.convertDateIntoDayMonthYearWithDash(machine.PO_RawMaterials[0].Delivery_Date));
    $("#forPrintTDO2").find("#address4Form").text(sup.Address4);
    $("#forPrintTDO2").find("#telNoForm").text("Tel: " + sup.Contact + "  Fax: " + sup.Fax);
    
    // Sort JO
    await machine.PO_RawMaterials.sort(function(a, b) {          
      if (a.PO_RawMaterial_Name === b.PO_RawMaterial_Name) {
        return a.Part_Name > b.Part_Name ? 1 : -1;
      }
      return a.PO_RawMaterial_Name > b.PO_RawMaterial_Name ? 1 : -1;
    });

    // Consolidate JO
    await machine.PO_RawMaterials.forEach(data=>{
      if(!partNoList1.includes(data.Part_Name)){
        partNoList1.push(data.Part_Name);
        amountList.push(data.PO_RawMaterial_Qty);
        partNoList3.push(data);
      }
      else{
        var index = partNoList1.indexOf(data.Part_Name);
        amountList[index] = parseInt(amountList[index]) + parseInt(data.PO_RawMaterial_Qty.toString());
        partNoList3[index].PO_RawMaterial_Qty = amountList[index];
      }
    });

    // See if it more than 8
    if(partNoList3.length > 8){
      var pages = Math.ceil(partNoList3.length / 8);

      //setTimeout(async function(){
        //this.spinner.hide();
        var divContents = $("#forPrintTDO1").html();
        var divContents3 = $("#forPrintTDO3").html();
        var printWindow = window.open('', '', 'height=800,width=800');
        printWindow.document.write('<html><head><title>Test Print</title>')
        printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 2px solid #dee2e6;} .table-bordered {border: 2px solid #000000;} .table-bordered th, .table-bordered td {border: 2px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>');
        
        for(var i=0; i<pages; i++){
          var p1 = partNoList3.slice((i * 8), ((i * 8) + 8));
          var counter = 0;
          $("#forPrintTDO2").find("#pagesForm").text("Page: " + (i + 1).toString() + " of " + pages.toString());
          var divContents2 = $("#forPrintTDO2").html();

          if(i > 0){
            printWindow.document.write('</tbody><tfoot><tr><td colspan="3" style="border-top: 1px solid black;"></td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;"></td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;text-align:center;"></td><td style="border-top: 1px solid black;"></td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;"></td></tr></tfoot></table>');
            printWindow.document.write('<p style="page-break-after:always;"></p>');
          }

          printWindow.document.write(divContents);
          printWindow.document.write(divContents2);
          printWindow.document.write('<table class="table"><thead><tr><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;width: 25px;">Item#</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;">JO Number</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;">Part Number</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;width: 100px;">Outsource name</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;width: 100px;">Remark</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;width: 20px;">Qty</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;"></th></tr></thead><tbody>');

          await p1.forEach(data => {
            total += parseInt(data.PO_RawMaterial_Qty.toString());
            var qr = qrcode(0, 'L');
            qr.addData(data.JO_No);
            qr.make();
            $('#barcode').html(qr.createImgTag());
  
            var divContents4 = $("#forPrintTDO4").html();
            //var index = partNoList1.indexOf(data.Part_Name);
            printWindow.document.write('<tr><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+count+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+data.JO_No+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+data.Part_Name+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+data.PO_RawMaterial_Name+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+data.Remark+'</td><td style="border-top: 0;text-align:center;font-size: 10px;font-family: sans-serif;">'+data.PO_RawMaterial_Qty+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;>'+divContents4+'</td></tr>');
            //partNoList2.push(data.Part_Name);
            count++;
            counter++;
          });
        }

        printWindow.document.write('</tbody><tfoot><tr><td colspan="4" style="border-top: 1px solid black;"></td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;">Total Quantity:</td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;text-align:center;" id="totalQtyForm">'+ total +'</td><td style="border-top: 1px solid black;"></td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;"></td></tr></tfoot></table>');
        printWindow.document.write(divContents3);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        //this.spinner.hide();
        setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 1000);
      //}, 1000);
    }
    else{
      $("#forPrintTDO2").find("#pagesForm").text("Page: 1 of 1");

      setTimeout(async function(){
        //this.spinner.hide();
        var divContents = $("#forPrintTDO1").html();
        var divContents2 = $("#forPrintTDO2").html();
        var divContents3 = $("#forPrintTDO3").html();
        var printWindow = window.open('', '', 'height=800,width=800');
        printWindow.document.write('<html><head><title>Test Print</title>')
        printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 2px solid #dee2e6;} .table-bordered {border: 2px solid #000000;} .table-bordered th, .table-bordered td {border: 2px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>');
        printWindow.document.write(divContents);
        printWindow.document.write(divContents2);
        printWindow.document.write('<table class="table"><thead><tr><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;width: 25px;">Item#</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;">JO Number</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;">Part Number</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;width: 100px;">Outsource name</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;width: 100px;">Remark</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;width: 20px;">Qty</th><th style="border-top: 1px solid black;border-bottom: 1px solid black;font-size: 10px;font-family: sans-serif;"></th></tr></thead><tbody>');
        
        await partNoList3.forEach(data => {
          total += parseInt(data.PO_RawMaterial_Qty.toString());
          
          if(!partNoList2.includes(data.Part_Name)){
            /*JsBarcode("#barcode", data.JO_No, {
              height:25,
              width:1,
              displayValue: false
            });*/
  
            var qr = qrcode(0, 'L');
            qr.addData(data.JO_No);
            qr.make();
            $('#barcode').html(qr.createImgTag());
  
            var divContents4 = $("#forPrintTDO4").html();
            var index = partNoList1.indexOf(data.Part_Name);
            printWindow.document.write('<tr><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+count+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+data.JO_No+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+data.Part_Name+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+data.PO_RawMaterial_Name+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;">'+data.Remark+'</td><td style="border-top: 0;text-align:center;font-size: 10px;font-family: sans-serif;">'+data.PO_RawMaterial_Qty+'</td><td style="border-top: 0;font-size: 10px;font-family: sans-serif;text-align:center;>'+divContents4+'</td></tr>');
            partNoList2.push(data.Part_Name);
            count++;
          }
        });
  
        printWindow.document.write('</tbody><tfoot><tr><td colspan="4" style="border-top: 1px solid black;"></td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;">Total Quantity:</td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;text-align:center;" id="totalQtyForm">'+ total +'</td><td style="border-top: 1px solid black;"></td><td style="border-top: 1px solid black;font-size: 10px;font-family: sans-serif;"></td></tr></tfoot></table>');
        printWindow.document.write(divContents3);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        //this.spinner.hide();
        setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 500);
      }, 500);
    }

    this.initTDO();
  }

  GenerateQRCode(){
    const dialogRef = this.dialog.open(GenerateQRCodeComponent, {
      width: '80%',
      height: '80%',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
        this.getPOLog();
        this.getRawLog();
      }
    });
  }

  AddSupplier(){
    const dialogRef = this.dialog.open(AddSupplierComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.SupplierDB_controller.add_Supplier(result, this.email);
        this.setup();
      }
    });
  }

  ViewSupplier(supplier: Supplier){
    const dialogRef = this.dialog.open(AddSupplierComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: {...supplier}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.SupplierDB_controller.update_Supplier(result, this.email);
        this.setup();
      }
    });
  }

  deletePO(po: PurchaseOrderRaw){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this po? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PORawController.delete_PO(po.PO_No);
        this.setup();
      }
    })
  }

  deleteTDO(po: PurchaseOrderRaw){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this tdo? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PORawController.delete_TDO(po.PO_No);
        this.setup();
      }
    })
  }

  deleteRaw(raw : RawMaterialInfo){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this raw material? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.RawDB_controllers.delete_Raw(raw.Material_ID, raw.Material_Name, this.email);
        this.setup();
      }
    })
  }

  deleteOutsource(raw : RawMaterialInfo){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this raw material? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.RawDB_controllers.delete_Outsource(raw.Material_ID, raw.Material_Name, this.email);
        this.setup();
      }
    })
  }

  deleteSup(sup : Supplier){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this supplier? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.SupplierDB_controller.delete_Supplier(sup.Supplier_ID);
        this.setup();
      }
    })
  }

  ManageRawType(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(ManageRawTypeComponent, dialogConfig);
  }

  ManageOutsourceType(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {top: '5%'};
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(ManageOutsourceTypeComponent, dialogConfig);
  }

  GenerataOldQRCode(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.stocklist.slice();
    this.dialog.open(GenerateOldQRCodeComponent, dialogConfig).afterClosed().subscribe(result=>{
      if(result){
        this.setup();
      }
    });
  }

  GetActivity(value){
    this.rawLength = value;
    this.getRawLog();
  }

  GetActivity2(value){
    this.poLength = value;
    this.getPOLog();
  }

  GetActivity3(value){
    this.crushingLength = value;
    this.getCrushingLog();
  }

  searchLog(){
    this.getRawLog();
    this.getPOLog();
    this.getCrushingLog();

  }

  limitPending(){
    this.clonePendingListPO = this.PendingListPO.slice(this.offsetPending, (this.offsetPending + this.pageSizePending));
  }

  limitCompleted(){
    this.cloneCompleteListPO = this.CompleteListPO.slice(this.offsetCompleted, (this.offsetCompleted + this.pageSizeCompleted));
  }

  paginatorPending(pageEvent: PageEvent) {
    this.pageSizePending = pageEvent.pageSize;
    this.pageIndexPending = pageEvent.pageIndex;
    this.offsetPending = this.pageSizePending * this.pageIndexPending;
    this.sortPending();
    this.limitPending();
  }

  paginatorCompleted(pageEvent:PageEvent){
    this.pageSizeCompleted = pageEvent.pageSize;
    this.pageIndexCompleted = pageEvent.pageIndex;
    this.offsetCompleted = this.pageSizeCompleted * this.pageIndexCompleted;
    this.sortCompleted();
    this.limitCompleted();
  }

  sortPending() {
    if (!this.sorteduPending.active || this.sorteduPending.direction === '') {
      return;
    }
    this.clonePendingListPO = this.PendingListPO.sort((a, b) => {
      const isAsc = this.sorteduPending.direction === 'asc';
      switch (this.sorteduPending.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortCompleted() {
    if (!this.sorteduCompleted.active || this.sorteduCompleted.direction === '') {
      return;
    }
    this.cloneCompleteListPO = this.CompleteListPO.sort((a, b) => {
      const isAsc = this.sorteduCompleted.direction === 'asc';
      switch (this.sorteduCompleted.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortDataPending(sort: Sort) {
    this.sorteduPending = sort;
    this.clonePendingListPO = this.PendingListPO.slice();

    if (!sort.active || sort.direction === '' ) {
      this.clonePendingListPO = this.PendingListPO.slice();
      this.limitPending();
      return;
    }
    this.clonePendingListPO = this.PendingListPO.sort((a, b) => {
      const isAsc = this.sorteduPending.direction === 'asc';
      switch (this.sorteduPending.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
    this.limitPending();
  }

  sortDataCompleted(sort: Sort) {
    this.sorteduCompleted = sort;
    this.cloneCompleteListPO = this.CompleteListPO.slice();

    if (!sort.active || sort.direction === '' ) {
      this.cloneCompleteListPO = this.CompleteListPO.slice();
      this.limitCompleted();
      return;
    }
    this.cloneCompleteListPO = this.CompleteListPO.sort((a, b) => {
      const isAsc = this.sorteduCompleted.direction === 'asc';
      switch (this.sorteduCompleted.active) {
        case 'POno': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'status': return this.compare(a.Status, b.Status, isAsc);
        case 'createdDate': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        case 'createdBy': return this.compare(a.Created_By, b.Created_By, isAsc);
        case 'updatedBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'UpdateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
    this.limitCompleted();
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const rawlist = this.RawList.sort((a,b)=>{
      return (a.Raw_Type < b.Raw_Type ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const raw of rawlist) {
      const info ={
        "Raw Type":raw.Raw_Type || "-",
        "Raw Material Name/Size": raw.Material_Name || "-",
        "Stock":raw.In_Stock || 0,
        "Reserved":raw.Quantity_Reserved || 0,
        "Quantity Per Bag":raw.Quantity_PerBag || 0,
        "Material Color":raw.Material_Color || "-",
        "Material Color Ratio":raw.Material_Color_Ratio|| "-",
        "Material Spen":raw.Material_Spen|| "-",
        "Carton Weight":raw.Carton_Weight|| "-",
        "Unit Price":raw.Unit_Price|| "-",
        "Unit": raw.Unit|| "-",
      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'RawMaterial'+new Date().getTime());
    this.spinner.hide();

  }

  async exportToExcelPO() {
    const exportInformation = [];
    this.spinner.show();
    const polist = this.RawListPO;

    for (const po of polist) {
      await po.PO_RawMaterials.forEach(a=>{
        const info ={
          "TDO No":po.PO_No || "-",
          "Supplier": po.Supplier || "-",
          "JO No": a.JO_No || "-",
          "Part No": a.Part_Name || "-",
          "Outsource": a.PO_RawMaterial_Name || "-",
          "Quantity": a.PO_RawMaterial_Qty || "-",
          "Status": a.PO_RawMaterial_Status || "-"
        }
        exportInformation.push(info);
      });
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'TDORaw'+new Date().getTime());
    this.spinner.hide();

  }

  exportToExcelSup() {
    const exportInformation = [];
    this.spinner.show();
    const suplist = this.Supplier.sort((a,b)=>{
      return (a.Supplier_Name < b.Supplier_Name ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const sup of suplist) {
      const info ={
        "Supplier name":sup.Supplier_Name || "-",
        "Address":sup.Address || "-",
        "Contact":sup.Contact || "-",
        "Currency":sup.Currency || "-",
        "Email":sup.Email || "-",
        "Person In Charge":sup.Person_In_Charge || "-",
        "Created Date":sup.Created_Date || "-",
      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'RawMaterial'+new Date().getTime());
    this.spinner.hide();

  }

  async initMachine(){
    this.PORawController.getIncomplePRorTDOList().then(data => {
      this.JOList = data;

      this.JOList.sort((a,b)=>{
        return a.Longest_Date.getTime() - b.Longest_Date.getTime();
      });
    });
  }

  getShowOrNot(partTracker : PartTracker){
    if(partTracker.Raw_Material_Status == 'false'){
      return 'col-lg-3 col-md-6 mt-3 p-2';
    }
    else{
      return '';
    }
  }

  /*async getPartRawMaterialUsed(part_track: PartTracker): Promise<void> {
    var childSnapshot = await this.db.database.ref('Part/'+part_track.PO_Part_No).once('value');

    const rawMaterialID = [];
    part_track.Raw_Material_Status = "true";
    childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
      const info = {
        ID: childSnapshot2.child('ID').val(),
        Name: childSnapshot2.child('Name').val(),
        Material: childSnapshot2.child('Material').val(),
        Raw_Type: childSnapshot2.child('Type').val(),
        UOM: childSnapshot2.child('UOM').val(),
        Amount: childSnapshot2.child('Amount').val()
      }

      this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
        if (snap.exists()) {
          const raw = new RawMaterialInfo();
          raw.Material_ID = snap.key;
          raw.Material_Name = snap.child('_Material_Name').val();
          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
          raw.In_Stock = snap.child('_In_Stock').val();
          raw.Unit = info.UOM;
          raw.Unit_Price = snap.child('_Unit_Price').val(); 
          raw.Updated_By = snap.child('_Updated_By').val();
          raw.Created_By = snap.child('_Created_By').val();
          raw.Created_Date = new Date(snap.child('_Created_Date').val());
          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
          raw.Part_Material_Name = info.Name;
          raw.Raw_Amount = info.Amount;
          raw.Raw_Type = info.Raw_Type;
          this.getRawMaterialUsed(raw, part_track);
          rawMaterialID.push(raw);
        }
      });
    });

    part_track.Raw_Material = rawMaterialID;
  }

  async getRawMaterialUsed(raw: RawMaterialInfo, quantity: PartTracker): Promise<void> {
    raw.quantityNeeded = parseFloat((parseFloat(raw.Raw_Amount) * parseFloat(quantity.POQuantity.toString())).toString()).toFixed(2).toString();

    if(parseFloat(raw.quantityNeeded) > parseFloat(raw.In_Stock)){
      quantity.Raw_Material_Status = "false";
    }
  }*/

  getSortedScheduleList(Schdule_TrackList: ScheduleTracker[], previousSchedules: ScheduleTracker[]): ScheduleTracker[] {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }

    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }

    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList)

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList)

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false
      SortedScheduleList.unshift(InProgress_Schedule)
    } else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true
      SortedScheduleList.unshift(Stopped_Schedule)

    } else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true
    }

    return SortedScheduleList;
  }
  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }
      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    let InProgress_Schedule: ScheduleTracker;
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {

        InProgress_Schedule = Schdule_TrackList[i]
        return InProgress_Schedule
      }
    }
    return null;
  }

  getStoppedSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Stopped_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => a.Machine_Schedule_Start_Date.localeCompare(b.Machine_Schedule_Start_Date));
    }
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")) {
        Stopped_Schedule = Schdule_TrackList[i]
        return Stopped_Schedule
      }
    }
    return null;
  }

  getNeededQuantity(schedule: ScheduleTracker, ID, quantity){

    var qty = parseFloat(quantity);
    schedule.RawMaterialUsed.forEach(e=>{
      if(e.MaterialID === ID)
        qty = qty - e.Quantity;
    })

    if(qty <= 0){
      qty = 0;
    }
    return qty.toFixed(2)

  }

  nextOrBack(machine){
    machine.current = !machine.current;
    var element = machine.Schedule_Track[0];
    machine.Schedule_Track.splice(0, 1);
    machine.Schedule_Track.splice(1, 0, element);
  }

  getColor(quantityNeeded){
    if(quantityNeeded == 'Pending'){
      return "red";
    }
    else if(quantityNeeded == "Complete"){
      return "blue"
    }
  }

  getTwoDecimal(number){
    return parseFloat(number).toFixed(2);
  }

  getColorPanel(po: PurchaseOrderRaw){
    if(po.Status != 'Complete'){
      if(po.Longest_Date.getTime() - new Date().getTime() <= 259200000){
        return 'error';
      }
      else if(po.Longest_Date.getTime() - new Date().getTime() <= 604800000){
        return 'warning';
      }
      else{
        return 'running';
      }
    }
  }

  UploadSupplier(){
    const dialogRefupdatePart = this.dialog.open(UploadExcelDialogComponent, {
      width: '80%',
      height: '80%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(data => {
      if (data){
        this.spinner.show();

        data.forEach(async data2 => {
          var snapshot = await this.db.database.ref('Supplier/' + data2['Code']).once('value');
  
          if (!snapshot.exists()) {
            const sup = new Supplier();
            sup.Supplier_ID = data2['Code'] || uuidv4();
            sup.Supplier_Name = data2['Company Name'] || "-";
            sup.Person_In_Charge = data2['Attention'] || "-";
            sup.Nature = data2['Nature Of Business'] || "";
            sup.Address = data2['Address 1'] || "";
            sup.Address2 = data2['Address 2'] || "";
            sup.Address3 = data2['Address 3'] || "";
            sup.Address4 = data2['Address 3'] || "";
            sup.Contact = data2['Phone 1'] || "";
            sup.Contact2 = data2['Phone 2'] || "";
            sup.Fax = data2['Fax 1'] || "";
            sup.Email = data2['Email Address'] || "";
            sup.Types = 'Raw Material';
            this.SupplierDB_controller.add_Supplier(sup, this.email);
          }
        });
        
        this.spinner.hide();
        this.setup();
      }
    });
  }

  checkRight(module){
    if(this.role == 'Adminstrator' || this.role == 'Director' || this.role == 'Planner'){
      return true;
    }
    else{
      return this.AccessList.includes(module);
    }
  }
}

