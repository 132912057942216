import { DateFormatService } from './../Utilities/date-format.service';
import { RawMaterialOrder, PoInRawMaterial, ReceivedDetails } from './../Object_Classes/RawMaterial/PORawMaterial';
import { AngularFireDatabase } from '@angular/fire/database';
import { PurchaseOrderRaw } from '../Object_Classes/RawMaterial/PORawMaterial';
import { AngularFirestore } from '@angular/fire/firestore';

export class PORawDB_controller {
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }
  private dateFormat = new DateFormatService();
  /************** Purchase Order ****************/
  async getPORawMaterialList(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    var snapshot = await this.db.database.ref('PO RawMaterial').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Type').val() == 'Raw'){
          let PO_item = new PurchaseOrderRaw();
          PO_item.PO_No = childSnapshot.key;
          PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          PO_item.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());
          PO_item.Created_By = childSnapshot.child('_Created_By').val();
          PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
          PO_item.JO_No = childSnapshot.child('_JO_Number').val();
          PO_item.Type = childSnapshot.child('_Type').val();

          PO_item.Status = childSnapshot.child('_Status').val();
          var rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            var poInRawMaterials: PoInRawMaterial[] = [];
            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();

              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.PO_Order_ID = childSnapshot2.key;
            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
            raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
            raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();


            rawMaterialOrders.push(raw);
          });
          PO_item.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(PO_item);
        }
      });
    }
    return PO_list;
  }

  async getTDOlList(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    var snapshot = await this.db.database.ref('PO Outsource').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Type').val() == 'Outsource'){
          let PO_item = new PurchaseOrderRaw();
          PO_item.PO_No = childSnapshot.key;
          PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          PO_item.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());
          PO_item.Pr_Date = new Date(childSnapshot.child('_PR_Date').val());
          PO_item.Created_By = childSnapshot.child('_Created_By').val();
          PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
          PO_item.SO_No = childSnapshot.child('_SO_Number').val();
          PO_item.Type = childSnapshot.child('_Type').val();
          PO_item.Status = childSnapshot.child('_Status').val();
          PO_item.Supplier = childSnapshot.child('_Supplier').val();
          PO_item.Outsource_Type = childSnapshot.child('_Outsource_Type').val();

          var rawMaterialOrders: RawMaterialOrder[] = [];
          var allCompleted = true;
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            var poInRawMaterials: PoInRawMaterial[] = [];
            var received: ReceivedDetails[] = [];
  
            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })
  
            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
  
              received.push(re);
            });

            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.PO_Order_ID = childSnapshot2.key;
            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();

            if(childSnapshot2.child('_PO_RawMaterial_Status').val() != "Completed"){
              allCompleted = false;
            }

            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
            raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
            raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();
            raw.JO_No = childSnapshot2.child('_JO_No').val();
            raw.Part_ID = childSnapshot2.child('_Part_No').val();
            raw.Part_No = childSnapshot2.child('_Part_Name').val();
            raw.Part_Uom = childSnapshot2.child('_Part_Uom').val();
            raw.Remark = childSnapshot2.child('_Remark').val();
            this.getPartInfo(raw);
            rawMaterialOrders.push(raw);
          });

          rawMaterialOrders.sort((a, b) => a.JO_No > b.JO_No ? 1 : -1);
          PO_item.PO_RawMaterials = rawMaterialOrders;

          if(PO_item.Status == "Pending" && allCompleted){
            PO_item.Status = "Completed";
            this.db.database.ref('PO Outsource/' + PO_item.PO_No + '/_Status').set("Completed");
          }

          PO_list.push(PO_item);
        }
      });
    }
    return PO_list;
  }

  async getPartInfo(pt: RawMaterialOrder): Promise<void> {
    var snapshot = await this.db.database.ref('Part').child(pt.Part_ID).once('value');
    if (snapshot.exists()) {
      pt.Part_Name = snapshot.child('Part No').val();
    }
    else{
      pt.Part_Name = pt.Part_No;
    }
  }

  async getIncomplePRorTDOList(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    var snapshot = await this.db.database.ref('PO RawMaterial').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() != 'Completed'){
          let PO_item = new PurchaseOrderRaw();
          PO_item.PO_No = childSnapshot.key;
          PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          PO_item.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());
          PO_item.Longest_Date = new Date('2100-01-01 00:00:00 UTC+00');
          PO_item.Created_By = childSnapshot.child('_Created_By').val();
          PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
          PO_item.JO_No = childSnapshot.child('_JO_Number').val();
          PO_item.Type = childSnapshot.child('_Type').val();
  
          PO_item.Status = childSnapshot.child('_Status').val();
          var rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            var poInRawMaterials: PoInRawMaterial[] = [];
            var received: ReceivedDetails[] = [];
  
            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })
  
            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
  
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.PO_Order_ID = childSnapshot2.key;
            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
            raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
            raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();
  
            if(childSnapshot2.child('_PO_RawMaterial_Status').val() != 'Complete'){
              if(raw.Delivery_Date.getTime() <= PO_item.Longest_Date.getTime()){
                PO_item.Longest_Date = raw.Delivery_Date;
              }
            }
  
            rawMaterialOrders.push(raw);
          });

          PO_item.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(PO_item);
        }
      });
    }
    return PO_list;
  }

  add_TDO(_newPO: any, email: string, type: string) {
    const updates = {};
    updates[_newPO.POName + '/_Created_Date'] = new Date();
    updates[_newPO.POName + '/_Updated_Date'] = new Date();
    updates[_newPO.POName + '/_Created_By'] = email;
    updates[_newPO.POName + '/_Updated_By'] = email;
    updates[_newPO.POName + '/_Status'] = 'Pending';
    updates[_newPO.POName + '/_PO_Customer'] = _newPO.poCustomer;
    updates[_newPO.POName + '/_SO_Number'] = _newPO.soNumber;
    updates[_newPO.POName + '/_PR_Date'] = _newPO.receivedDate;
    updates[_newPO.POName + '/_Outsource_Type'] = _newPO.outsourceType;
    updates[_newPO.POName + '/_Supplier'] = _newPO.supplier;
    updates[_newPO.POName + '/_Type'] = type;

    let materialName = '';

    if(_newPO.raws != null){
      _newPO.raws.forEach((data,index) => {
        if(index=== _newPO.raws.length-1)
          materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity +'</b>';
        else
          materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity + '</b>\n';
          
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_ID'] = data.materialID;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Name'] = data.materialName;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Qty'] = data.quantity;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Status'] = data.status;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Supplier'] = data.supplier.Supplier_Name;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Delivery_Date'] = data.deliveryDate;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Unit_Price'] = data.unitPrice;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Amount'] = data.amount;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Received_Quantity'] = "0";
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Deducted_Quantity'] = "0";
      });
    }

    if(_newPO.outsources != null){
      _newPO.outsources.forEach(async (data,index) => {
        if(index=== _newPO.outsources.length-1)
          materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity +'</b>';
        else
          materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity + '</b>\n';
  
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_JO_No'] = data.joNo;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Part_No'] = data.partNum;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Part_Name'] = data.partNo;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Part_Uom'] = data.partUOM;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_ID'] = data.materialID;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Name'] = data.materialName;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Qty'] = data.quantity;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Delivery_Date'] = data.tdoDate;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Supplier'] = data.supplier;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Status'] = data.status;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Received_Quantity'] = "0";
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Deducted_Quantity'] = "0";
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Remark'] = data.remark;

        var snapshot = await this.db.database.ref('Part').child(data.partNum).once('value');

        if (snapshot.exists()) {
          const partinfo = { };
          partinfo['Part No'] = data.partNo || '-';
          await this.db.database.ref('Part/' + data.partNum).update(partinfo);
        }
        else{
          const partinfo = { };
          partinfo['Stock Quantity'] = 0;
          partinfo['Part Name'] = data.partNo || '-';
          partinfo['Part No'] = data.partNo || '-';
          await this.db.database.ref('Part/' + data.partNum).set(partinfo);
        }

        if(data.soNo != ""){
          var current = new Date().getTime().toString();
          this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Status').set("Outsourcing");
          this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Outsouce_Detail').set(_newPO.POName + '/RawMaterials/' + data.orderID);
          this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Schedule/' + current + '/Process').set("Outsourcing");
          this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Schedule/' + current + '/Supplier').set(data.supplier);
          this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Schedule/' + current + '/Type').set(_newPO.outsourceType);
          this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Schedule/' + current + '/Created_By').set(email);
        }
      });
    }

    this.db.database.ref('PO Outsource/').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _newPO.POName + ' has been added by the <b>' + email + '</b>\n' + materialName,
      date: new Date()
    };
    this.firestore.collection('POOutsourceLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('POOutsourceLog').doc(dateFormat).collection('PO').add(info);
  }

  async edit_TDO(_newPO: any, email: string, type: string) {
    const updates = {};

    await this.db.database.ref('PO Outsource/' + _newPO.POName).set(null).then(async r => {
      updates[_newPO.POName + '/_Created_Date'] = new Date();
      updates[_newPO.POName + '/_Updated_Date'] = new Date();
      updates[_newPO.POName + '/_Created_By'] = email;
      updates[_newPO.POName + '/_Updated_By'] = email;
      updates[_newPO.POName + '/_Status'] = 'Pending';
      updates[_newPO.POName + '/_PO_Customer'] = _newPO.poCustomer;
      updates[_newPO.POName + '/_SO_Number'] = _newPO.soNumber;
      updates[_newPO.POName + '/_PR_Date'] = _newPO.receivedDate;
      updates[_newPO.POName + '/_Outsource_Type'] = _newPO.outsourceType;
      updates[_newPO.POName + '/_Supplier'] = _newPO.supplier;
      updates[_newPO.POName + '/_Type'] = type;
  
      let materialName = '';
  
      if(_newPO.outsources != null){
        _newPO.outsources.forEach(async (data,index) => {
          if(index=== _newPO.outsources.length-1)
            materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity +'</b>';
          else
            materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity + '</b>\n';
    
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_JO_No'] = data.joNo;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Part_No'] = data.partNum;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Part_Name'] = data.partNo;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Part_Uom'] = data.partUOM;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_ID'] = data.materialID;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Name'] = data.materialName;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Qty'] = data.quantity;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Delivery_Date'] = data.tdoDate;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Supplier'] = data.supplier;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Status'] = data.status;
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Received_Quantity'] = "0";
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Deducted_Quantity'] = "0";
          updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Remark'] = data.remark;
          
          var snapshot = await this.db.database.ref('Part').child(data.partNum).once('value');
  
          if (snapshot.exists()) {
            const partinfo = { };
            partinfo['Part No'] = data.partNo || '-';
            await this.db.database.ref('Part/' + data.partNum).update(partinfo);
          }
          else{
            const partinfo = { };
            partinfo['Stock Quantity'] = 0;
            partinfo['Part Name'] = data.partNo || '-';
            partinfo['Part No'] = data.partNo || '-';
            await this.db.database.ref('Part/' + data.partNum).set(partinfo);
          }

          if(data.soNo != ""){
            var current = new Date().getTime().toString();
            this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Status').set("Outsourcing");
            this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Outsouce_Detail').set(_newPO.POName + '/RawMaterials/' + data.orderID);
            this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Schedule/' + current + '/Process').set("Outsourcing");
            this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Schedule/' + current + '/Supplier').set(data.supplier);
            this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Schedule/' + current + '/Type').set(_newPO.outsourceType);
            this.db.database.ref('Purchase Order/' + data.soNo + '/Part List/' + data.joNo + '/Schedule/' + current + '/Created_By').set(email);
          }
        });
      }
  
      this.db.database.ref('PO Outsource/').update(updates);
      const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
      const info = {
        message: _newPO.POName + ' has been added by the <b>' + email + '</b>\n' + materialName,
        date: new Date()
      };
      this.firestore.collection('POOutsourceLog').doc(dateFormat).set({ Date: new Date() });
      this.firestore.collection('POOutsourceLog').doc(dateFormat).collection('PO').add(info);
    });
    
  }

  add_PO(_newPO: any, email: string, type: string) {
    const updates = {};
    updates[_newPO.POName + '/_Created_Date'] = new Date();
    updates[_newPO.POName + '/_Updated_Date'] = new Date();
    updates[_newPO.POName + '/_Created_By'] = email;
    updates[_newPO.POName + '/_Updated_By'] = email;
    updates[_newPO.POName + '/_Status'] = 'Pending';
    updates[_newPO.POName + '/_PO_Customer'] = _newPO.poCustomer;
    updates[_newPO.POName + '/_SO_Number'] = _newPO.soNumber;
    updates[_newPO.POName + '/_JO_Number'] = _newPO.joNumber;
    updates[_newPO.POName + '/_Part_No'] = _newPO.partNo;
    updates[_newPO.POName + '/_Quantity'] = _newPO.quantity;
    updates[_newPO.POName + '/_PR_Date'] = _newPO.prDate;
    updates[_newPO.POName + '/_Type'] = type;

    let materialName = '';

    if(_newPO.raws != null){
      _newPO.raws.forEach((data,index) => {
        if(index=== _newPO.raws.length-1)
          materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity +'</b>';
        else
          materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity + '</b>\n';
          
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_ID'] = data.materialID;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Name'] = data.materialName;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Qty'] = data.quantity;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Status'] = data.status;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Supplier'] = data.supplier.Supplier_Name;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Delivery_Date'] = data.deliveryDate;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Unit_Price'] = data.unitPrice;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Amount'] = data.amount;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Received_Quantity'] = "0";
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Deducted_Quantity'] = "0";
      });
    }

    if(_newPO.outsources != null){
      _newPO.outsources.forEach((data,index) => {
        if(index=== _newPO.outsources.length-1)
          materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity +'</b>';
        else
          materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity + '</b>\n';
  
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_ID'] = data.materialID;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Name'] = data.materialName;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Qty'] = data.quantity;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Status'] = data.status;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Supplier'] = data.supplier.Supplier_Name;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Delivery_Date'] = data.deliveryDate;
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Received_Quantity'] = "0";
        updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Deducted_Quantity'] = "0";
      });
    }

    this.db.database.ref('PO RawMaterial/').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _newPO.POName + ' has been added by the <b>' + email + '</b>\n' + materialName,
      date: new Date()
    };
    this.firestore.collection('PORawMaterialLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('PORawMaterialLog').doc(dateFormat).collection('PO').add(info);
  }

  add_Consumable_PO(_newPO: any, email: string, type: string) {
    const updates = {};
    updates[_newPO.POName + '/_Created_Date'] = new Date();
    updates[_newPO.POName + '/_Updated_Date'] = new Date();
    updates[_newPO.POName + '/_Created_By'] = email;
    updates[_newPO.POName + '/_Updated_By'] = email;
    updates[_newPO.POName + '/_Status'] = 'Pending';
    updates[_newPO.POName + '/_PO_Customer'] = _newPO.poCustomer;
    updates[_newPO.POName + '/_SO_Number'] = _newPO.soNumber;
    updates[_newPO.POName + '/_JO_Number'] = _newPO.joNumber;
    updates[_newPO.POName + '/_Part_No'] = _newPO.partNo;
    updates[_newPO.POName + '/_Quantity'] = _newPO.quantity;
    updates[_newPO.POName + '/_PR_Date'] = _newPO.prDate;
    updates[_newPO.POName + '/_Type'] = type;

    let materialName = '';
    _newPO.raws.forEach((data,index) => {
      if(index=== _newPO.raws.length-1)
        materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity +'</b>';
      else
        materialName += 'Material Name: <b>' + data.materialName + '</b>\nQuantity: <b>' + data.quantity + '</b>\n';
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_ID'] = data.materialID;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Name'] = data.materialName;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Qty'] = data.quantity;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_PO_RawMaterial_Status'] = data.status;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Supplier'] = data.supplier.Supplier_Name;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Delivery_Date'] = data.deliveryDate;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Unit_Price'] = data.unitPrice;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Amount'] = data.amount;
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Received_Quantity'] = "0";
      updates[_newPO.POName + '/RawMaterials/' + data.orderID + '/_Deducted_Quantity'] = "0";
    });
    this.db.database.ref('PO RawMaterial/').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _newPO.POName + ' has been added by the <b>' + email + '</b>\n' + materialName,
      date: new Date()
    };
    this.firestore.collection('PORawMaterialLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('PORawMaterialLog').doc(dateFormat).collection('PO').add(info);
  }

  update_PO(PO: any, email: string) {
    const updates = {};
    updates[PO.selectedPO.PO_No + '/_Updated_Date'] = new Date();
    updates[PO.selectedPO.PO_No + '/_Updated_By'] = email;
    updates[PO.selectedPO.PO_No + '/_Status'] = PO.status;
    updates[PO.selectedPO.PO_No + '/_Completed_Date'] = PO.Completed_Date;

    PO.selectedRaws.forEach(async (data, index) => {
      updates[PO.selectedPO.PO_No + '/RawMaterials/' + data.PO_Order_ID + '/_PO_RawMaterial_Status'] = PO.detailsRaws[index].status;
      await this.db.database.ref('RawMaterial/' + data.PO_RawMaterial_ID).once('value').then(datasnap => {
        // tslint:disable-next-line: radix
        let quantity = parseFloat(datasnap.child('_In_Stock').val());
        const date = new Date();
        // tslint:disable-next-line: radix
        quantity += parseFloat(PO.detailsRaws[index].receivedQuantity);
        let sentence = 'Material Name: <b>' + datasnap.child('_Material_Name').val() + '</b>\nQuantity: <b>' + PO.detailsRaws[index].receivedQuantity+'</b>';
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' + 
          data.PO_Order_ID + '/_Received_Quantity').set(PO.detailsRaws[index].receivedQuantity);
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Received_Quantity').set(PO.detailsRaws[index].amount);
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Received_Date').set(PO.detailsRaws[index].receivedDate.toISOString());
        
        
        if(PO.detailsRaws[index].file != null){
          this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
            data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_ImageURL').set(PO.detailsRaws[index].file);
        }  
        this.db.database.ref('PO RawMaterial/' + PO.selectedPO.PO_No + '/RawMaterials/' +
          data.PO_Order_ID + '/ReceivedDetails/' + PO.detailsRaws[index].uid + '/_Invoice_Number').set(PO.detailsRaws[index].invoice);

        this.db.database.ref('RawMaterial/' + data.PO_RawMaterial_ID + '/_In_Stock').set(quantity.toFixed(2));
        this.db.database.ref('RawMaterial/' + data.PO_RawMaterial_ID + '/_Updated_By').set(email);
        this.db.database.ref('RawMaterial/' + data.PO_RawMaterial_ID + '/_Last_Updated').set(date.toISOString());
        const info = {
          message: PO.selectedPO.PO_No + ' has been updated by the <b>' + email + '</b>\n' + sentence,
          date: new Date()
        };
        this.firestore.collection('PORawMaterialLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('PORawMaterialLog').doc(dateFormat).collection('PO').add(info);
      });
    });
    this.db.database.ref('PO RawMaterial/').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());

  }

  async update_TDO(PO: any, email: string) {
    PO.outsources.forEach(async (data, index) => {
      this.db.database.ref('PO Outsource/' + data.TDONo + '/RawMaterials/' + 
        data.id + '/_PO_RawMaterial_Status').set(data.status);
      this.db.database.ref('PO Outsource/' + data.TDONo + '/RawMaterials/' + 
        data.id + '/_Received_Quantity').set(data.receivedQuantity);
      this.db.database.ref('PO Outsource/' + data.TDONo + '/RawMaterials/' +
        data.id + '/ReceivedDetails/' + data.uid + '/_Received_Quantity').set(data.receivedQuantity);
      this.db.database.ref('PO Outsource/' + data.TDONo + '/RawMaterials/' +
        data.id + '/ReceivedDetails/' + data.uid + '/_Received_Date').set(new Date().toISOString());
      this.db.database.ref('PO Outsource/' + data.TDONo + '/RawMaterials/' +
        data.id + '/ReceivedDetails/' + data.uid + '/_Invoice_Number').set(data.invoice);

      var snapshot = await this.db.database.ref('PO Outsource').child(data.TDONo).once('value');

      if (snapshot.exists()) {
        var allCompleted = true;
        this.db.database.ref('PO Outsource/' + data.TDONo + '/_Status').set("Completed");
        this.db.database.ref('PO Outsource/' + data.TDONo + '/_Completed_Date').set(new Date().toISOString());

        await snapshot.child("RawMaterials").forEach((childSnapshot) => {
          if(allCompleted){
            if(childSnapshot.child("_PO_RawMaterial_Status").val() != 'Completed'){
              this.db.database.ref('PO Outsource/' + data.TDONo + '/_Status').set("Pending");
              this.db.database.ref('PO Outsource/' + data.TDONo + '/_Completed_Date').set(null);
              allCompleted = false;
            }
          }
        });

        this.db.database.ref('PO Outsource/' + data.TDONo + '/_Updated_By').set(email);
      }
    });
  }

  async search_PO(PO_No: string): Promise<PurchaseOrderRaw> {

    let searched_PO = new PurchaseOrderRaw();
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let get_PO_No = childSnapshot.key;
        if (PO_No.match(get_PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());

          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];
            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();

              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;

            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();

            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
        }
      });
    }

    return searched_PO;
  }

  async search_Partial_PO(PO_No: string): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrderRaw();
        let get_PO_No = childSnapshot.key;
        if (get_PO_No.includes(PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());
          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.PO_No = childSnapshot.child('_SO_Number').val();
          searched_PO.Type = childSnapshot.child('_Type').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          searched_PO.Supplier = childSnapshot.child('_Supplier').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];

            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;

            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();

          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();



            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(searched_PO);
        }
      });
    }
    return PO_list;
  }

  async search_PO_withStatusPending(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrderRaw();
        let status = childSnapshot.child('_Status').val();
        if (status === 'Pending' && childSnapshot.child('_Type').val() == 'Raw') {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());

          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          searched_PO.Type == childSnapshot.child('_Type').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];

            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;

            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();


            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(searched_PO);
        }
      });
    }
    return PO_list;
  }

  async search_TDO_withStatusPending(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    const snapshot = await this.db.database.ref('PO Outsource').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrderRaw();
        let status = childSnapshot.child('_Status').val();
        if (status === 'Pending') {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());
          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.SO_No = childSnapshot.child('_SO_Number').val();
          searched_PO.Type = childSnapshot.child('_Type').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          searched_PO.Supplier = childSnapshot.child('_Supplier').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];

            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;

            raw.TDOno = childSnapshot.key;
            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
            raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
            raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();
            raw.JO_No = childSnapshot2.child('_JO_No').val();
            raw.Part_ID = childSnapshot2.child('_Part_No').val();
            raw.Part_No = childSnapshot2.child('_Part_Name').val();
            raw.Part_Uom = childSnapshot2.child('_Part_Uom').val();
            raw.Remark = childSnapshot2.child('_Remark').val();
            this.getPartInfo(raw);
            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(searched_PO);
        }
      });
    }
    return PO_list;
  }

  async search_TDO_Details_withStatusPending(): Promise<RawMaterialOrder[]> {
    let PO_list: RawMaterialOrder[] = [];
    const snapshot = await this.db.database.ref('PO Outsource').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if (childSnapshot.child('_Status').val() === 'Pending') {
           childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            if (childSnapshot2.child('_PO_RawMaterial_Status').val() != 'Completed') {
              let raw = new RawMaterialOrder();
              raw.PO_Order_ID = childSnapshot2.key;
              raw.TDOno = childSnapshot.key;
              raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
              raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
              raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
              raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
              raw.Supplier = childSnapshot2.child('_Supplier').val();
              raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
              raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
              raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
              raw.Unit = childSnapshot2.child('_Unit').val();
              raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
              raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
              raw.Amount = childSnapshot2.child('_Amount').val();
              raw.JO_No = childSnapshot2.child('_JO_No').val();
              raw.Part_ID = childSnapshot2.child('_Part_No').val();
              raw.Part_No = childSnapshot2.child('_Part_Name').val();
              raw.Part_Uom = childSnapshot2.child('_Part_Uom').val();
              raw.Remark = childSnapshot2.child('_Remark').val();
              this.getPartInfo(raw);
              PO_list.push(raw);
            }
          });
        }
      });
    }
    return PO_list;
  }

  async search_Outource_PO_withStatusPending(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrderRaw();
        let status = childSnapshot.child('_Status').val();
        if (status === 'Pending' && childSnapshot.child('_Type').val() == 'Outsource') {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());
          searched_PO.JO_No = childSnapshot.child('_JO_Number').val()
          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();
          searched_PO.Type == childSnapshot.child('_Type').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];

            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;

            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
            raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();


            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(searched_PO);
        }
      });
    }
    return PO_list;
  }

  async search_PO_withStatusCompleted(): Promise<PurchaseOrderRaw[]> {
    let PO_list: PurchaseOrderRaw[] = [];
    const snapshot = await this.db.database.ref('PO RawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrderRaw();
        let status = childSnapshot.child('_Status').val();
        if (status === 'Completed') {
          searched_PO.PO_No = childSnapshot.key;
          searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_PO.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          searched_PO.Completed_Date = new Date(childSnapshot.child('_Completed_Date').val());

          searched_PO.Created_By = childSnapshot.child('_Created_By').val();
          searched_PO.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_PO.Status = childSnapshot.child('_Status').val();

          let rawMaterialOrders: RawMaterialOrder[] = [];
          childSnapshot.child('RawMaterials').forEach(childSnapshot2 => {
            let raw = new RawMaterialOrder();
            raw.PO_Order_ID = childSnapshot2.key;
            var poInRawMaterials: PoInRawMaterial[] = [];
            var received: ReceivedDetails[] = [];

            childSnapshot2.child('_AssignedPO').forEach(childSnapshot3 => {
              let po = new PoInRawMaterial();
              po.Po_No = childSnapshot3.key;
              po.Part_No = childSnapshot3.child('Part No').val();
              po.CheckOut_Date = childSnapshot3.child('Checkout Date').val();
              po.CheckOut_Quantity = childSnapshot3.child('Checkout Raw Material Quantity').val();
              po.Machine_Assigned = childSnapshot3.child('Machine Assign').val();
              poInRawMaterials.push(po);
            })

            childSnapshot2.child('ReceivedDetails').forEach(childSnapshot3 => {
              let re = new ReceivedDetails();
              re.Received_Date = new Date(childSnapshot3.child('_Received_Date').val());
              re.Rack_Number = childSnapshot3.child('_Rack_Number').val();
              re.Packets = childSnapshot3.child('_Packets').val();
              re.Invoice_Number = childSnapshot3.child('_Invoice_Number').val();
              re.Lot_Number = childSnapshot3.child('_Lot_Number').val();
              re.Box_Number = childSnapshot3.child('_Box_Number').val();
              re.Received_Quantity = childSnapshot3.child('_Received_Quantity').val();
              re.Quantity_PerBags = childSnapshot3.child('_Quantity_PerBags').val();
              re.ImageURL = childSnapshot3.child('_ImageURL').val();
              received.push(re);
            })
            raw.PoInRawMaterialList = poInRawMaterials;
            raw.ReceivedDetailsList = received;
            raw.PO_RawMaterial_ID = childSnapshot2.child('_PO_RawMaterial_ID').val();
            raw.PO_RawMaterial_Name = childSnapshot2.child('_PO_RawMaterial_Name').val();
            raw.PO_RawMaterial_Qty = childSnapshot2.child('_PO_RawMaterial_Qty').val();
            raw.PO_RawMaterial_Status = childSnapshot2.child('_PO_RawMaterial_Status').val();
            raw.Supplier = childSnapshot2.child('_Supplier').val();
            raw.Received_Quantity = childSnapshot2.child('_Received_Quantity').val();
            raw.Deducted_Quantity = childSnapshot2.child('_Deducted_Quantity').val();
            raw.Unit = childSnapshot2.child('_Unit').val();
            raw.Unit_Price = childSnapshot2.child('_Unit_Price').val();
          raw.quantity_perBag = childSnapshot2.child('_Quantity_PerBag').val();
          raw.Delivery_Date = new Date(childSnapshot2.child('_Delivery_Date').val());
            raw.Amount = childSnapshot2.child('_Amount').val();
            rawMaterialOrders.push(raw);
          });
          searched_PO.PO_RawMaterials = rawMaterialOrders;
          PO_list.push(searched_PO);
        }
      });
    }
    return PO_list;
  }

  delete_PO(PO_No: string) {
    this.db.database.ref('/PO RawMaterial/' + PO_No).set(null);
  }

  delete_TDO(PO_No: string) {
    this.db.database.ref('/PO Outsource/' + PO_No).set(null);
  }
}
